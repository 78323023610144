





















































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import {
  TransactionModule,
  NewTransaction
} from "@shared/store/modules/TransactionModule";

import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";

@Component({ mixins: [TransactionMixin] })
export default class Request2 extends Mixins(TransactionMixin) {
  public isLoading: boolean = false;
  public error: string | null = null;



  //#endregion
public created(){

    this.EnableLabels = true;
}

  public mounted() {
    this.Pagenr = 1;
    try{
    parent.postMessage("Maximize", "*");
    }catch (ex){
      console.log("not running in iframe");
      console.log(ex);
    }
    this.EnableLabels = true;
  }
}

// $('.content-fold > a').click(function () {
//   $('.inquiry-pageform-left-inner').toggleClass('content-fold--isunfold');

//   if ( $('.inquiry-pageform-left-inner').hasClass("content-fold--isunfold") ) {
//     $('.content-fold > a').text('Weniger anzeigen');
//   } else {
//     $('.content-fold > a').text('Mehr anzeigen');
//   }
// })
