


































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { APPMODE } from "@/services/Config";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import { mixins } from "vue-class-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, confirmed, email } from "vee-validate/dist/rules";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import CheckboxesWithValidation from "@shared/components/Inputs/CheckboxesWithValidation.vue";

import BSelectWithValidation from "@shared/components/Inputs/BSelectWithValidation.vue";
import VuePictureSwipe from "vue-picture-swipe";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";

@Component({
  mixins: [TransactionMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BTextInputWithValidation,
    VuePictureSwipe,
    BSelectWithValidation,
    CheckboxesWithValidation,
  },
})
export default class Request4 extends mixins(TransactionMixin) {
  public isLoading: boolean = false;
  public error: string | null = null;
  newpassword: string = "";
  repeatNewPassword: string = "";
  email: string = "";
  country: string | null = null;
  tabIndex: number = 0;
  tabs: Array<string> = ["#register", "#login", "#forgotpassword", "#forgotok"];
  loginUser: string = "";
  loginPassword: string = "";
  agbchecked: Boolean = false;
  privacychecked: Boolean = false;
  private countryoptions: Array<any> = Environment.CountryOptions;
  public get NewPassword(): string {
    return this.newpassword;
  }

  public set NewPassword(value: string) {
    this.newpassword = value;
  }
  public get Email(): string {
    return this.email;
  }

  public set Email(value: string) {
    this.email = value;
  }
  public mounted() {
    // this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash);
  }
  public async ForgotPassword() {
    this.isLoading = true;
    this.error = null;
    try {
      const user: User = {
        userName: APPMODE + "_" + this.email, //this.username,
        password: "", //this.password,
        firstName: "",
        lastName: "",
        userPoolId: -1,
        language: "de",
        eMail: APPMODE + "_" + this.email,
        timeZone: "",
        id: "",
        entityId: "",
        userRoles: "",
      };
      await UserModule.ForgotPassword(user);
      this.tabIndex = this.tabs.findIndex((tab) => tab === "#forgotok");
      this.isLoading = false;
      this.error = "";
    } catch (ex) {
      this.error = "invalid username or email";
      this.isLoading = false;
      return;
    }
  }

  public async Login() {
    this.isLoading = true;
    this.error = null;

    try {
      const user: User = {
        userName: APPMODE + "_" + this.loginUser, //this.username,
        password: this.loginPassword, //this.password,
        firstName: "",
        lastName: "",
        userPoolId: -1,
        language: "de",
        eMail: APPMODE + "_" + this.loginUser,
        timeZone: "",
        id: "",
        entityId: "",
        userRoles: "",
      };
      await UserModule.Login(user);
    } catch (ex) {
      this.error = "invalid username or password";
      this.isLoading = false;
      return;
    }
    await TransactionModule.TakeOverCurrentTransaction();
    this.isLoading = false;
    Environment.JumpToMainApp(
      UserModule.claims[0],
      UserModule.Token,
      this.$router
    );
    // this.$store.dispatch('loadCategories')
  }

  isLoginButtonDisabled() {
    return (
      !this.loginUser || !this.loginPassword || this.loginPassword.length < 4
    );
  }

  public async Register() {
    //@ts-ignore
    const isvalid = !(await (<any>this.$refs.observer).validate());
    if (isvalid == false) {
      this.isLoading = true;
      try {
        //  console.log("test"+ this.email +"|"+ this.NewPassword);
        const user: User = {
          userName: APPMODE + "_" + this.email,
          password: this.NewPassword,
          firstName: "",
          lastName: "",
          userPoolId: -1,
          language: "de",
          eMail: APPMODE + "_" + this.email,
          timeZone: "",
          id: "",
          entityId: "",
          userRoles: "",
        };
        await UserModule.Register(user);
        await UserModule.Login(user);
        await TransactionModule.TakeOverCurrentTransaction();
        var profile: Definitions.PrivacyProfile = UserModule.Profile;
        profile.country = this?.country ?? "";
        profile.email = this.email;
        await PrivacyNugget.UpdateSafe(UserModule.UserEntityId ?? "", profile);

        if (
          profile.firstname == "" ||
          profile.firstname == undefined ||
          profile.firstname == null
        ) {
          this.$router.push({
            name: "newrequest5",
            params: { user: UserModule.Email, userid: "-1" },
          });
          return;
        }
        Environment.JumpToMainApp(
          UserModule.claims[0],
          UserModule.Token,
          this.$router
        );
        this.isLoading = false;
        //  this.$router.push("Customer/CustomerOverview");
      } catch (e) {
        this.error = e;
        this.isLoading = false;
      }
    }
  }
}
