import 'mutationobserver-shim'
import Vue from 'vue'

import '@shared/plugins/bootstrap-vue'
import '@shared/plugins/axios'
import App from './App.vue'
import router from './router'
import store from '@shared/store'
import i18n from '@shared/i18n'
import VueMeta from 'vue-meta'
import Vue2Filters from 'vue2-filters'
import { ENVIRONMENT } from "@/services/Config";


Vue.config.devtools = true;


import { localize } from 'vee-validate';
import moment from 'moment'

import 'moment/locale/de'  // without this line it didn't work
moment.locale('de')

//import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';

//  import { init } from '@elastic/apm-rum'

//  const apm = init({
//    serviceName: ENVIRONMENT+'DieGeldverleiher',
//    serverUrl: 'https://vcapm.vertical.codes',
//    serviceVersion: ''
//  })

// Install English and german locales.
localize({ de: de });
localize('de',de);
require('moment/locale/de.js');

Vue.use(Vue2Filters)

Vue.use(VueMeta)

const VueFormGenerator = require("@shared/components/vue-form-generator/vfgindex")
Vue.use(VueFormGenerator);

Vue.directive('visible', function(el, binding) {
	el.style.display = !!binding.value ? 'block' : 'none';
});
import { Environment } from './services/Environment'
Vue.prototype.$agburl = Environment.AGB_URL;
Vue.prototype.$privacyurl = Environment.PRIVACY_URL;
Vue.prototype.$impressumurl = Environment.IMPRESSUM_URL;

Vue.config.productionTip = false
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, min_value,confirmed,email,numeric,regex } from "vee-validate/dist/rules";


// Add the required rule
extend('required', {
  ...required,
  message: 'Das ist ein Pflichtfeld'
});

// Add the required rule
extend('regex', {
  ...regex,
  message: 'Bitte gib einen gültigen Wert ein'
});



extend('numeric', {
  ...numeric,
  message: 'Bitte gib eine Nummer ein'
});
extend("min_value", { ...min_value, message: 'Dieser Wert is zu niedrig' });
extend('confirmed', {
  ...confirmed,
  message: 'Die Passwörter stimmen nicht überein'
});

extend('email', {
  ...email,
  message: 'Bitte gib eine gültige Emailadresse ein'

});


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')




