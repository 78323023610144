export const ENVIRONMENT: string =
  window.location.hostname.toLowerCase().indexOf("staging") >= 0 || window.location.hostname.toLowerCase().indexOf("localhost") >= 0
    ? "staging"
    : "master";

export const APPENTITYID: string =
  ENVIRONMENT === "staging"
    ? "99c3056a617b61d58c9d927eaf4f646f9af6c0fe05f77b4f87a6f8e999472ebf"
    : "testentitsyid";
export const CN_ANONYMOUSUSERNAME: string = "anonymous@vertical.codes";
export const CN_ANONYMOUSPASSWORD: string = "mysecurepassword";
export const CN_TENANT: string = "WebApp";
export const IMG_PREVIEWFILETYPE: string = "png";
export const IMG_LISTPREVIEWHEIGHT: string = "80";
export const IMG_LISTPREVIEWWIDTH: string = "80";
export const IMG_BACKGROUND: string = "%23FFFFFF";
export const IMG_LISTORIGINALSIZE: boolean = false;

export enum AppModes {
  Pfandlädchen = "pf",
  Golending = "go",
  DieGeldverleiher = "gv",
  None = "",
}

const RoleMapStaging = {
  Pawn: 10,
  User: 11,
  Admin: 9,
};

const RoleMapMaster = {
  Pawn: 2,
  User: 3,
  Admin: 1,
};

export const RoleMap =
  ENVIRONMENT == "staging" ? RoleMapStaging : RoleMapMaster;

const UserPoolMapStaging = {
  Pawn: 41,
  User: 38,
  Admin: 37,
};

const UserPoolMapMaster = {
  Pawn: 13,
  User: 10,
  Admin: 9,
};

export const UserPoolMap =
  ENVIRONMENT === "staging" ? UserPoolMapStaging : UserPoolMapMaster;

export const APPMODE: AppModes = AppModes.DieGeldverleiher;

export function SiteUrls(mode: AppModes): string {
  if (mode === AppModes.DieGeldverleiher) {
    return ENVIRONMENT === "master"
      ? "https://app.diegeldverleiher.com"
      : "https://diegeldverleiherwebui-staging.azurewebsites.net";
  } else if (mode === AppModes.Golending) {
    return "https://golending.azurewebsites.net";
  } else if (mode === AppModes.Pfandlädchen) {
    return "https://daspfandlaedchen.azurewebsites.net";
  }
  return "http://localhost:8080";
}
