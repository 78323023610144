var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"variant":"primary","size":"sm","disabled":_vm.isLoading},on:{"click":function($event){return _vm.ShowModal()}}},[(_vm.isLoading)?_c('i',{staticClass:"fa fa-lg fa-spinner fa-spin"}):_vm._e(),_c('i',{staticClass:"fa fa-lg fa-search"}),_vm._v(" "+_vm._s(_vm.ButtonName)+" ")]),(_vm.activated)?_c('b-modal',{ref:"mymodal",attrs:{"id":_vm.Modelname,"size":"xl","content-class":"shadow","modal-class":"modal-regular modal-regular--issmall","header-class":"modal-header-pagination","body-class":"modal-body--haspadding-b"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('headline',{attrs:{"transactionModel":_vm.transactionModel,"transactions":_vm.transactions},on:{"onClose":function($event){return _vm.CloseModal()}}})],1)]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [(_vm.error != '' && _vm.error != null)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e(),(_vm.newItem)?_c('create-transaction',{on:{"TransactionCreated":_vm.onCreated}}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[(!_vm.newItem  && _vm.RueckfrageText != '')?_c('div',{staticClass:"inquiry-comment-customer inquiry-comment-forcefullwidth mb-3"},[_c('div',{staticClass:"inquiry-answer-customer__text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.RueckfrageText)}})])]):_vm._e(),_c('div',{staticClass:"form-data form-data__modal"},[_c('transaction-display-Headline',{attrs:{"transactionModel":_vm.transactionModel}})],1),_c('b-vue-form-generator',{ref:"vfg",attrs:{"schema":{fields: _vm.Schema.fields},"model":_vm.Model,"options":_vm.formOptions}})],1)]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"modal-footer__buttonbar"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.CloseModal()}}},[_c('i',{staticClass:"fa fa-lg fa-times"}),_vm._v("Schließen ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.SaveMe()}}},[_c('i',{staticClass:"fa fa-lg fa-check"}),_vm._v("Speichern und Anfrage erneut senden ")])],1)])],1)],1)]}}],null,false,3304327778)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }