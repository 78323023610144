


















import { Component, Prop, Vue, Model, Mixins } from "vue-property-decorator";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

@Component({})
export default class HeadlinePager extends Vue {
  @Prop() transactionModel!: Components.Schemas.Transaction;
  @Prop() transactions!: Components.Schemas.Transaction[];
  @Prop() visible!: boolean;
  public mounted() {}

  }

