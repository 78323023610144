






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { APPMODE } from "@/services/Config";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";

@Component({
  components: { MarketingMenuHeader },
})
export default class ResetPasswordSuccess extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  public GetCleanEmail(text: string) {
    return text.indexOf(APPMODE + "_") >= 0
      ? text.substring(APPMODE.length + 1)
      : text;
  }

  @Prop()
  public email!: string;
  public mounted() {}
}
