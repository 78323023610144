










































import {SiteUrls,APPMODE, AppModes, ENVIRONMENT} from '@/services/Config'
import { Environment } from '@/services/Environment';
import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";

@Component({
  components: {}
})

export default class MarketingMenuHeader extends Vue {
  menueopen:boolean = true;
  siteUrls:Function = SiteUrls;
  appmode:AppModes = APPMODE;

  public get MarketingWebsiteBaseUrl():string{
    return Environment.MarketingWebsiteBaseUrl;
  }
}
