





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Environment } from '@/services/Environment'
import { UserModule } from '@shared/store/modules/UserAuthModule'


@Component
export default class Backend extends Vue {

  /*
  get username(){
    return this.credentials.username;
  }

  get username(){
    return this.credentials.password;
  }*/

  public mounted () {


    // this.$store.dispatch('loadCategories')
  }

}
