































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { APPMODE,AppModes,RoleMap,UserPoolMap } from '@/services/Config';
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";
import headlineAccount from "@shared/components/General/HeadlineAccount.vue";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import UserDialogMixin from "@shared/components/GV/UserDialogMixin";
import EditProfile from "@shared/components/General/AccountParts/EditProfile.vue";
import PrivacyNugget from "@shared/services/nuggets/PrivacyNugget";
@Component({
  mixins: [UserDialogMixin],
  components: {
    headlineAccount,

    EditProfile
  }
})
export default class GVNewUser extends Mixins(UserDialogMixin) {
  public isLoading: boolean = false;
  public error: string | null = null;

  @Prop()
  public userType!: string;

  public get UserTypeName():string{
    switch (this.userType){
      case "pawn": return "Pfandleiher";
      case "customer": return "Kunde";
      case "gv": return "Golending User";
    }
    return "Kunde";
  }

  public async AddNewUser() {
    var roleid = -1;
    this.isLoading = true;
    if (this.userModel != null){
      switch (this.userType){
      case "pawn": this.userModel.userPoolId = UserPoolMap.Pawn; roleid = RoleMap.Pawn; break;
      case "customer": this.userModel.userPoolId = UserPoolMap.User; roleid = RoleMap.User; break;
      case "gv": this.userModel.userPoolId = UserPoolMap.Admin; roleid = RoleMap.Admin;break;
    }

    this.user = APPMODE+"_"+this.Profile?.email??"";
    this.userModel.userName = this.userModel.eMail =  APPMODE+"_"+this.Profile?.email??"";
    try{
    this.userModel = await UserModule.Register(this.userModel!);
    }catch (ex){
      this.error =ex;
      console.log(ex);
      return;
      this.isLoading = false;
    }
    if (roleid >= -1){

       await ManagementNugget.AddUserRole(this.userModel,roleid);
       try{
       await ManagementNugget.RemoveUserRole(this.userModel,RoleMap.User);
       }catch(ex){

       }
       const roles = await ManagementNugget.GetUserRoles(this.userModel!);
    }
    await PrivacyNugget.CreateSafe(this.userModel.entityId);
    await this.SaveContactDetails(this.Profile);
    this.$router.push("/GV/gvlistusers");
    }
  }
}
