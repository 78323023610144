







import { Component, Prop, Vue, Mixins,Emit } from "vue-property-decorator";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import { Client,Definitions } from "@shared/services/nuggets/PrivacySvc";
import { APPMODE } from '@/services/Config';

const moment = require("moment");

@Component({components:{   }})
export default class HeadlineAccount extends Vue {
  @Prop() userModel!: Definitions.PrivacyProfile;
  @Prop() headline!: string;

  moment() {
    moment.locale("de");
    return moment;
  }
  public CleanEmail(value: string) {
    if (value.startsWith(APPMODE + "_"))
      return value.substr(APPMODE.length + 1);
    return value;
  }

  public mounted() {}
}
