import { Component, Prop, Vue } from "vue-property-decorator";
import { totalSum, totalInterest, totalFees } from "@shared/CalculateTotal";
import {
  TransactionModule,
  NewTransaction,
} from "@shared/store/modules/TransactionModule";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
@Component
export default class OfferMixin extends Vue {
  public GetOfferVM(
    t: Components.Schemas.Transaction,
    v: Components.Schemas.Offer,
    profile: Definitions.PrivacyProfile | null = null
  ): any {
    return {
      id: v.id,
      pawnbrokername_pawnbrokeraddress: {
        pawnbrokername: profile?.company
          ? profile?.company
          : (profile?.firstname ?? "") + " " + (profile?.lastname ?? ""),
        pawnbrokeraddress:
          (profile?.postalcode ?? "") +
          " " +
          (profile?.city ?? "") +
          ", " +
          PrivacyNugget.GetCountryName(profile?.country ?? ""),
      },
      creditamount: v.values["kreditbetrag"],
      creditinterest: {
        creditinterest: totalInterest(
          Number(v.values["kreditbetrag"]),
          Number(v.values["zinsen"]),
          Number(t.period)
        ),
        creditinterestpercent: v.values["zinsen"],
      },
      creditfees: totalFees(
        Number(v.values["kreditbetrag"]),
        Number(v.values["gebühren"]),
        Number(t.period)
      ),
      creditsum: totalSum(
        Number(v.values["kreditbetrag"]),
        Number(v.values["zinsen"]),
        Number(v.values["gebühren"]),
        Number(t.period)
      ),
    };
  }
}
