import { Component, Prop, Vue } from "vue-property-decorator";
import {
  TransactionModule,
  NewTransaction,
} from "@shared/store/modules/TransactionModule";
const VueFormGenerator = require("../vue-form-generator/vfgindex");
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { Environment } from "@/services/Environment";
import { sumBy, filter } from "lodash";
import { FilterSettings } from "@shared/components/General/FilterSettings";
import moment from "moment";
import {
  IMG_PREVIEWFILETYPE,
  IMG_LISTPREVIEWHEIGHT,
  IMG_LISTPREVIEWWIDTH,
  IMG_BACKGROUND,
  IMG_LISTORIGINALSIZE,
} from "@/services/Config";

@Component
export default class TransactionListMixin extends Vue {
  public CurrentFilterSettings: FilterSettings = new FilterSettings();
  public timer: any = "";
  moment() {
    moment.locale("de");
    return moment;
  }

  mounted() {}

  GetPfTransactionCount(transactionObject: Components.Schemas.Transaction) {
    var employeeIds = UserModule.users;
    return transactionObject.responses
      .filter(
        (x) =>
          x?.offerstate != "deleted" &&
          !employeeIds.some((i) => i.entityId == x.userId)
      )
      .map((u) => u.userId).length;
  }

  public TransactionSum(
    transactions: Components.Schemas.Transaction[]
  ): number {
    var t = this;
    let isNaN = (maybeNaN) => maybeNaN != maybeNaN;
    return sumBy(transactions, function (i: Components.Schemas.Transaction) {
      const selectedval = t.SelectedOfferSum(i);
      return isNaN(Number(selectedval)) ? 0 : Number(selectedval);
    });
  }
  public HasSelectedOffer(value: Components.Schemas.Transaction) {
    return value.responses.filter((x) => x.offerstate == "selected").length;
  }

  public SelectedOfferSum(value: Components.Schemas.Transaction): number {
    const offers = value.responses.filter(
      (x) => x.offerstate == "selected"
    );
    if (offers.length == 0) {
      return value.summwishedfor;
    } else {
      const offer = offers[0];
      return offer.values["kreditbetrag"];
    }
  }
  public GetTransactionVM(v: Components.Schemas.Transaction): any {
    var title = "";
    const key =
      v.category.descriptionKeyField != ""
        ? v.category.descriptionKeyField.toLowerCase()
        : "titel.de";
    title = v.requestValues[key];
    if (title != undefined && title.length > Environment.LISTTEXTLENGTH)
      title.substring(0, Environment.LISTTEXTLENGTH);
    try {
      var image = JSON.parse(
        v.requestValues[v.category.imageKeyField.toLowerCase()]
      )[0];
      if (image != "" && image != undefined) {
      }
    } catch (ex) {}
    var imageUrl: string = "";

    imageUrl =
      image != undefined
        ? Environment.API_ENDPOINT +
          "/file/preview?guid=" +
          v.id +
          "&Language=de&width=" +
          IMG_LISTPREVIEWWIDTH +
          "&height=" +
          IMG_LISTPREVIEWHEIGHT +
          "&format=" +
          IMG_PREVIEWFILETYPE +
          "&originalsize=" +
          IMG_LISTORIGINALSIZE.toString() +
          "&background=" +
          IMG_BACKGROUND +
          "&filename=" +
          encodeURI(image) +
          "&CloudnuggetJWTToken=" +
          UserModule.Token
        : "";
    return {
      date: {
        runsOutDate: moment(v.createDate).add(24, "hour"),
        createDate: moment(v.createDate),
      },
      country:
        Environment.CountryOptions.filter((m) => m.value === v.country)[0]
          ?.text ?? "unbekannt",
      id: v.id.substring(0, 6),
      fullid: v.id,
      state: v.state == "closedok" ? "Angenommen" : "Abgelehnt",
      inquirytype: {
        name:
          v.transactionMode?.toLowerCase() == "pawn" ? "Beleihen" : "Verkaufen",
      },
      category: { name: v.category.name },
      media: {
        imageurl: imageUrl,
      },
      title: title,
      runtime: v.transactionMode?.toLowerCase() == "pawn" ? v.period : "-",
      sum: v.summwishedfor,
      transactionObject: v,
    };
  }
  public GetOffer(
    value: Components.Schemas.Transaction
  ): Components.Schemas.Offer {
    const offers = value.responses;
    return offers[0];
  }
  public GetTransactionOfferVM(v: Components.Schemas.Transaction): any {
    var title = v.requestValues[v.category.descriptionKeyField.toLowerCase()];
    if (title != undefined && title.length > Environment.LISTTEXTLENGTH)
      title.substring(0, Environment.LISTTEXTLENGTH);
    try {
      var image = JSON.parse(
        v.requestValues[v.category.imageKeyField.toLowerCase()]
      )[0];
      if (image != "" && image != undefined) {
      }
    } catch (ex) {}
    var imageUrl: string = "";

    imageUrl =
      image != undefined
        ? Environment.API_ENDPOINT +
          "/file/preview?guid=" +
          v.id +
          "&Language=de&width=" +
          IMG_LISTPREVIEWWIDTH +
          "&height=" +
          IMG_LISTPREVIEWHEIGHT +
          "&format=" +
          IMG_PREVIEWFILETYPE +
          "&originalsize=" +
          IMG_LISTORIGINALSIZE.toString() +
          "&background=" +
          IMG_BACKGROUND +
          "&filename=" +
          encodeURI(image) +
          "&CloudnuggetJWTToken=" +
          UserModule.Token
        : "";
    const offer = this.GetOffer(v);
    return {
      date: {
        runsOutDate: moment(v.createDate).add(24, "hour"),
        createDate: moment(v.createDate),
      },
      country:
        Environment.CountryOptions.filter((m) => m.value === v.country)[0]
          ?.text ?? "unbekannt",
      id: v.id.substring(0, 6),
      fullid: v.id,
      inquirytype: {
        name:
          v.transactionMode?.toLowerCase() == "pawn" ? "Beleihen" : "Verkauf",
      },
      category: { name: v.category.name },
      media: {
        imageurl: imageUrl,
      },
      title: title,

      runtime: v.transactionMode?.toLowerCase() == "pawn" ? v.period : "-",
      sum: offer != undefined ? offer.values["kreditbetrag"] : "",
      fees:
        offer != undefined
          ? (Number(offer.values["kreditbetrag"]) / 100) *
            Number(offer.values["gebühren"]) *
            Number(v.period)
          : "",
      interest:
        offer != undefined
          ? (Number(offer.values["kreditbetrag"]) / 100) *
            Number(offer.values["zinsen"]) *
            Number(v.period)
          : "",
      transactionObject: v,
    };
  }

  public GetDateDiff(from, to): number {
    const temp = moment.duration(moment(to).diff(moment(from))).asHours();
    return temp;
  }

  public TransactionsVM(transactions: Components.Schemas.Transaction[]): any {
    if (this.CurrentFilterSettings.IsSet()) {
      if (this.CurrentFilterSettings.CategoryId != "")
        transactions = transactions.filter(
          (x) => x.category.id == this.CurrentFilterSettings.CategoryId
        );
      if (this.CurrentFilterSettings.Type != "")
        transactions = transactions.filter(
          (x) => x.transactionMode == this.CurrentFilterSettings.Type
        );
    }
    var unfiltered = transactions.map(this.GetTransactionVM);
    if (this.CurrentFilterSettings.SearchPhrase != "")
      unfiltered = unfiltered.filter(
        (x) =>
          (x.title?.toLowerCase() ?? "").indexOf(
            this.CurrentFilterSettings.SearchPhrase.toLowerCase()
          ) >= 0
      );
    if (this.CurrentFilterSettings.ReactionTime != 0)
      unfiltered = unfiltered.filter(
        (x) =>
          this.GetDateDiff(x.runsOutDate, moment()) <=
          this.CurrentFilterSettings.ReactionTime
      );
    return unfiltered;
  }

  public TransactionsOfferVM(
    transactions: Components.Schemas.Transaction[]
  ): any {
    if (this.CurrentFilterSettings.IsSet()) {
      if (this.CurrentFilterSettings.CategoryId != "")
        transactions = transactions.filter(
          (x) => x.category.id == this.CurrentFilterSettings.CategoryId
        );
      if (this.CurrentFilterSettings.Type != "")
        transactions = transactions.filter(
          (x) => x.transactionMode == this.CurrentFilterSettings.Type
        );
    }
    var unfiltered = transactions.map(this.GetTransactionOfferVM);
    if (this.CurrentFilterSettings.SearchPhrase != "")
      unfiltered = unfiltered.filter(
        (x) =>
          (x.title?.toLowerCase() ?? "").indexOf(
            this.CurrentFilterSettings.SearchPhrase.toLowerCase()
          ) >= 0
      );
    if (this.CurrentFilterSettings.ReactionTime != 0)
      unfiltered = unfiltered.filter(
        (x) =>
          this.GetDateDiff(x.runsOutDate, moment()) <=
          this.CurrentFilterSettings.ReactionTime
      );
    return unfiltered;
  }
}
