import { render, staticRenderFns } from "./GVListFeedback.vue?vue&type=template&id=7761a504&scoped=true&"
import script from "./GVListFeedback.vue?vue&type=script&lang=ts&"
export * from "./GVListFeedback.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7761a504",
  null
  
)

export default component.exports