































































































































































import { Component, Prop, Vue, Model, Mixins } from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import OfferMixin from "@shared/components/General/OfferMixin";
import Headline from "@shared/components/General/Headline.vue";
@Component({
  mixins: [ModalMixin, OfferMixin],
  components: { TransactionDisplayHeadline, TransactionDisplay, Headline },
})
export default class PFOfferAccepted extends Mixins(ModalMixin, OfferMixin) {
  public async OnActivated() {}

  public OffersVM(transaction: Components.Schemas.Transaction): Array<any> {
    if (transaction == undefined) return [];
    return transaction.responses.map((x) =>
      this.GetOfferVM(transaction,x, null)
    );
  }

  public mounted() {
    if (this.transactionModel.transactionMode.toLowerCase() != "pawn") {
      this.fields = [
        {
          key: "creditamount",
          label: "Gebot",
          thClass: "th-creditamount",
          tdClass: "td-creditamount",
        },
      ];
    }
  }
  public checked_custome_contact: boolean = false;
  public fields: Array<any> = [
    {
      key: "creditamount",
      label: "Kreditbetrag",
      thClass: "th-creditamount",
      tdClass: "td-creditamount",
    },
    {
      key: "creditinterest",
      label: "Zinsen",
      thClass: "th-creditinterest",
      tdClass: "td-creditinterest",
    },
    {
      key: "creditfees",
      label: "Gebühren",
      thClass: "th-creditfees",
      tdClass: "td-creditfees",
    },
    {
      key: "creditsum",
      label: "Gesamtkosten",
      thClass: "th-creditsum",
      tdClass: "td-creditsum",
    },
  ];
  // ---------------------- ITEMS ------------------------
}
