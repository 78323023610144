var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"variant":"secondary","size":"sm","disabled":_vm.isLoading},on:{"click":function($event){return _vm.ShowModal()}}},[(_vm.isLoading)?_c('i',{staticClass:"fa fa-lg fa-spinner fa-spin"}):_vm._e(),_c('i',{staticClass:"fa fa-lg fa-search"}),_vm._v(" "+_vm._s(_vm.ButtonName)+" ")]),(_vm.activated)?_c('b-modal',{ref:"mymodal",attrs:{"id":_vm.Modelname,"size":"xl","content-class":"shadow","modal-class":"modal-regular","header-class":"modal-header-pagination","body-class":"modal-body--haspadding-t"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('headline',{attrs:{"transactionModel":_vm.transactionModel,"transactions":_vm.transactions},on:{"onClose":function($event){return _vm.CloseModal()}}})],1)]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"form-data form-data__modal"},[_c('transaction-display-Headline',{attrs:{"transactionModel":_vm.transactionModel}}),(_vm.error != '')?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e(),_c('transaction-display',{attrs:{"transactionModel":_vm.transactionModel}}),(_vm.noaction == false)?_c('div',{staticClass:"inquiry-comment-admin inquiry-comment-forcefullwidth"},[_c('div',{staticClass:"form-data__row"},[_c('div',{staticClass:"form-data__cell"},[_c('div',{staticClass:"form-data__label form-data__label--islarge"}),_c('div',{staticClass:"form-data__record"},[_c('b-form-textarea',{attrs:{"id":"textarea-description","placeholder":"","rows":"3","max-rows":""},model:{value:(_vm.RueckfrageText),callback:function ($$v) {_vm.RueckfrageText=$$v},expression:"RueckfrageText"}})],1)])])]):_vm._e()],1)])]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"modal-footer__buttonbar"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return cancel()}}},[_c('i',{staticClass:"fa fa-lg fa-times"}),_vm._v("Schließen ")]),(_vm.noaction == false || _vm.transactionModel.state == 'new')?_c('inquiry-delete-confirmation',{attrs:{"transaction":_vm.transaction,"buttonname":_vm.deleteButton},on:{"transactionDeleted":_vm.OnDeleted}}):_vm._e(),(_vm.noaction == false)?_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.Rueckfragen();hide('incomplete')}}},[_c('i',{staticClass:"fa fa-lg fa-question"}),_vm._v(" "+_vm._s(_vm.$t('GV_Rueckfrage_Buttontext'))+" ")]):_vm._e(),(_vm.noaction == false)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.Release(_vm.transactionModel.id);ok()}}},[_c('i',{staticClass:"fa fa-lg fa-check"}),_vm._v(" "+_vm._s(_vm.$t('GV_Release_ButtonText'))+" ")]):_vm._e()],1)])],1)],1)]}}],null,false,782878511)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }