














































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");

import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import VuePictureSwipe from 'vue-picture-swipe';

@Component({
  mixins: [TransactionListMixin, TransactionMixin],
  components: { InquiryDeleteConfirmation,VuePictureSwipe }
})
export default class TransactionDisplay extends Mixins(
  TransactionListMixin,
  TransactionMixin
) {
  @Prop() transactionModel!: Components.Schemas.Transaction;

  public get IsTransactionLoaded(){
    return TransactionModule.CurrentTransaction != null;
  }
  public created(){
    TransactionModule.SET_TRANSACTION(this.transactionModel);

  }
  public TransactionVM(): any {
    return this.GetTransactionVM(this.transactionModel);
  }

}
