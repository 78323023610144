<template lang="pug">
	.input-group.date
		date-picker(v-model="innerValue" :config="{maxDate: nowValue, format:'D.M.YYYY'}", @dp-change="onInput" :autocomplete="schema.autocomplete", :disabled="disabled", :readonly="schema.readonly", :name="schema.inputName", :id="getFieldID(schema)")
</template>

<script>
/* global $ */


import abstractField from "../abstractField";
import dateFieldHelper from "vue-form-generator/src/utils/dateFieldHelper";
import * as $ from "jquery";
import datePicker from "vue-bootstrap-datetimepicker";
// Import date picker css
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import moment from 'moment'

export default {
  mixins: [abstractField],
  components: {
    datePicker
  },
  computed:{
        nowValue: {
          get(){
            return moment();
          }
        },
    		innerValue: {
			get() {
        if (this.model[this.schema.model] == undefined) return "";
			   return this.model[this.schema.model].toString();
			},
			set(newValue) {
        console.log('Set:' +moment(newValue).toISOString());
		   	this.model[this.schema.model] = moment(newValue).toISOString();
			}
		}
  },
  methods: {
    onInput(value,oldvalue) {
      console.log("changed " + value.target.value);

  //	this.model[this.schema.model] = value.target.value;
      this.value = value.target.value;
      this.schema.onChanged.call(this, this.model, this.schema, this);
    }
  },
  data: {
  },
  mounted() {

  },


};
</script>


<style lang="scss">
</style>
