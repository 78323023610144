











































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit,
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import OfferAcceptedView from "@shared/components/Customer/Parts/OfferAcceptedView.vue";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";

import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");

import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import Headline from "@shared/components/General/Headline.vue";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
import { Schema } from "inspector";

@Component({
  mixins: [ModalMixin, TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    TransactionDisplayHeadline,
    OfferAcceptedView,
    Headline,
  },
})
export default class CustomerOfferAccepted extends Mixins(
  ModalMixin,
  TransactionListMixin,
  TransactionMixin
) {
  direct = false;
  public async mounted() {
    if (
      this.$router.currentRoute.params.id != undefined &&
      this.transactionModel == null
    ) {
      this.activated = true;
      this.transactionModel = await TransactionNugget.GetTransaction(
        this.$router.currentRoute.params.id ??
          this.$router.currentRoute.params.transactionid
      );
      this.direct = true;
      this.ShowModal();
    }
  }

  public CloseModal() {
    if (this.$refs.mymodal) {
      (this.$refs.mymodal as any).hide();
      this.activated = false;
      this.direct = false;
      this.$router.push({ name: "archivlist" });
    }
  }
  public async OnActivated() {}
}
