




































































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import { mixins } from "vue-class-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, confirmed, email } from "vee-validate/dist/rules";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import EditProfile from "@shared/components/General/AccountParts/EditProfile.vue";
import UserDialogMixin from "@shared/components/GV/UserDialogMixin";
import { ENVIRONMENT } from "@/services/Config";

@Component({
  mixins: [UserDialogMixin, TransactionMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BTextInputWithValidation,
    EditProfile,
  },
})
export default class Request5 extends Mixins(
  UserDialogMixin,
  TransactionMixin
) {
  public isLoading: boolean = false;
  public error: string | null = null;

  public async SaveData(profile) {
    this.isLoading = true;
    (<any>this.$refs.observer).validate();
    const isinvalid = !(await (<any>this.$refs.observer).validate());
    if (isinvalid == true) {
      this.isLoading = false;
      return;
    }
    await this.SaveContactDetails(profile);
    this.isLoading = false;
    this.$router.push("customeroverview");
    Environment.JumpToMainApp(
      UserModule.Claims[0],
      UserModule.Token,
      this.$router
    );
    // window.location.replace(Environment.WebsiteBaseUrl+"/#/Customer/CustomerOverview");
  }
}
