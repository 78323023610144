import { render, staticRenderFns } from "./Request3.vue?vue&type=template&id=7dfc6089&scoped=true&"
import script from "./Request3.vue?vue&type=script&lang=ts&"
export * from "./Request3.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dfc6089",
  null
  
)

export default component.exports