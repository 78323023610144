

















































import { Component, Vue } from "vue-property-decorator";
import Home from "@shared/components/Home.vue";
import LanguageChooser from "@shared/components/General/LanguageChooser.vue";
import axios from "axios";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import "@shared/components/vue-form-generator/vfg.css";
import { EventBus } from '@/eventbus';

@Component({
  components: {
    Home,
    LanguageChooser
  }
})
export default class App extends Vue {
  public AppName: string = "Appname";
  public Isloaded:Boolean = false;
  constructor() {
    super();
  }

  public created(){
   this.Isloaded = this.$router.currentRoute.path.toLowerCase().indexOf('gv') >=0|| this.$router.currentRoute.path.toLowerCase().indexOf('customer') >=0|| this.$router.currentRoute.path.indexOf('pf')>=0
// Listen for the i-got-clicked event and its payload.
EventBus.$on('AppLoaded', clickCount => {
  console.log('AppLoadedrecieved');
  this.Isloaded = true;
});
  }
}
