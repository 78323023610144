


































































import { Component, Prop, Vue,Model,Mixins,Emit } from 'vue-property-decorator'
import ModalMixin  from '@shared/components/General/ModalMixin';
import TransactionNugget from '@shared/services/nuggets/TransactionNugget';
@Component({ mixins: [ModalMixin] })
export default class InquiryForwardConfirmation extends Mixins(ModalMixin) {

@Prop()
public text! :string;//Wenn Sie die Anfrage ablehnen wird der Kunde davon informiert, und die Anfrage zur weiteren Bearbeitung gesperrt.";

@Prop()
public subtext! :string;

@Prop()
public deleteButton! :string;

public isLoading :boolean =false;
 public async OnActivated() {
  if (this.deleteButton == null) this.deleteButton= "Anfrage nicht abwarten";
  if (this.subtext == null) this.subtext= "";
  if (this.text == null) this.text= "Wenn Sie das tun, dann können die Pfandleiher keine weiteren Angebote abgeben. Sie finden diese Anfrage dann unter Pfandleiherangebote";

 }

  @Emit('transactionForwarded')
  TransactionForwarded(id:string) {

  }

  public async ForwardTransaction(){
    this.isLoading = true;
      await TransactionNugget.TransitionTransactionStateById(this.transaction,'lenderstimeout');
      this.TransactionForwarded(this.transaction);
      this.CloseModal();
      this.isLoading = false;
  }
}
