
import { AxiosResponse, AxiosInstance } from 'axios'
import axios from 'axios'
import { CloudNuggetService } from '@verticals/cloudnugget.frontend.js/bin/lib/cloud-nugget-service'
import { CloudNuggetAuthenticationService } from '@verticals/cloudnugget.frontend.js/bin/lib/cloud-nugget-authentication-service'
import { TokenData, Claim } from '@verticals/cloudnugget.frontend.js/bin/lib/data/token-data'
import { ENVIRONMENT, RoleMap, UserPoolMap,APPENTITYID } from '@/services/Config'
import * as ApiEnvironment from "@/services/Environment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserModule } from '@shared/store/modules/UserAuthModule'

export interface User {
  id: string,
  entityId:string,
  firstName: string;
  lastName: string;
  userName: string;
  userPoolId: number;
  eMail: string;
  language: string;
  timeZone: string;
  password: string;
  userRoles: string;
  lockedDate?: Date;
}


export interface UserChangePassword {
  id: string,
  entityId:string,
  firstName: string;
  lastName: string;
  userName: string;
  userPoolId: number;
  eMail: string;
  language: string;
  timeZone: string;
  password: string;
  userRoles: string;
  lockedDate?: Date;
  secret: string;
  appEntityId: string;

}
export interface UserAndProfile {
  id: string,
  entityId:string,
  firstName: string;
  lastName: string;
  userName: string;
  userPoolId: number;
  eMail: string;
  language: string;
  timeZone: string;
  password: string;
  company: string;
}

export class ProfileDto {

  constructor(email: string) {
    this.email = email;
  }
  email: string = '';
}

export class ManagementNugget extends Vue {


  public static GetAxiosClient() :AxiosInstance{
    const apiClient = axios.create({
      baseURL: ApiEnvironment.Environment.CNMGMNTAPI_ENDPOINT,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'CloudNuggetJWTToken':UserModule.Token
      }

    });
    apiClient.defaults.headers.common['CloudNuggetJWTToken'] =  UserModule.Token;
    return apiClient;
  }
  public static async ConfirmUser(myuser: User) {

    const apiClient = this.GetAxiosClient();
    try {
      const response = await apiClient.get('/users/'+encodeURIComponent(APPENTITYID)+'/'+encodeURIComponent(myuser.eMail)+'/confirm');
    } catch (err) {
      throw err;
    }
  }

  public static async ForgotPassword(myuser: User) {

    const apiClient = this.GetAxiosClient();
    try {
      const response = await apiClient.patch('/users/'+encodeURIComponent(APPENTITYID)+'/'+encodeURIComponent(myuser.eMail)+'/reset');
    } catch (err) {
      throw err;
    }
  }
  public static async DeleteUser(myuser: User) {

    const apiClient = this.GetAxiosClient();
    try {
      const response = await apiClient.delete('/users/'+encodeURIComponent(myuser.id));
    } catch (err) {
      throw err;
    }
  }


  public static async AddUserRole(myuser: User,roleId:number) {

    const apiClient = this.GetAxiosClient();
    try {
      const response = await apiClient.post('/users/' + encodeURIComponent(myuser.id) + '/roles', {userpoolroleid:roleId});
    } catch (err) {
      throw err;
    }
  }

  public static async GetUserRoles(myuser: User) {

    const apiClient = this.GetAxiosClient();
    try {
      const response = await apiClient.get('/users/' + encodeURIComponent(myuser.id) + '/roles');
    } catch (err) {
      throw err;
    }
  }


  public static async RemoveUserRole(myuser: User,roleId:number) {

    const apiClient = this.GetAxiosClient();
    try {
      const response = await apiClient.delete('/users/' + encodeURIComponent(myuser.id) + '/roles/'+roleId);
    } catch (err) {
      throw err;
    }
  }


  public static async GetUsers() {
    try {
      const response = await this.GetAxiosClient().get<User[]>('/users');
      const user = response.data.filter(m=>m.userPoolId == UserPoolMap.Admin || m.userPoolId == UserPoolMap.Pawn || m.userPoolId == UserPoolMap.User);
      return user;
    } catch (err) {
      throw err;
    }

  }
  public static async GetUser(id:string) {
    try {
      const response = await this.GetAxiosClient().get<User>('/users/'+id);
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }

  }

  public static async UpdateUser(myuser:User) {
    try {
      const response = await this.GetAxiosClient().put<User>('/users/'+myuser.id,myuser);
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }

  }


  public static async GetUserByEmail(email:string) {
    try {
      const response = await this.GetAxiosClient().get<User>('/users/'+email+"/byemail");
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }

  }

  public static async GetUserByEntityId(entityId:string) {
    try {
      const response = await this.GetAxiosClient().get<User>('/users/'+entityId+"/byentityid");
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }

  }

  public static async GetProfile() {
    try {
      const response = await this.GetAxiosClient().get<User>('/users/profile');
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }

  }
}
