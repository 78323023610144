const env = (process.env.NODE_ENV as string).trim();
import { ENVIRONMENT, APPMODE, AppModes } from "@/services/Config";
import { CloudNuggetReportingService } from "@verticals/cloudnugget.frontend.js/bin/lib/cloud-nugget-reporting-service";
import VueRouter from "vue-router";

export class Environment {
  public static get MarketingWebsiteBaseUrl(): string {
    if (ENVIRONMENT === "develop") {
      return "http://localhost:8080";
    } else if (ENVIRONMENT === "staging") {
      return "https://diegeldverleiher-staging.azurewebsites.net";
    } else if (ENVIRONMENT === "master") {
      return "https://www.diegeldverleiher.com";
    }
    return "";
  }

  public static readonly LISTTEXTLENGTH = 30;
  public static CLOUDNUGGETS_TOKEN: string = "cloudnuggets_token";
  public static PRIVACY_URL: string =
    Environment.MarketingWebsiteBaseUrl + "/diegeldverleiher-datenschutz.html";
  public static AGB_URL: string =
    Environment.MarketingWebsiteBaseUrl + "/diegeldverleiher-agb.html";
  public static IMPRESSUM_URL: string =
    Environment.MarketingWebsiteBaseUrl + "/diegeldverleiher-impressum.html";
  public static REFRESHTIME: number = 15000;
  public static TOKENSTORAGE = sessionStorage;
  public static CountryOptions: Array<any> = [
    { value: null, text: "Land auswählen" },
    { value: "at", text: "Österreich" },
    { value: "de", text: "Deutschland" },
    { value: "ch", text: "Schweiz" },
    { value: "nl", text: "Niederlande" },
  ];

  private static svc = new CloudNuggetReportingService("");

  static GOLENDING_ENABLED: boolean = false;
  static PFANDLAEDCHEN_ENABLED: boolean = false;
  static JumpOffMode: boolean = true;

  public static JumpToMainApp(
    claims: string,
    token: string,
    router: VueRouter
  ) {
    if (Environment.JumpOffMode) {
      switch (claims) {
        case "CUST": {
          const custUrl =
            Environment.WebsiteBaseUrl +
            "/#/Customer/Enter/Token/" +
            encodeURI(token);
          console.log(custUrl);
          //   alert(Environment.WebsiteBaseUrl+"/#/Customer/Enter/Token/"+token);
          parent.postMessage({ message: "leave", url: custUrl }, "*");
          //  alert('leaving');
          //  window.location.replace(custUrl);
          break;
        }
        case "PB": {
          const pfUrl =
            Environment.WebsiteBaseUrl +
            "/#/pf/Enter/Token/" +
            encodeURI(token);
          console.log(pfUrl);
          parent.postMessage({ message: "leave", url: pfUrl }, "*");
          //   alert('leaving');
          //  window.location.replace(pfUrl);
          break;
        }
        case "GV": {
          const gvurl =
            Environment.WebsiteBaseUrl +
            "/#/GV/Enter/Token/" +
            encodeURI(token);
          console.log(gvurl);
          parent.postMessage({ message: "leave", url: gvurl }, "*");
          //   alert('leaving');
          //   window.location.replace(gvurl);
          break;
        }
      }
    } else {
      switch (claims) {
        case "CUST": {
          router.push("/Customer");
          break;
        }
        case "PB": {
          router.push("/pf");
          break;
        }
        case "GV": {
          router.push("/GV");
          break;
        }
      }
    }
  }

  public static get API_ENDPOINT(): string {
    if (APPMODE == AppModes.DieGeldverleiher) {
      return Environment.DIEGELDVERLEIHER_API_ENDPOINT;
    } else if (APPMODE == AppModes.Golending) {
      return Environment.GOLENDING_API_ENDPOINT;
    } else if (APPMODE == AppModes.Pfandlädchen) {
      return Environment.PFANDLAECHEN_API_ENDPOINT;
    }
    return "";
  }

  public static get DIEGELDVERLEIHER_API_ENDPOINT(): string {
    if (ENVIRONMENT === "develop") {
      return "https://localhost:44301";
    } else if (ENVIRONMENT === "staging") {
      return "https://diegeldverleihertransactionnugget-staging.azurewebsites.net/";
    } else if (ENVIRONMENT === "master") {
      return "https://diegeldverleihertransactionnugget.azurewebsites.net/";
    }
    return "";
  }

  public static get WebsiteBaseUrl(): string {
    if (ENVIRONMENT === "develop") {
      return "http://localhost:8080";
    } else if (ENVIRONMENT === "staging") {
      return "https://diegeldverleiherwebui-staging.azurewebsites.net";
    } else if (ENVIRONMENT === "master") {
      return "https://app.diegeldverleiher.com";
    }
    return "";
  }

  public static get GOLENDING_API_ENDPOINT(): string {
    if (ENVIRONMENT === "develop") {
      return "https://localhost:44301";
    } else if (ENVIRONMENT === "staging") {
      return "https://golendingtransactionnugget-staging.azurewebsites.net/";
    } else if (ENVIRONMENT === "master") {
      return "https://golendingtransactionnugget-master.azurewebsites.net";
    }
    return "";
  }

  public static get PFANDLAECHEN_API_ENDPOINT(): string {
    if (ENVIRONMENT === "develop") {
      return "https://localhost:44301";
    } else if (ENVIRONMENT === "staging") {
      return "https://pfandlaedchentransactionnugget-staging..azurewebsites.net/";
    } else if (ENVIRONMENT === "master") {
      return "https://pfandlaedchentransactionnugget.azurewebsites.net/";
    }
    return "";
  }

  public static get CNMGMNTAPI_ENDPOINT(): string {
    if (ENVIRONMENT === "develop") {
      return "http://localhost:80";
    } else if (ENVIRONMENT === "staging") {
      return "https://api.frontend.master.cloudnuggets.io";
    } else if (ENVIRONMENT === "master") {
      return "https://api.frontend.master.cloudnuggets.io";
    }
    return "";
  }

  public static get CNPRIVACYAPI_ENDPOINT(): string {
    if (ENVIRONMENT === "develop") {
      return "http://localhost:5000";
    } else if (ENVIRONMENT === "staging") {
      return "https://api.privacynugget.master.cloudnuggets.io";
    } else if (ENVIRONMENT === "master") {
      return "https://api.privacynugget.master.cloudnuggets.io";
    }
    return "";
  }

  public static get CNAPI_ENDPOINT(): string {
    if (ENVIRONMENT === "develop") {
      return "https://localhost:44369";
    } else if (ENVIRONMENT === "staging") {
      return "https://cloudnuggets.io/api/v1";
    } else if (ENVIRONMENT === "master") {
      return "https://master.cloudnuggets.io/api/v1";
    }
    return "";
  }

  constructor() {}
  public static IsTokenValid(token: string) {
    CloudNuggetReportingService.apiEndpoint = Environment.CNAPI_ENDPOINT; //SetEnvironment();
    //  Environment.svc.
  }

  public static getToken(): string | null {
    return sessionStorage.getItem(this.CLOUDNUGGETS_TOKEN);
  }

  public static setToken(token: string): void {
    sessionStorage.setItem(this.CLOUDNUGGETS_TOKEN, token);
  }

  public static clearToken(): void {
    sessionStorage.removeItem(this.CLOUDNUGGETS_TOKEN);
  }
}
