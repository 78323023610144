





























































































































import Vue2 from "vue";
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { mapState } from "vuex";
import * as ApiEnvironment from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";

import OpenAPIClientAxios from "openapi-client-axios";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

import { ENVIRONMENT } from "@/services/Config";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";

const moment = require("moment");
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import TransactionFilter from "@shared/components/General/TransactionFilter.vue";

import CustomerTop5Offers from "./Parts/CustomerTop5Offers.vue";
import CustomerOfferAccepted from "./Parts/CustomerOfferAccepted.vue";
import DateDisplay from "@shared/components/General/DateDisplay.vue";

@Component({
  mixins: [TransactionMixin, TransactionListMixin],
  components: {
    CustomerTop5Offers,
    CustomerOfferAccepted,
    TransactionFilter,
    DateDisplay,
  },
})
export default class OfferList extends TransactionListMixin {
  public isLoading: boolean = false;
  public error: string | null = null;
  public transactions: Components.Schemas.Transaction[] = [];
  @Prop({ default: true })
  public PageMode!: Boolean;
  @Emit("reloadArchivList")
  public reloadArchivList(id: string) {}

  public get Transactions() {
    return this.transactions;
  }

  public set Transactions(value: Components.Schemas.Transaction[]) {
    this.transactions = value;
  }
  public GetSortedVM() {
    return this.TransactionsVM(this.Transactions).sort(
      (a, b) => b.date.createDate - a.date.createDate
    );
  }

  public async created() {
    if (!UserModule.Authenticated) {
      await UserModule.AnonymousLogin();
    }

    await this.Reload();
    this.timer = setInterval(this.Reload, Environment.REFRESHTIME);
  }

  public beforeDestroy() {
    clearInterval(this.timer);
  }

  public async OnDelete(id: string) {
    await this.Reload();
  }
  public async OnReleased(id: string) {
    await this.Reload();
  }

  public async OnSelected(id: string) {
    this.reloadArchivList(id);
    this.Reload();
    clearInterval(this.timer);
  }

  public async OnOpened(id: string) {
    clearInterval(this.timer);
  }

  public async Reload() {
    this.Transactions = await TransactionNugget.GetTransactionsByState(
      "offersapproved"
    );
  }

  public get TransactionFields(): Array<any> {
    return [
      {
        key: "id",
        label: "Nummer",
        thClass: "th-number",
        tdClass: "td-number",
      },
      {
        key: "createDate",
        label: "Datum",
        thClass: "th-datetime",
        tdClass: "td-datetime",
        sortable: true,
      },
      {
        key: "country",
        label: "Land",
        thClass: "th-country",
        tdClass: "td-country",
      },
      {
        key: "inquirytype",
        label: "Art",
        thClass: "th-typeinquirynr",
        tdClass: "td-typeinquirynr",
        sortable: true,
      },
      {
        key: "category",
        label: "Kategorie",
        thClass: "th-category",
        tdClass: "td-category",
        sortable: true,
      },
      {
        key: "media",
        label: "Foto",
        thClass: "th-image",
        tdClass: "td-image",
      },
      {
        key: "title",
        label: "Titel",
        thClass: "th-title",
        tdClass: "td-title",
      },
      {
        key: "runtime",
        label: "Laufzeit",
        thClass: "th-term",
        tdClass: "td-term",
        sortable: true,
      },
      {
        key: "sum",
        label: "Betrag",
        thClass: "th-amount",
        tdClass: "td-amount",
        sortable: true,
      },
      {
        key: "btn-check",
        label: "",
        thClass: "th-btn-check",
        tdClass: "td-btn-check",
      },
    ];
  }

  // ---------------------- ITEMS ------------------------
}
