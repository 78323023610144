import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@shared/store'
const i18n = require('@shared/i18n');// from '../../i18n'


export interface ILanguageState {
  CurrentLanguage: string,
  Languages: string[]
 }

@Module({ dynamic: true, store, name: 'language' })
class LanguageStateModule extends VuexModule implements ILanguageState{
    currentLanguage: string =  sessionStorage.getItem('user-language') || navigator.language;
    languages: string[] = ['en-US','de-DE','fr-FR'];

    public get CurrentLanguage (): string {
      return this.currentLanguage;
    }

    public get Languages (): string[] {
      return this.languages;
    }

    @Mutation
    public Set_Language ( lang : string) {
    this.currentLanguage = lang;
    }


    @Action
    public SelectLanguage (lang:string) {
      sessionStorage.setItem('user-language',lang);
      this.Set_Language(lang);

      i18n.default.locale = lang.substr(0,lang.indexOf('-'));
    }

  }

export const LanguageModule = getModule(LanguageStateModule)
