




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Environment } from '@/services/Environment'
import  CompanySwitcher  from '@shared/components/General/CompanySwitcher.vue'
import { TransactionModule } from '@shared/store/modules/TransactionModule'
import headlineAccount from "@shared/components/General/HeadlineAccount.vue";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { APPMODE,AppModes } from '@/services/Config';
import { UserModule} from "@shared/store/modules/UserAuthModule"
import { User, ProfileDto, ManagementNugget } from '@shared/services/nuggets/ManagementNugget'
import PrivacyNugget from "@shared/services/nuggets/PrivacyNugget";
import { Client,Definitions } from "@shared/services/nuggets/PrivacySvc";
import EditProfile from "@shared/components/General/AccountParts/EditProfile.vue";
import {CustomData} from "@shared/services/nuggets/PrivacyNugget";
@Component({
  components: {
     headlineAccount, EditProfile
  }
})

export default class EditUser extends Vue {
  public isLoading: boolean = false
  public error: string | null = null;
  private user?: User;
  public profile:Definitions.PrivacyProfile = {};
  public customdata: CustomData | null=null;


  public async mounted () {
     this.user = UserModule.CNUser;
     var result:Definitions.PrivacyProfile  = UserModule.Profile;
     const cData = await TransactionNugget.GetCustomData(UserModule.UserEntityId);
     this.customdata = cData != undefined ? cData: null;
     if (result.firstname == null) result.firstname ="";
     if (result.lastname == null) result.lastname ="";
     this.profile = result;
    //await UserModule.LoadProfile()
  }

  public async SaveContactDetails(){
      await PrivacyNugget.UpdateSafe(UserModule.UserEntityId,this.profile);

   //   await TransactionNugget.UpdateCustomData(UserModule.UserEntityId,{ categories:["KFZ"]});
  }
}
