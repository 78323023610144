









































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import Headline from "@shared/components/General/Headline.vue";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import CreateTransaction from "../CreateTransaction.vue";
@Component({
  mixins: [ModalMixin, TransactionMixin],
  components: { Headline, TransactionDisplayHeadline, CreateTransaction }
})
export default class NewInquiry extends Mixins(
  ModalMixin,
  TransactionMixin
) {
  newItem: Boolean = true;
   public async OnActivated() {
   }
  //#endregion
  public created() {
    this.Initialize();
  }

  public Initialize(){
      if (!this.newItem) {
      this.EnableLabels = true;
      this.Pagenr = -1;
      TransactionModule.SelectCategory(this.transactionModel.category);


    }else{
      TransactionModule.Clear();
    }
  }

  @Emit("transactionChanged")
  public FireChanged(id: string) {}

  public onCreated() {
    this.newItem = false;
    this.transactionModel = TransactionModule.CurrentTransaction!;
    this.Initialize();
  }

  public async SaveMe(){
    this.isLoading = true;
    if (this.SaveModel(false)){
    await this.Changed(this.transactionModel.id);
    }
    this.isLoading = false;

  }

  public async Changed(id: string) {
    this.isLoading = true;
    await this.Save(false, "added");
    await TransactionModule.TakeOverCurrentTransaction();
    // await TransactionNugget.TransitionTransactionState(this.transactionModel,'new')
    this.FireChanged(this.transactionModel.id);
    this.$router.push("/customer");
    this.isLoading = false;
  }

  public mounted() {}

  public;
}
