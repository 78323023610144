































































































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { UserChangePassword } from "@shared/services/nuggets/ManagementNugget";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import { required, confirmed, email } from "vee-validate/dist/rules";
import { APPENTITYID } from "../../../../src/services/Config";
import { APPMODE } from "@/services/Config";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BTextInputWithValidation,
    MarketingMenuHeader,
  },
})
export default class ResetPassword extends Vue {
  isLoading: boolean = false;
  isDone: Boolean = false;
  oldPassword: string = "";
  newpassword: string = "";
  repeatNewPassword: string = "";
  error: string | null = null;
  @Prop({ default: "" })
  public secret!: string;

  @Prop({ default: "" })
  public userid!: string;

  @Prop({ default: "" })
  public email!: string;

  public mounted() {}

  async onSubmit() {
    const isValid = await (<any>this.$refs.observer).validate();
    if (isValid) {
      this.isLoading = true;
      await this.changepassword();
    }
  }

  async changepassword() {
    //@ts-ignore
    const isvalid = !(await (<any>this.$refs.observer).validate());
    const user: UserChangePassword = {
      userName: this.email,
      password: this.newpassword, //this.password,
      firstName: "",
      lastName: "",
      userPoolId: -1,
      language: "de",
      eMail: this.email,
      timeZone: "",
      id: "",
      entityId: "",
      secret: this.secret,
      appEntityId: APPENTITYID,
      userRoles: "",
    };
    this.isLoading = true;
    try {
      if (this.secret == "") {
        await UserModule.ChangePassword(user);
      } else {
        await UserModule.ResetPasswordFromSecret(user);
        this.isLoading = false;
        this.$router.push({
          name: "confirmPasswordSuccess",
          params: { email: APPMODE + "_" + this.email },
        });
        this.isDone = true;
        return;
      }
    } catch (ex) {
      this.error = ex;
      this.isLoading = false;
      return;
    }
  }
}
