









import { Component, Prop, Vue } from 'vue-property-decorator'
import { Environment } from '@/services/Environment'
import { LanguageModule } from '@shared/store/modules/LanguageModule'


@Component
export default class LanguageChooser extends Vue {

  public get languages():string[] {
    return LanguageModule.languages;
  }
  public LanguageStore = LanguageModule;

  public mounted () {


  }
}

