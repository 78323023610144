






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import TransactionNugget from '@shared/services/nuggets/TransactionNugget';
import { sumBy, filter } from "lodash";
import { ENVIRONMENT, SiteUrls } from '@/services/Config';
import * as ApiEnvironment from "@/services/Environment";
import {  APPMODE, AppModes } from '@/services/Config'
import { UserModule } from '../../store/modules/UserAuthModule';
const moment = require('moment');
@Component
export default class CompanySwitcher extends Vue {
  private GVTransactions : Array<Components.Schemas.Transaction> | null = null;
 private GOTransactions : Array<Components.Schemas.Transaction> | null = null;
 private PFTransactions : Array<Components.Schemas.Transaction> | null = null;
 private readonly MAXPERIOD = 24;
 private intervallTimer :any|undefined =   undefined;
  moment() {
    moment.locale('de')
    return moment;
  }

  public get pfenabled():boolean{
    return ApiEnvironment.Environment.PFANDLAEDCHEN_ENABLED;

  }

  public get goenabled():boolean{
    return ApiEnvironment.Environment.GOLENDING_ENABLED;

  }

  public TargetUrl(mode:AppModes){
    return window.location.href.replace(SiteUrls(APPMODE),SiteUrls(mode)).replace(SiteUrls(AppModes.None),SiteUrls(mode)).replace('/GV/','/GV/Enter/Token/'+UserModule.token+'/');
  }

    public CurrentStyleActive(mode:AppModes){
      return (mode == APPMODE);
    }

  public greenTransactions(transactions:  Array<Components.Schemas.Transaction>): number{
    if (transactions == null) return 0;
    return transactions.filter(m=> !(moment(m.createDate).add(this.MAXPERIOD-6, 'hour') <moment()) && !(moment(m.createDate).add(this.MAXPERIOD-2, 'hour') < moment())).length;
  }

  public yellowTransactions(transactions:  Array<Components.Schemas.Transaction>): number{
    if (transactions == null) return 0;
    return transactions.filter(m=>moment(m.createDate).add(this.MAXPERIOD-6, 'hour') < moment() && !(moment(m.createDate).add(this.MAXPERIOD-2, 'hour') < moment())).length;
  }

    public redTransactions(transactions:  Array<Components.Schemas.Transaction>): number{
    if (transactions == null) return 0;
    return transactions.filter(m=>moment(m.createDate).add(this.MAXPERIOD -2, 'hour') < moment()).length;
  }

  public async mounted () {
    this.Reload();
    this.intervallTimer =setInterval(x => {
      this.Reload();
    },60000*5);
  }
  public beforeDestroy () {
	clearInterval(this.intervallTimer);
}


  private async Reload() {
    this.GVTransactions=[...await TransactionNugget.GetTransactionsByState("new",ApiEnvironment.Environment.DIEGELDVERLEIHER_API_ENDPOINT),...await TransactionNugget.GetTransactionsByState("lenderstimeout",ApiEnvironment.Environment.DIEGELDVERLEIHER_API_ENDPOINT)];
    if (this.pfenabled){
    this.PFTransactions=[...await TransactionNugget.GetTransactionsByState("new",ApiEnvironment.Environment.PFANDLAECHEN_API_ENDPOINT),...await TransactionNugget.GetTransactionsByState("lenderstimeout",ApiEnvironment.Environment.PFANDLAECHEN_API_ENDPOINT)];
    }
    if (this.goenabled){
    this.GOTransactions=[...await TransactionNugget.GetTransactionsByState("new",ApiEnvironment.Environment.GOLENDING_API_ENDPOINT),...await TransactionNugget.GetTransactionsByState("lenderstimeout",ApiEnvironment.Environment.GOLENDING_API_ENDPOINT)];
    }
  }


}

