





















































































































































































































































































































































































































































































































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit,
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";

import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");
import { totalSum, totalInterest, totalFees } from "@shared/CalculateTotal";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import Headline from "@shared/components/General/Headline.vue";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import OfferMixin from "@shared/components/General/OfferMixin";
import PrivacyNugget from "@shared/services/nuggets/PrivacyNugget";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";

@Component({
  mixins: [ModalMixin, OfferMixin, TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    TransactionDisplayHeadline,
    BTextInputWithValidation,
    ValidationProvider,
    ValidationObserver,
    Headline,
  },
})
export default class OffersCheck extends Mixins(
  ModalMixin,
  TransactionListMixin,
  TransactionMixin,
  OfferMixin
) {
  private amount: number = this.transactionModel.summwishedfor;
  private interest: number = 0;
  private cost: number = 0;
  private isdeletingOffer: boolean = false;
  private iscreatingOffer: boolean = false;

  public offers: Array<any> = [];

  public get Cost() {
    return this.cost;
  }

  public set Cost(value: number) {
    this.cost = value;
  }

  public get Interest() {
    return this.interest;
  }

  public set Interest(value: number) {
    this.interest = value;
  }

  public get Amount() {
    return this.amount;
  }

  public set Amount(value: number) {
    this.amount = value;
  }

  public get AbsInterest(): number {
    // Zinsen auf Monatsbasis * Monate
    return totalInterest(
      Number(this.Amount),
      Number(this.Interest),
      Number(this.transactionModel.period)
    );
  }

  public get AbsFee(): number {
    // Zinsen auf Monatsbasis * Monate
    return totalFees(
      Number(this.Amount),
      Number(this.Cost),
      Number(this.transactionModel.period)
    );
  }

  public get TotalSum(): number {
    //Kreditbetrag + Gebühren * Monate + Zinsen auf Monatsbasis * Monate
    return (
      //Number(this.SumWishedFor)
      totalSum(
        this.Amount,
        this.interest,
        this.Cost,
        Number(this.transactionModel.period)
      )
    );
  }

  @Emit("transactionReleased")
  public Released(id: string) {}

  public async ReleaseTop5() {
    if (
      this.transactionModel.responses.filter((m) => m.offerstate != "deleted")
        .length < 5
    ) {
      this.error = "Es müssen mindestens 5 Angebote vorhanden sein.";
      return;
    }
    this.isLoading = true;
    try {
      await TransactionNugget.TransitionTransactionState(
        this.transactionModel,
        "offersapproved"
      );
      this.Released(this.transactionModel.id);
    } catch (e) {
      this.error = "Angebote konnten nicht freigegeben werden"!;
    }
    this.isLoading = false;
  }

  public async OnActivated() {
    this.offers = await this.OffersAsyncVM(this.transactionModel);
    TransactionModule.SET_TRANSACTION(this.transactionModel);
    TransactionModule.SET_TRANSACTIONMODE(
      this.transactionModel.transactionMode
    );
    TransactionModule.SET_RUNTIME(Number(this.transactionModel.period));
    TransactionModule.SET_SUM(this.transactionModel.summwishedfor);
  }

  public mounted() {
    if (this.transactionModel.transactionMode.toLowerCase() != "pawn") {
      this.interest = 0;
      this.cost = 0;
      this.fields = [
        {
          key: "pawnbrokername_pawnbrokeraddress",
          label: "",
          thClass: "th-pawnbroker",
          tdClass: "td-pawnbroker",
        },
        {
          key: "creditamount",
          label: "Kreditbetrag",
          thClass: "th-creditamount",
          tdClass: "td-creditamount",
        },
        {
          key: "creditsum",
          label: "Gesamtkosten",
          thClass: "th-creditsum",
          tdClass: "td-creditsum",
        },
        {
          key: "btn-action",
          label: "",
          thClass: "th-btn-action",
          tdClass: "td-btn-action",
        },
      ];
    }
  }

  public async DeleteOffer(id: string) {
    this.isdeletingOffer = true;
    try {
      this.transactionModel.responses = this.transactionModel.responses.filter(
        (x) => {
          return x.id != id;
        }
      );
      await TransactionNugget.DeleteBid(this.transactionModel.id, id);
    } catch (e) {
      this.error = "Angebot konnte nicht gelöscht werden"!;
    }
    this.isdeletingOffer = false;
  }

  public async CreateGVOffer() {
    const isinvalid = !(await (<any>this.$refs.observer).validate());
    if (isinvalid == false) {
      this.iscreatingOffer = true;
      try {
        const myModel = {
          Kreditbetrag: this.Amount.toString(),
          Zinsen: this.Interest.toString(),
          Gebühren: this.Cost.toString(),
        };
        const newval: Components.Schemas.Offer = { values: myModel };
        this.transactionModel.responses.push(newval);
        await TransactionNugget.CreateBid(
          this.transactionModel.id,
          this.Amount,
          this.Interest,
          this.Cost
        );
        this.transactionModel = await TransactionNugget.GetTransaction(
          this.transactionModel.id
        );
        this.offers = await this.OffersAsyncVM(this.transactionModel);
        this.Amount = this.transactionModel.summwishedfor;
        this.Interest = 0;
        this.Cost = 0;
        this.$forceUpdate();
      } catch (e) {
        console.log(e);
        this.error = "Angebot konnte nicht angelegt werden"!;
      }
      this.iscreatingOffer = false;
    }
  }

  public async OffersAsyncVM(transaction: Components.Schemas.Transaction) {
    if (transaction == undefined) return [];

    const filtered = transaction.responses.filter((x) => {
      return x.offerstate != "deleted" && x.userId != UserModule.UserEntityId;
    });
    const result = await Promise.all(
      filtered.map(async (x) => {
        const xprofile =
          x.privacytoken == null
            ? null
            : await PrivacyNugget.GetSafe(x?.privacytoken);
        return this.GetOfferVM(transaction, x, xprofile);
      })
    );
    return result
      .sort((a, b) => {
        return a.creditsum - b.creditsum;
      })
      .slice(0, 5);
  }

  public GVOffersVM(transaction: Components.Schemas.Transaction): Array<any> {
    if (transaction == undefined) return [];
    const filtered = transaction.responses.filter((x) => {
      return x.offerstate != "deleted" && x.userId == UserModule.UserEntityId;
    });
    return filtered.map((x) => this.GetOfferVM(transaction, x, null));
  }

  public fields: Array<any> = [
    {
      key: "pawnbrokername_pawnbrokeraddress",
      label: "",
      thClass: "th-pawnbroker",
      tdClass: "td-pawnbroker",
    },
    {
      key: "creditamount",
      label: "Kreditbetrag",
      thClass: "th-creditamount",
      tdClass: "td-creditamount",
    },
    {
      key: "creditinterest",
      label: "monatl. Zinsen",
      thClass: "th-creditinterest",
      tdClass: "td-creditinterest",
    },
    {
      key: "creditfees",
      label: "monatl. Gebühren",
      thClass: "th-creditfees",
      tdClass: "td-creditfees",
    },
    {
      key: "creditsum",
      label: "Gesamtkosten",
      thClass: "th-creditsum",
      tdClass: "td-creditsum",
    },
    {
      key: "btn-action",
      label: "",
      thClass: "th-btn-action",
      tdClass: "td-btn-action",
    },
  ];
  // ---------------------- ITEMS ------------------------
}
