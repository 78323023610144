











































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Environment } from '@/services/Environment'
import { UserModule } from '@shared/store/modules/UserAuthModule'


@Component
 class AGB extends Vue {


  public mounted () {


  }
}

// @ is an alias to /src

export default {
  name: 'agb',
  components: {
    AGB
  }
}
