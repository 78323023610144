









import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
 class Datenschutz extends Vue {


  public mounted () {


  }
}

// @ is an alias to /src

export default {
  name: 'datenschutz',
  components: {
    Datenschutz
  }
}
