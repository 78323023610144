





























































import {
  Component,
  Emit,
  Mixins,
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
@Component({ mixins: [ModalMixin] })
export default class Congratulations extends Mixins(ModalMixin) {
  mounted() {
    (<any>this.$refs.mymodal).show();
    sessionStorage.removeItem('accptedTransaction')
    console.log('removemarker')

  }
  @Emit("closeMe")
  public closed(): void {

  }

  closeme() {
    this.closed()
    this.CloseModal()
  }
}
