











































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import * as ApiEnvironment from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";

import OpenAPIClientAxios from "openapi-client-axios";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

import { ENVIRONMENT } from "@/services/Config";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";
import { filter, sumBy } from "lodash";
import PFCategories from "@shared/components/Pfandleiher/PFCategories.vue";
import CountryPostalCodes from "@shared/components/Pfandleiher/CountryPostalCodes.vue";
import moment from "moment";

@Component({ components: { CountryPostalCodes } })
export default class GVCategories extends PFCategories {
  public isLoading: boolean = false;
  public error: string | null = null;

  moment() {
    moment.locale("de");
    return moment;
  }

  public categories: Components.Schemas.Category[] | null = null;

  public myCategories: any | null = null;
  public otherCategories: Components.Schemas.Category[] | null = null;
  public Countries: Array<string> = [];
  public PLZ: Array<string> = [];
  public constructor() {
    super();
    this.ModeSelf = false;
  }
  public get CostTotal(): number {
    var sum = 0;
    if (this.MyCategories == null) return 0;
    sum += this.MyCategories.filter(m => m["checked"] == true).length * 40;
    if (this.OtherCategories == null) return sum;
    sum += this.OtherCategories!.filter(m => m["checked"] == true).length * 40;
    return sum;
  }

  public get MyCategories(): any[] | null {
    return this.AddChecked(this.myCategories ?? [], null);
  }
  public get OtherCategories(): Components.Schemas.Category[] | null {
    if (this.otherCategories == null && this.myCategories != null) {
      if (this.categories == null || this.myCategories == null) return [];
      var results = this.categories.filter(
        m => this.myCategories!.filter(i => i.id == m.id).length == 0
      );
      this.otherCategories = this.AddChecked(results, false);
    }
    if (this.otherCategories == null && this.myCategories == null) {
      return this.categories != null ? this.categories : [];
    }
    return this.otherCategories;
  }

  public AddChecked(
    list: Array<any>,
    defaultval: boolean | null = null
  ): Array<any> {
    return list.map(m => {
      const subscription = m["categorySubscription"];
      if (subscription?.canceledAt == null || subscription.canceledAt == undefined) {
        m["checked"] = defaultval ?? true;
      } else {
        m["checked"] = defaultval ?? false;
      }
      return m;
    });
  }

  public async CheckedChange(item) {
    item.checked = !item.checked;
    if (item.checked == false) {
      await TransactionNugget.RemoveMyCategory(
        item.id,
        this.userModel.entityId
      );
    } else {
      await TransactionNugget.SetMyCategory(item.id, this.userModel.entityId);
    }
    await TransactionModule.SearchMyCategories(this.userModel.entityId);
    this.myCategories = TransactionModule.MyCategories;
    this.Costs = this.CalcCostTotal();
    this.$forceUpdate();
  }

 async mounted(){

       await TransactionModule.SearchMyCategories(this.userModel.entityId);
    this.myCategories = TransactionModule.MyCategories;
 }
  public OrderCheckedChange(item) {
    this.$forceUpdate();
  }
  public async Order() {
    this.isLoading = true;
    var newcats = this.OtherCategories!.filter(m => m["checked"] == true);
    for (var i = 0; i < newcats.length; i++) {
      await TransactionNugget.SetMyCategory(
        newcats[i].id,
        this.userModel.entityId
      );
    }

    await TransactionModule.SearchMyCategories(this.userModel.entityId);
    this.myCategories = TransactionModule.MyCategories;
    this.otherCategories = null;
    this.isLoading = false;
  }
}
