import { render, staticRenderFns } from "./FeedbackList.vue?vue&type=template&id=60b175fc&scoped=true&"
import script from "./FeedbackList.vue?vue&type=script&lang=ts&"
export * from "./FeedbackList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b175fc",
  null
  
)

export default component.exports