





















































































































































































































import {
  Component,
  Prop,
  Vue,
  Mixins,
  Emit,
  Watch
} from "vue-property-decorator";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import { Client, Definitions } from "@shared/services/nuggets/PrivacySvc";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import PrivacyNugget, {
  CustomData
} from "@shared/services/nuggets/PrivacyNugget";
import { APPMODE, AppModes } from "@/services/Config";
import DocumentEdit from "@shared/components/General/AccountParts/DocumentEdit.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BSelectWithValidation from "@shared/components/Inputs/BSelectWithValidation.vue";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import { required, confirmed, email, regex } from "vee-validate/dist/rules";
import { Environment } from "@/services/Environment";
import PFCategories from "@shared/components/Pfandleiher/PFCategories.vue";
import CountryPostalCodes from "@shared/components/Pfandleiher/CountryPostalCodes.vue";

import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import SelfDeleteConfirmation from "@shared/components/General/AccountParts/SelfDeleteConfirmation.vue";
const moment = require("moment");

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    DocumentEdit,
    BTextInputWithValidation,
    BSelectWithValidation,
    PFCategories,SelfDeleteConfirmation,CountryPostalCodes
  }
})
export default class EditProfile extends Vue {
  @Prop()
  public user!: User;

  @Prop()
  public multipleSaves!: Boolean;

  @Prop({default:false})
  public DisablePassword!: Boolean;

  @Prop()
  public profileModel!: Definitions.PrivacyProfile;

  @Prop({default:false})
  public newuser!: Boolean;

  @Prop({
    default: {
      categories: [],
      lastVerifyDate: "",
      feedback: "",
      email: ""
    }
  })
  public customData!: CustomData;
  @Emit()
  public onSaved() {}
  private cprofile: Definitions.PrivacyProfile | null = null;

  public get password() {
    return this._password;
  }
  public set password(val: string) {
    this._password = val;
    this.user.password = val;
  }

  public get Email(): string | undefined {
    if (this.profileModel.email == undefined) return "";
    const mode = APPMODE;
    return this.profileModel.email.indexOf(mode + "_") >= 0
      ? this.profileModel.email.substr((mode + "_").length)
      : this.profileModel.email;
  }
  public set Email(val: string | undefined) {
    if ((this.profileModel?.email ?? "").indexOf(APPMODE + "_") >= 0) {
      this.profileModel.email = APPMODE + "_" + val;
    } else {
      this.profileModel.email = val;
    }
    this.profileModel.email = val;
  }

  private get DataDownloadURL():string{
    return Environment.API_ENDPOINT+"/userdata/"+encodeURIComponent(UserModule.UserEntityId)+"?cloudnuggetjwttoken="+encodeURIComponent(UserModule.Token);
  }


  public DataDownload(){
    window.open(this.DataDownloadURL, "_blank");
  }


  public get IsGvApp(): boolean {
    return APPMODE == AppModes.DieGeldverleiher;
  }
  private _password: string = "";

  public passwordrepeat: string = "";
  public passworderror: string = "";
  public saved: boolean = false;
  public savedCompany: boolean = false;
  public savedPassword: boolean = false;

  private countryoptions: Array<any> = Environment.CountryOptions;

  public get CompanyProfile() {
    if (!this.multipleSaves) return this.profileModel;
    if (
      this.cprofile == null ||
      (this.profileModel?.company != null && this.cprofile?.company == null)
    ) {
      this.cprofile = this.profileModel;
    }
    return this.cprofile;
  }

  moment() {
    moment.locale("de");
    return moment;
  }

  public get IsPawnBroker(): boolean {
    const claims = UserModule.Claims[0];
    return claims == "PB";
  }

  public async changePassword() {

    const isinvalid = !(await (<any>this.$refs.observer)?.validate()??(<any>this.$refs.observerpw)?.validate());
    if (isinvalid == false) {
         const changedUser: User = {
          firstName: "",
          id: "",
          entityId: "",
          lastName: "",
          userName: this.user.eMail,
          userPoolId: 11,
          eMail: this.user.eMail,
          language: "German",
          timeZone: "GMT +1",
          password: this.passwordrepeat,
          userRoles: ""
        };
        await UserModule.ChangePassword(changedUser);
        this.savedPassword = true;
    }
  }

  public async SaveContactDetails(
    saveprofile: Definitions.PrivacyProfile,
    company: boolean = false
  ) {
    if (company) {
      (<any>this.$refs.observercompany).validate();
      const isinvalid = !(await (<any>this.$refs.observercompany).validate());
      if (isinvalid == true) {
        return;
      }
      this.savedCompany = true;
    } else {
      (<any>this.$refs.observerbase).validate();
      const isinvalid = !(await (<any>this.$refs.observerbase).validate());
      if (isinvalid == true) {
        return;
      }
      this.saved = true;
    }
    if (saveprofile != this.profileModel) {
      saveprofile.firstname = this.profileModel.firstname;
      saveprofile.lastname = this.profileModel.lastname;
      saveprofile.phone = this.profileModel.phone;
      saveprofile.email = this.profileModel.email;
      saveprofile.postalcode = this.profileModel.postalcode;
      saveprofile.city = this.profileModel.city;
      saveprofile.address = this.CompanyProfile?.address ?? "";
    }
    saveprofile.taxnumber = this.CompanyProfile?.taxnumber ?? "";
    saveprofile.webpage = this.CompanyProfile?.webpage ?? "";
    saveprofile.company = this.CompanyProfile?.company ?? "";
    this.customData.email = this.profileModel.email ?? "";
    await PrivacyNugget.UpdateSafe(
      UserModule.UserEntityId,
      saveprofile
    );
    await TransactionNugget.UpdateCustomData(
      UserModule.UserEntityId,
      this.customData
    );

    this.onSaved();
  }
  public mounted() {}
}
