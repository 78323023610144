






























































































































import { Component, Prop, Vue,Emit } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { getModule } from "vuex-module-decorators";
import {
  TransactionModule,
  NewTransaction
} from "@shared/store/modules/TransactionModule";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

import { namespace } from "vuex-class";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, confirmed, email, numeric,min } from "vee-validate/dist/rules";
//const transactionModule = namespace('TransactionModule')
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import { UserModule } from '../../store/modules/UserAuthModule';
import TransactionNugget from '../../services/nuggets/TransactionNugget';
import { APPMODE, AppModes } from '../../../../src/services/Config';
import { EventBus } from '@/eventbus';
@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BTextInputWithValidation
  }
})
export default class CreateTransaction extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  private selectedCategory: string = "";
  private runtime: number = 0;
  private sum: number = 0;

  private transactionMode: string = 'Pawn';

  @Prop({default:''})
  private defaultTransactionMode!: string;
  //@transactionModule.State
  public Categories: Components.Schemas.Category[] = [];

  private options: any = {};

  get Selected(): string {
    return this.selectedCategory;
  }
  set Selected(value: string) {
    this.selectedCategory = value;
  }

  private GetCategorybyName(value: string): Components.Schemas.Category {
    return TransactionModule.Categories[
      TransactionModule.Categories.findIndex(x => x.id === value)
    ];
  }

  get SelectedCategory(): Components.Schemas.Category {
    return this.GetCategorybyName(this.Selected);
  }

  get Sum(): number {
    return this.sum;
  }

  set Sum(value: number) {
    this.sum = value;
  }

  set Runtime(value: number) {
    this.runtime = value;
  }

  get Runtime(): number {
    return this.runtime;
  }

  set TransactionMode(value: string) {
    this.transactionMode = value;
  }

  get TransactionMode(): string {
    return TransactionModule.TransactionMode;
  }
@Emit("TransactionCreated")
  public TransactionCreated(){

  }

  public async NewTransaction() {

     const isvalid = await (<any>this.$refs.observer).validate();

  //  const isvalid = (<any>this.$refs.observer)._data.flags.invalid;
    if (isvalid == true) {
    this.isLoading = true;
    const newTransaction = new NewTransaction();
    newTransaction.data = this.SelectedCategory;
    newTransaction.runtime = this.Runtime;
    newTransaction.sum = this.Sum;
    newTransaction.mode = this.TransactionMode;
    await TransactionModule.NewTransaction(newTransaction);
    this.TransactionCreated();

    }
  }
  public SelectTransactionMode(mode: string) {
    this.TransactionMode = mode;
    TransactionModule.SelectTransactionMode(mode);
  }

 Comparator(a, b) {
   if (a.name < b.name) return -1;
   if (a.name > b.name) return 1;
   return 0;
 }
  public async created() {

      if (this.defaultTransactionMode != ''){
      TransactionModule.SelectTransactionMode(this.defaultTransactionMode);
    }
    if (UserModule.ProvisionUserID != undefined && APPMODE == AppModes.Pfandlädchen){
    await TransactionNugget.UpdateUserStyle(UserModule.ProvisionUserID);
    }
    await TransactionModule.SearchCategories();
    this.Runtime = TransactionModule.Runtime;
    this.Sum = TransactionModule.Sum;

    this.TransactionMode = TransactionModule.TransactionMode;
    const category = TransactionModule.Category;
    this.SelectTransactionMode(this.transactionMode);
    if (category !== undefined) {
      this.Selected = category.id;
    }
    this.Categories = TransactionModule.Categories.sort(this.Comparator);

  }
  public async mounted(){
      EventBus.$emit('AppLoaded');
  }
}
