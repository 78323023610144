



























import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { Environment } from "@/services/Environment";
import PrivacyNugget from "@shared/services/nuggets/PrivacyNugget";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import DocumentDropzone from "@shared/components/General/AccountParts/DocumentDropzone.vue";
@Component({
  components: {
    vue2Dropzone,
    DocumentDropzone
  }
})
export default class DocumentEdit extends Vue {
  @Prop()
  public profile!: Definitions.PrivacyProfile;
  @Prop()
  public id!: string;

  @Prop()
  public ReadOnly!: boolean;

  public keyselected: string = "passport";

  public filetypes: Array<any> = [
    { value: "passport", text: "Reisepass" },
    { value: "drivinglicense", text: "Führerschein" },
    { value: "socialsecurity", text: "Personalausweis" }
  ];
}
