























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { APPMODE } from "@/services/Config";
import { Environment } from "@/services/Environment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";
import BSelectWithValidation from "@shared/components/Inputs/BSelectWithValidation.vue";
import CheckboxesWithValidation from "@shared/components/Inputs/CheckboxesWithValidation.vue";

import { UserModule } from "@shared/store/modules/UserAuthModule";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BTextInputWithValidation,
    BSelectWithValidation,
    CheckboxesWithValidation,
    MarketingMenuHeader,
  },
})
export default class Register extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  newpassword: string = "";
  repeatNewPassword: string = "";
  email: string = "";
  country: string | null = null;
  menueopen: boolean = true;
  agbchecked: Boolean = false;
  privacychecked: Boolean = false;
  private countryoptions: Array<any> = Environment.CountryOptions;

  public get NewPassword(): string {
    return this.newpassword;
  }

  public set NewPassword(value: string) {
    this.newpassword = value;
  }
  public get Email(): string {
    return this.email;
  }

  public set Email(value: string) {
    this.email = value;
  }
  public mounted() {
    // this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash);
  }

  public async Register() {
    //@ts-ignore
    const isvalid = !(await (<any>this.$refs.observer).validate());
    if (isvalid == false) {
      this.isLoading = true;
      try {
        //  console.log("test"+ this.email +"|"+ this.NewPassword);
        const user: User = {
          userName: APPMODE + "_" + this.email,
          password: this.NewPassword,
          firstName: "",
          lastName: "",
          userPoolId: -1,
          language: "de",
          eMail: APPMODE + "_" + this.email,
          timeZone: "",
          id: "",
          entityId: "",
          userRoles: "",
        };
        try {
          await UserModule.Register(user);
        } catch (ex) {
          this.error = ex;
          this.isLoading = false;
          return;
        }
        await UserModule.Login(user);
        await TransactionModule.TakeOverCurrentTransaction();
        var profile: Definitions.PrivacyProfile = UserModule.Profile;
        profile.country = this?.country ?? "";
        profile.email = this.email;
        await PrivacyNugget.UpdateSafe(UserModule.UserEntityId ?? "", profile);

        switch (UserModule.claims[0]) {
          case "CUST": {
            this.$router.push("/Customer");
            break;
          }
          case "PB": {
            this.$router.push("/pf");
            break;
          }
          case "GV": {
            this.$router.push("/GV");
            break;
          }
        }
      } catch (e) {
        this.error = e;
        this.isLoading = false;
      }
    }
  }
}
