
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  TransactionModule,
  NewTransaction
} from "@shared/store/modules/TransactionModule";
const VueFormGenerator = require("../vue-form-generator/vfgindex")
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import { filter } from 'lodash';
import TransactionNugget from '@shared/services/nuggets/TransactionNugget';
import { UserModule } from '@shared/store/modules/UserAuthModule';
import { Environment } from '@/services/Environment';
const moment = require('moment');
var VueScrollTo = require('vue-scrollto');



@Component
export default class TransactionMixin extends Vue {

  public model: object | null = null;

  public schema: any | null = null;
  private options: any = {};

  private pagenr = 1;
  private Errors: string = "";
  private validated: boolean = false;





  moment() {
    moment.locale('de')
    return moment;
  }

  public get Validated(): boolean {
    return this.validated;
  }
  public set Validated(value: boolean) {
    this.validated = value;
  }
  public PhotoSwipeVM(pictures): Array<any> {
    const me = this;
    if (pictures == undefined || pictures == null) return [];
    var result = pictures.map(m => {
      return  {
        src: me.ImageBaseUrl.replace('$filename', m),
        thumbnail: me.ImageBaseUrl.replace('$filename', m),
        w: 600,
        h: 400,
        alt: m // optional alt attribute for thumbnail image
    };
    });
    return result;
  }

  get ImageBaseUrl(): string {
    if (TransactionModule.CurrentTransaction != undefined) {
      return Environment.API_ENDPOINT + '/file?guid=' + TransactionModule.CurrentTransaction.id + '&Language=de&filename=$filename&CloudnuggetJWTToken=' + UserModule.Token;
    }
    return "";
  }

  get Pagenr(): number { return this.pagenr; }

  set Pagenr(value: number) { this.pagenr = value; }

  get Schema(): any {
    if (this.schema == null) {
      const transaction = TransactionModule.CurrentTransaction;
      if (transaction != undefined) {
        this.schema = {
          fields: this.CurrentRequestFields(transaction)
        };
      }
    }

    return this.schema == null
      ? {
        fields: []
      }
      : this.schema;
  }

  get Model(): object {
    if (this.model == null) {
      const transaction = TransactionModule.CurrentTransaction;
      if (transaction != undefined) {
        this.model = this.CurrentRequestModel(transaction);
        return this.model;
      }
      const category = TransactionModule.Category;
      if (category != undefined) {
        this.model = VueFormGenerator.schema.createDefaultObject(this.Schema, {
          id: 123,
          created: new Date().valueOf()
        });
      }
    }

    return this.model == null ? {} : this.model;
  }

  public formOptions: object = {
    validateAfterLoad: false,
    validateAfterChanged: false,
  };

  //#region Properties


  get Category(): Components.Schemas.Category | undefined {

    return TransactionModule?.Category ?? undefined;
  }


  get CategoryName(): string {
    if (TransactionModule.Category != undefined) {
      return TransactionModule.Category.name;
    } else {
      return "No Category";
    }
  }

  get Sum(): number {
    return TransactionModule.Sum;
  }

  set Sum(value: number) { }

  set Runtime(value: number) { }

  get Runtime(): number {
    return TransactionModule.Runtime;
  }
  private enableLabels: boolean = true;

  get EnableLabels(): boolean {
    return this.enableLabels;
  }

  set EnableLabels(value: boolean) {
    this.enableLabels = value;
  }

  set TransactionMode(value: string) { }

  get TransactionMode(): string {
    return TransactionModule.TransactionMode;
  }


  private GetRequired(v: any): boolean {
    var result: boolean = false;
    if (v.validators != null) {
      v.validators.forEach(element => {
        result = result || element.type == 'required';
      });
    }
    return result;
  }


  private GetValidators(v: any, field: any): any {
    var result: Array<any> = [];
    let customsyncValidator = function (value, field, model) {

      if (value < v.max) {
        return ["Invalid value"];
      } else {
        return []
      }
    };

    let customAsyncValidator = function (value) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (value)
            resolve(undefined);
          else
            resolve(["Invalid value from async validator"]);
        }, 1000);
      });
    };
    v.validators.forEach(x => {
      if (x.type == 'min') {
        field['min'] = x.min;

      }
      if (x.type == 'max') {
        field['max'] = x.max;


      }
      if (x.type == 'textlength') {
        field['min'] = x.min;

      }
    });
    result.push("required");
    if (v.datatype == 'string') result.push("string");
    //   if (v.datatype == 'bool') result.push(function (value, field, model): object { return []; });
    //  if (v.datatype == 'int') result.push("integer");
    //    if (v.datatype == 'double') result.push("double");
    //  if (v.datatype == 'datetime') return result.push("datetime");
    //  if (v.datatype == 'list') result.push("string");
    //  if (v.datatype == 'upload') result.push("string");
    return result;
  }

  private CurrentRequestFields(transaction: Components.Schemas.Transaction): object[] {
    const current: Components.Schemas.Category = transaction.category;
    var myobj = this;
    var myImageBaseUrl = this.ImageBaseUrl.toString();
    var useLabels = this.EnableLabels;
    var requestfields = current.requestFields.filter(m=>m.transactionMode.toLowerCase() == transaction.transactionMode.toLowerCase() || m.transactionMode == 'any').map(function (v) {
      const options: any = v.fieldOptions.length > 0 ? JSON.parse(v.fieldOptions) : {};
      if (v.datatype == 'string' && options.controlType == 'textArea') {
        var field: any = {
          type: options.rows != undefined && options.rows > 0 ?"btextArea":"binput",
          rows: options.rows,
          inputType: v.datatype,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          append: v.unit,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          readonly: false,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      } else if (v.datatype == 'bool') {
        var field: any = {
          type: "bradios",
          values: [{ name: 'Ja', value: 'true' },{ name: 'Nein', value:"false" }],
          default: 'false',
          styleClasses:'vfgradio',
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          readonly: false,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
          /*    values:["Ja","Nein"],
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal == "Ja" ? true:false;
          } */
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      }
      else if (v.datatype == 'oldbool') {
        var field: any = {
          type: "bcheckbox",
          default: options.default,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          readonly: false,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
          /*    values:["Ja","Nein"],
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal == "Ja" ? true:false;
          } */
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      }
      else if (v.datatype == 'upload') {
        var field: any = {
          type: "bdropzone",
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          postUrl: Environment.API_ENDPOINT + '/file?guid=' + transaction.id + '&Language=de',
          getPreviewUrl: myImageBaseUrl,
          getFileUrl: myImageBaseUrl,
          getFiles: async function () { return await TransactionNugget.GetFiles(transaction, v.keyname) },
          RemoveFile: async function (file) { return await TransactionNugget.RemoveFile(transaction, file) },
          headers: { "CloudnuggetJWTToken": UserModule.Token },
          readonly: false,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      } else if (v.datatype == 'int') {
        var field: any = {
          type: "binput",
          inputType: v.datatype == "int"? "number": v.datatype,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,     append: v.unit,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          readonly: false,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      } else if (v.datatype == 'datetime') {
        var field: any = {
          type: "bdatetimepicker",
          inputType: v.datatype,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          readonly: false,
          min: moment("1900-01-01").toDate(),
          max: moment("2099-01-01").toDate(),
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          dateTimePickerOptions: {
            format: "D.M.YYYY"
          },
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {

          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      } else if (v.datatype == 'list') {
        var field: any = {
          type: "bselect",
          values: options.values,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          readonly: false,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,

          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      } else if (v.datatype == 'descriptiveList') {
        var field: any = {
          type: "bradiosdescriptive",
          values: options,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          readonly: false,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,

          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      }
      else {
        var field: any = {
          type: "binput",
          inputType: v.datatype,
          label: useLabels ? v.name : "",
          hint: v.description,
          model: v.keyname,
          pagenr: v.pageNr,
          sortOrder: v.sortOrder,
          append: v.unit,
          readonly: false,
          featured: false,
          required: myobj.GetRequired(v),
          disabled: false,
          placeholder: v.placeholdertext,
          onChanged(model, newVal, oldVal, field) {
            model[v.keyname] = newVal;
          }
        };
        field["validator"] = myobj.GetValidators(v, field);
        return field;
      }
    });
    return requestfields;
  }

  private CurrentRequestModel(transaction: Components.Schemas.Transaction): object {
    const current = transaction.category;
    var vmodel = {};
    current.requestFields.map(function (v) {
      const data = v.value;
      if (v.datatype == 'datetime') {
        vmodel[v.keyname] = data != '' ? data : 1496216976762;
      } else {
        vmodel[v.keyname] = data;
      }
      return v.keyname;
    });
    Object.keys(transaction.requestValues).map(function (key) {
      const v = transaction.requestValues[key];
      const schema = transaction.category.requestFields.filter(m => m.keyname.toLowerCase() == key.toLowerCase())[0];
      const data = schema.datatype == 'upload'? (v != "" && v != undefined) ? JSON.parse(v) : "":v;
      vmodel[key] = data;
    });
    return vmodel;
  }

  private BuildValuesList(myModel: any, transaction: Components.Schemas.Transaction): { [name: string]: string } {
    var _properties: { [name: string]: string } = transaction.requestValues;
    Object.keys(myModel).map(function (key, index) {
      const schema = transaction.category.requestFields.filter(m => m.keyname.toLowerCase() == key.toLowerCase())[0];
      if (schema != undefined) {
        _properties[key] = schema?.datatype == 'upload' ? JSON.stringify(myModel[key]) : myModel[key];
      }

    });

    return _properties;
  }

  public SaveModel(routing: boolean = true):Boolean {
    /* tslint:disable */

    var options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      onStart: function(element) {
        // scrolling started
      },
      onDone: function(element) {
        // scrolling is done
      },
      onCancel: function() {
        // scrolling has been interrupted
      },
      x: false,
      y: true
    }

    this.Validated = (<any>(this.$refs.vfg)).validate();
    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query
      // the DOM. ¯\_(ツ)_/¯

      var err = document.querySelector(".error");
      if (err != null) {
        var element = [0];
        var cancelScroll = VueScrollTo.scrollTo(element, 150, options);
      }
    });
    this.Errors = (<any>(this.$refs.vfg)).errors.map(function (v) { return v.field.hint; }).join("<br/>");
    //this.$refs[`${firstField}Input`].scrollIntoView();
    /* tslint:enable */

    const transaction = TransactionModule.CurrentTransaction;
    if (transaction != undefined) {
      transaction.summwishedfor = Number(this.Sum);
      transaction.period = this.Runtime.toString();
      transaction.transactionMode = TransactionModule.TransactionMode.toLowerCase();
      transaction.requestValues = this.BuildValuesList(this.Model, transaction)
      TransactionModule.SET_TRANSACTION(transaction);

    }
    if (this.Validated && routing) {
      const target = "Request" + (this.pagenr + 2);
      this.$router.push(target);
    }
    return this.validated;
  }
  public async Save(routing: boolean = true, changeState: string = 'added') {
    this.SaveModel(routing);
    var transaction = TransactionModule.CurrentTransaction;
    if (transaction != undefined) {
      transaction.state = changeState;
      transaction.provisionUserId = UserModule.ProvisionUserID;
      await TransactionNugget.UpdateTransaction(transaction);
    }

  }

}
