export function totalInterest(
  loanAmount: number,
  interest: number,
  period: number
): number {
  return loanAmount * (interest / 100) * period;
}

export function totalSum(
  loanAmount: number,
  interest: number,
  fees: number,
  period: number
): number {
  return (
    totalInterest(loanAmount, interest, period) +
    totalFees(loanAmount, fees, period) +
    loanAmount
  );
}

export function totalFees(
  loanAmount: number,
  fees: number,
  period: number
): number {
  return loanAmount * (fees / 100) * period;
}
