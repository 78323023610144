












































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import * as ApiEnvironment from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";

import OpenAPIClientAxios from "openapi-client-axios";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

import { ENVIRONMENT } from "@/services/Config";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";

const moment = require("moment");
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import PfOfferBid from "./Parts/PFOfferBid.vue";
import TransactionFilter from "@shared/components/General/TransactionFilter.vue";
import DateDisplay from "@shared/components/General/DateDisplay.vue";

@Component({
  mixins: [TransactionMixin, TransactionListMixin],
  components: {
    PfOfferBid,
    TransactionFilter,
    DateDisplay,
  },
})
export default class PFHistory extends TransactionListMixin {
  public isLoading: boolean = false;
  public error: string | null = null;
  public transactions: Components.Schemas.Transaction[] = [];

  public get Transactions() {
    return this.transactions;
  }

  public set Transactions(value: Components.Schemas.Transaction[]) {
    this.transactions = value;
  }

  public async created() {
    if (!UserModule.Authenticated) {
      await UserModule.AnonymousLogin();
    }
    await this.Reload();
  }

  public async OnChanged(id: string) {
    await this.Reload();
  }

  public GetOffer(
    value: Components.Schemas.Transaction
  ): Components.Schemas.Offer {
    const offers = value.responses;
    return offers[0];
  }

  public async Reload() {
    this.Transactions = [
      ...(await TransactionNugget.GetTransactionsByState("closedok")),
      ...(await TransactionNugget.GetTransactionsByState("closednok")),
    ].filter((m) => m.responses.length > 0);
  }

  public get TransactionFields(): Array<any> {
    return [
      {
        key: "id",
        label: "Nummer",
        thClass: "th-number",
        tdClass: "td-number",
      },
      {
        key: "createDate",
        label: "Datum",
        thClass: "th-datetime",
        tdClass: "td-datetime",
        sortable: true,
      },
      {
        key: "country",
        label: "Land",
        thClass: "th-country",
        tdClass: "td-country",
        sortable: true,
      },
      {
        key: "inquirytype",
        label: "Art",
        thClass: "th-typeinquirynr",
        tdClass: "td-typeinquirynr",
        sortable: true,
      },
      {
        key: "category",
        label: "Kategorie",
        thClass: "th-category",
        tdClass: "td-category",
        sortable: true,
      },
      {
        key: "media",
        label: "Foto",
        thClass: "th-image",
        tdClass: "td-image",
      },
      {
        key: "title",
        label: "Titel",
        thClass: "th-title",
        tdClass: "td-title",
      },
      {
        key: "runtime",
        label: "Laufzeit",
        thClass: "th-term",
        tdClass: "td-term",
        sortable: true,
      },
      {
        key: "sum",
        label: "Betrag",
        thClass: "th-amount",
        tdClass: "td-amount",
        sortable: true,
      },
      {
        key: "fees",
        label: "Gebühren",
        thClass: "th-fees",
        tdClass: "td-fees",
        sortable: true,
      },
      {
        key: "interest",
        label: "Zinsen",
        thClass: "th-interest",
        tdClass: "td-interest",
        sortable: true,
      },
      {
        key: "btn-check",
        label: "",
        thClass: "th-btn-check",
        tdClass: "td-btn-check",
        sortable: true,
      },
    ];
  }

  // ---------------------- ITEMS ------------------------
}
