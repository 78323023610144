import { render, staticRenderFns } from "./UserDeleteConfirmation.vue?vue&type=template&id=32f8886c&scoped=true&"
import script from "./UserDeleteConfirmation.vue?vue&type=script&lang=ts&"
export * from "./UserDeleteConfirmation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f8886c",
  null
  
)

export default component.exports