



























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";

import { UserModule } from "@shared/store/modules/UserAuthModule";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";

import CustomerOfferAccepted from "./Parts/CustomerOfferAccepted.vue";
import CustomerInquiryCorrection from "./Parts/CustomerInquiryCorrection.vue";

@Component({
  components: {
    InquiryDeleteConfirmation,
    CustomerOfferAccepted,
    CustomerInquiryCorrection
  }
})
export default class CustomerMaster extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  public get IsAuthenticated(): boolean {
    return UserModule.Authenticated || UserModule.IsAnonymous;
  }

  public get Username(): string {
    return UserModule.Email;
  }

 public get Company(): string {
    return UserModule.Profile.company??"";
  }

  public isMobile: boolean = false;
  public get GetStyle(): boolean {
    return this.isMobile ? true : false;
  }
  public mounted() {}
}
