












































































































































































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");

import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import ViewProfile from "@shared/components/General/AccountParts/ViewProfile.vue";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { APPMODE,AppModes } from '@/services/Config';
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";
import Headline from "@shared/components/General/Headline.vue";

import PrivacyNugget, { CustomData } from "@shared/services/nuggets/PrivacyNugget";
@Component({
  mixins: [ModalMixin, TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    Headline,
    TransactionDisplayHeadline,
    ViewProfile
  }
})
export default class InquiryCheckSplitView extends Mixins(
  ModalMixin,
  TransactionListMixin,
  TransactionMixin
) {
  public Problems: Array<string> = [];

  public ProblemText: string = "";

  public IsUserConfirmed(customData: any) {
    return PrivacyNugget.IsUserConfirmed(customData);
  }

  public profile: Definitions.PrivacyProfile = {
    email: "",
    phone: "",
    firstname: "",
    lastname: "",
    address: "",
    postalcode: "",
    city: "",
    country: "",
    company: "",
    taxnumber: "",
    webpage: ""
  };
  public customData: CustomData = {
    categories: [],
    lastVerifyDate: "",
    feedback: "",
    email: "",
    postalcodes: [],
    countries: []
  };
  public userModel: User | null = {
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    userPoolId: -1,
    language: "de",
    eMail: "",
    timeZone: "",
    id: "",
    entityId: "",
    userRoles: ""
  };


  public get Profile() {
    return this.profile;
  }
  public GetMessage(msg: Components.Schemas.Lifecycle) {
    if (msg.type == "Feedback") {
      return JSON.parse(msg.message).problemText;
    } else {
      return "Status geändert auf " + this.$t('state_'+msg.newState);
    }
  }

  public async SendFeedback() {
    await TransactionNugget.UpdateCustomData(
      this.profile?.entityId ?? "",
      this.customData
    );
    this.CloseModal();
  }

  public async ReleaseUser() {
    if (this.customData != null) {
      this.customData.lastVerifyDate = moment.utc().toISOString();
      console.log('setdate');
    }
    await TransactionNugget.UpdateCustomData(
      this.profile?.entityId ?? "",
      this.customData
    );
    this.CloseModal();
  }

  public async OnActivated() {
    console.log('activ');
    if (this.transactionModel.privacytoken != null) {

      this.profile = await PrivacyNugget.GetSafe(
        this.transactionModel.privacytoken.toString()
      );

      var result = await TransactionNugget.GetCustomData(
        this.transactionModel.privacytoken
      );

      if (result?.feedback == undefined) {
      } else {
       this.customData = result != undefined ? result: <any>{};
     }
    }else{
      console.log('no token')
    }
    this.$forceUpdate();
  }

  public get RueckfrageText(): string {
    if (this.transactionModel.lifecycle != null) {
      if (this.ProblemText != "") {
        return this.ProblemText;
      }
      const messages = this.transactionModel.lifecycle.sort((a, b) =>
        moment(a).diff(moment(b))
      ); //.filter(m=>{ return m.type == 'Feedback' });
      try {
        return messages.length > 0
          ? JSON.parse(messages[messages.length - 1].message).problemText
          : "";
      } catch {
        return this.ProblemText;
      }
    } else {
      return this.ProblemText;
    }
  }

  public set RueckfrageText(value: string) {
    this.ProblemText = value;
  }

  public OnDeletedRecieved(id: string) {
    this.OnDeleted(id);
    this.CloseModal();
  }

  @Emit("transactionDeleted")
  public async OnDeleted(id: string) {
    await TransactionNugget.SendMessage(
      this.transactionModel.id,
      this.Problems,
      "Deine Anfrage wurde abgelehnt!"
    );
    this.CloseModal();
  }
  @Emit("transactionChanged")
  public OnChanged(id: string) {}
  @Emit("transactionReleased")
  public Released(id: string) {}

  public PagerLeftClicked() {}

  public PagerRightClicked() {}

  public async Release(id: string) {
    await TransactionNugget.TransitionTransactionStateById(
      this.transaction,
      "approved"
    );
    this.Released(id);
    this.CloseModal();
  }

  public async Rueckfragen() {
    await TransactionNugget.SendMessage(
      this.transactionModel.id,
      this.Problems,
      this.ProblemText
    );
    await TransactionNugget.TransitionTransactionStateById(
      this.transaction,
      "feedback"
    );
    this.OnChanged(this.transactionModel.id);
  }
}
