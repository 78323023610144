import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router';
import {
  TransactionModule,
  NewTransaction
} from "@shared/store/modules/TransactionModule";
const VueFormGenerator = require("@shared/components/vue-form-generator/vfgindex")
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
const moment = require('moment');
import TransactionNugget from '@shared/services/nuggets/TransactionNugget';
import { Environment } from '@/services/Environment';

@Component
export default class ModalMixin extends Vue {
  @Prop(String) transaction!: string;
  @Prop() transactionModel!: Components.Schemas.Transaction;
  @Prop() transactions!: Components.Schemas.Transaction[];
  @Prop(String) buttonname!: string;
  @Prop(String) operationid!: string;
  @Prop(String) size!: string;

  
  @Emit("opened")
  public Opened() {

  }

  protected error: string = "";

  public created() {
    if (this.operationid == undefined || this.operationid == null || this.operationid == '') return;
      if (this.$route.query["transactionid"] == this.transaction && this.$route.query["operation"] == this.operationid) {
      this.ShowModal();
    }
  }

  public activated: boolean = false;
  moment() {
    moment.locale('de')
    return moment;
  }

  public ProblemText: string = "";
  public Problems: Array<string> = [];
  public isLoading: boolean = false;
  public get RueckfrageText(): string {
    if (this.transactionModel != null && this.transactionModel.lifecycle != null) {
      if (this.ProblemText != "") {
        return this.ProblemText;
      }
        const messages = this.transactionModel.lifecycle.filter(x=>x.type=='Feedback').sort((a, b) =>
        moment(a).diff(moment(b))
      ); //.filter(m=>{ return m.type == 'Feedback' });
      try {
        return messages.length > 0
          ? JSON.parse(messages[messages.length - 1].message).problemText
          : "";
      } catch {
        return this.ProblemText;
      }
    } else {
      return this.ProblemText;
    }
  }

  public set RueckfrageText(value: string) {
    this.ProblemText = value;
  }



  private modelname: string = "";

  public get Modelname(): string {
    return this.operationid + this.transaction;
  }

  public get ButtonName(): string {
    return this.buttonname;
  }
  public async ShowModal() {
    this.isLoading = true;
    this.activated = true;
    if ((<any>this).OnActivated != undefined) {
      await (<any>this).OnActivated();
    }
    (<any>this.$refs.mymodal).show();
    this.Opened();
    this.isLoading = false;
  }
  @Emit("closeModal")
  public CloseModal(): void {
    (<any>this.$refs.mymodal).hide();
  }


}
