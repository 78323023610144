


































































import { Component, Prop, Vue,Model,Mixins,Emit } from 'vue-property-decorator'
import ModalMixin  from '@shared/components/General/ModalMixin';
import TransactionNugget from '@shared/services/nuggets/TransactionNugget';
import { ManagementNugget, User } from '../../../services/nuggets/ManagementNugget';
import { UserModule } from '../../../store/modules/UserAuthModule';
const moment = require("moment");

@Component({ mixins: [ModalMixin] })
export default class UserDeleteConfirmation extends Mixins(ModalMixin) {

@Prop()
public user! :User;//Wenn Sie die Anfrage ablehnen wird der Kunde davon informiert, und die Anfrage zur weiteren Bearbeitung gesperrt.";


@Prop()
public text! :string;//Wenn Sie die Anfrage ablehnen wird der Kunde davon informiert, und die Anfrage zur weiteren Bearbeitung gesperrt.";

@Prop()
public subtext! :string;

@Prop()
public deleteButton! :string;

@Prop({default:false})
public nolock! :Boolean;


public isLoading :boolean =false;
 public async OnActivated() {
  if (this.deleteButton == null) this.deleteButton= "Benutzer löschen";
  if (this.subtext == null) this.subtext= "";
  if (this.text == null) this.text= "Wenn Sie den Benutzer löschen, werden dessen persönliche Daten aus dem System entfernt und die Emailadresse für die Zukunft gesperrt.";

 }

  @Emit('userDeleted')
  TransactionDeleted(id:string) {

  }

  public async DeleteTransaction(){
    this.isLoading = true;
    if (!this.nolock){
      this.user.lockedDate = moment().utc().toISOString();
      await ManagementNugget.UpdateUser(this.user);
    }else{
      await ManagementNugget.DeleteUser(this.user);
    }
      this.TransactionDeleted(this.transaction);
      this.CloseModal();
      this.isLoading = false;
  }
}
