
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import OpenAPIClientAxios from 'openapi-client-axios'
import { Client, Components } from '@shared/services/nuggets/TransactionSvc'
import Store from '../index'
import { AxiosResponse } from 'openapi-client-axios'
import { UserModule } from './UserAuthModule'
import * as ApiEnvironment from "@/services/Environment";
import TransactionNugget from '@shared/services/nuggets/TransactionNugget'

export interface ITransactionState {
  Categories: Components.Schemas.Category[]
}
export class NewTransaction{
  public data?: Components.Schemas.Category;
  public runtime: number = 0;
  public sum: number = 0;
  public mode: string = '';
}

@Module({
  dynamic: true,
  store: Store,
  name: 'transaction',
  namespaced: true,
})
export class TransactionStoreModule extends VuexModule implements ITransactionState {

  private categories: Components.Schemas.Category[] = [];
  private mycategories: Components.Schemas.Category[] = [];

  private currentcategory? : Components.Schemas.Category = undefined;

  private currentsum: number = 1000;

  private currentruntime: number = 3;

  private currentTransactionMode: string = 'pawn';
  private currentTransaction?: Components.Schemas.Transaction = undefined;
  public get historyForUser (): string {
    return ''
  }



  get Category():Components.Schemas.Category | undefined{
    return this.currentcategory;
  }

  get Sum():number{
    return this.currentsum;
  }

  get Runtime():number{
    return this.currentruntime;
  }

  get TransactionMode(): string {
    return this.currentTransactionMode;
  }

  get Categories(): Components.Schemas.Category[] {
    return this.categories;
  }

  get MyCategories(): Components.Schemas.Category[] {
    return this.mycategories;
  }

    @Mutation
  public SET_CATEGORIES (data: Components.Schemas.Category[]) {
        this.categories = data;
   }


  @Mutation
  public SET_MYCATEGORIES (data: Components.Schemas.Category[]) {
        this.mycategories = data;
  }

  @Mutation
  public SELECT_CATEGORY ( data? : Components.Schemas.Category) {

        this.currentcategory = data;
  }

  @Mutation
  public SET_RUNTIME ( value :number) {

        this.currentruntime = value;
  }
  @Mutation
  public SET_SUM ( value :number) {

        this.currentsum = value;
  }
  @Mutation
  public SET_TRANSACTIONMODE ( value : string) {

        this.currentTransactionMode = value;
  }
  @Mutation
  public SET_TRANSACTION( value : Components.Schemas.Transaction | undefined) {

    this.currentTransaction = value;
  }

  public get CurrentTransaction(): Components.Schemas.Transaction | undefined {
    return this.currentTransaction;
  }

  @Action({ rawError: true })
  public async SelectCategory ( data : Components.Schemas.Category) {
    this.SELECT_CATEGORY(data);
  }

  @Action({ rawError: true })
  public async SelectTransactionMode ( mode:string) {
    this.SET_TRANSACTIONMODE(mode);
  }

  @Action({ rawError: true })
  public async NewTransaction(transaction: NewTransaction) {
    this.SELECT_CATEGORY(transaction.data);
    this.SET_RUNTIME(transaction.runtime);
    this.SET_SUM(transaction.sum);
    this.SET_TRANSACTIONMODE(transaction.mode);

    if (this.Category?.id != undefined) {
      var result = (await TransactionNugget.CreateTransaction({ state: 'added', CategoryId: this.Category?.id, language: 'de' }));//[{name:'language',value:'de',in: 'query' }]
      const created = result;
      created.summwishedfor = Number(this.Sum);
      created.period = this.Runtime.toString();
      created.transactionMode = TransactionModule.TransactionMode.toLowerCase();
      this.SET_TRANSACTION(created);// ({ organization: organization || "1", sort: "name"});
    }


  }
  @Action({ rawError: true })
  public Clear() {
    this.SET_TRANSACTION(undefined);
  }

  @Action({ rawError: true })
  public async TakeOverCurrentTransaction() {
    if (TransactionModule.CurrentTransaction != undefined) {
      var transaction = this.CurrentTransaction;
      if (transaction?.country == null || transaction?.country == undefined) {
        transaction!.country = UserModule.Profile.country;
        await TransactionNugget.UpdateTransaction(transaction!);
      }
      TransactionModule.Clear();
       if (transaction!.state == 'added') {
        await TransactionNugget.TransitionTransactionState(
          transaction!,
          "New"
        );
      }
   }

  }

    @Action({ rawError: true })
    public async SearchCategories() {
      if (this.Categories.length > 0) return;
      const result = await TransactionNugget.LoadCategories()//[{name:'language',value:'de',in: 'query' }]
      this.SET_CATEGORIES(result);// ({ organization: organization || "1", sort: "name"});
    }



    @Action({ rawError: true })
    public async SearchMyCategories( entityId:string = "") {
      const result = await TransactionNugget.LoadMyCategories(entityId)//[{name:'language',value:'de',in: 'query' }]
      this.SET_MYCATEGORIES(result);// ({ organization: organization || "1", sort: "name"});
    }
}
export const TransactionModule = getModule(TransactionStoreModule)
