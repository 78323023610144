import { Component, Prop, Vue } from 'vue-property-decorator'
const moment = require('moment');

import { UserModule } from "@shared/store/modules/UserAuthModule";
import {
  User,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";

import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import PrivacyNugget, {
  CustomData
} from "@shared/services/nuggets/PrivacyNugget";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";

@Component
export default class UserDialogMixin extends Vue {

  public isLoading: boolean = false;
  public error: string | null = null;

  @Prop({default : false})
  public newuser!: boolean;

  @Prop()
  public user!: string;
  @Prop()
  public userid!: string;
  moment() {
    moment.locale("de");
    return moment;
  }
  public profile: Definitions.PrivacyProfile = {
    "email": "",
    "phone": "",
    "firstname": "",
    "lastname": "",
    "address": "",
    "postalcode": "",
    "city": "",
    "country": "",
    "company": "",
    "taxnumber": "",
    "webpage": ""
  };
  public customData: CustomData = {
    categories: [],
    lastVerifyDate: "",
    feedback: "",
    email: "",
    postalcodes: [],
    countries: []

  };
  public userModel: User | null = {
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    userPoolId: -1,
    language: "de",
    eMail: "",
    timeZone: "",
    id: "",
    entityId: "",
    userRoles:""
  };

  public get Profile() {
    return this.profile;
  }
  public get IsAuthenticated(): boolean {
    return UserModule.Authenticated;
  }

  public get Username(): string {
    return UserModule.Email;
  }
  public get Company(): string {
    return "Golending";
  }

  public async SaveContactDetails(saveprofile: Definitions.PrivacyProfile) {
    if (saveprofile?.email != null) {
      await PrivacyNugget.UpdateSafe(this.userModel!.entityId, saveprofile);
      await TransactionNugget.UpdateCustomData(this.userModel!.entityId, this.customData);
    }
  }

  public async mounted() {
    if (!this.newuser) {
      if (Number(this.userid) >= 0) {
        this.userModel = await ManagementNugget.GetUser(this.userid);
      } else {
        this.userModel = UserModule.CNUser;
      }
      console.log(this.userModel);
      this.profile = await PrivacyNugget.GetSafe(this.userModel.entityId);
      var result = await TransactionNugget.GetCustomData(this.userModel.entityId);
      if (result?.feedback == undefined) {
      } else {
        this.customData = result != undefined ? result: <any>{};
      }
    }
  }
}


