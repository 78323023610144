





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";
import { ENVIRONMENT, APPMODE, AppModes } from "@/services/Config";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";

@Component({
  mixins: [],
  components: {

  }
})
export default class CountryPostalCodes extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  @Prop({ default: false })
  protected ModeSelf!: Boolean;
  @Prop({ default: <any>{ entityId: UserModule.UserEntityId } })
  public userModel!: User;
  public Countries :Array<string> = [];
  public PLZ :Array<string> = [];
  private countryoptions: Array<any> = Environment.CountryOptions.filter(m=>m.value != null);

 public get IsPawnBroker(): boolean {
    const claims = UserModule.Claims[0];
    return claims == "PB";
  }

   public get IsPawnApp(): boolean {
    return APPMODE == AppModes.Pfandlädchen;
  }


  public async mounted() {
    if (this.ModeSelf) {
      this.PLZ = UserModule.CustomData.postalcodes??[];
    }
      this.Countries = UserModule.CustomData.countries??[];
  }

  public async SaveCountriesPLZ(){
    var data = UserModule.CustomData;
    data.countries = this.Countries;
    data.postalcodes = this.PLZ;
    await TransactionNugget.UpdateCustomData(UserModule.userEntityId,data);
  }

}
