

















































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import Headline from "@shared/components/General/Headline.vue";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import CreateTransaction from "../CreateTransaction.vue";
@Component({
  mixins: [ModalMixin, TransactionMixin],
  components: { Headline, TransactionDisplayHeadline, CreateTransaction }
})
export default class CustomerInquiryCorrection extends Mixins(
  ModalMixin,
  TransactionMixin
) {
  @Prop({ default: false }) newItem!: Boolean;
  //#endregion
  public async OnActivated() {
    this.Initialize();
  }

  public Initialize() {
    if (!this.newItem) {
      this.EnableLabels = true;
      this.Pagenr = -1;
      TransactionModule.SelectCategory(this.transactionModel.category);
      TransactionModule.SelectTransactionMode(
        this.transactionModel.transactionMode
      );
      TransactionModule.SET_TRANSACTION(this.transactionModel);
      TransactionModule.SET_RUNTIME(Number(this.transactionModel.period));
      TransactionModule.SET_SUM(this.transactionModel.summwishedfor);
    } else {
      TransactionModule.Clear();
    }
  }

  @Emit("transactionChanged")
  public FireChanged(id: string) {}

  public onCreated() {
    this.newItem = false;
    this.transactionModel = TransactionModule.CurrentTransaction!;
    this.Initialize();
  }

  public async SaveMe() {
    this.SaveModel(false);
    this.Changed(this.transactionModel.id);
    this.CloseModal();
  }

  public async Changed(id: string) {
    this.isLoading = true;
    try {
      await this.Save(false, "new");
      // await TransactionNugget.TransitionTransactionState(this.transactionModel,'new')
      this.FireChanged(this.transactionModel.id);
    } catch (e) {
      this.error = "Die Anfrage konnte nicht gesendet werden, bitte probiere es später erneut"!;
    }
    this.isLoading = false;
  }

  public mounted() {}

  public;
}
