


















































import { Component, Prop, Vue, Model, Mixins } from "vue-property-decorator";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { FilterSettings } from '@shared/components/General/FilterSettings'


@Component({})
export default class TransactionFilter extends Vue {
  @Prop() alltransactions!: Components.Schemas.Transaction;

  @Prop() Settings!: FilterSettings;
  private categories: Components.Schemas.Category[] = [];
  private category?: Components.Schemas.Category;

  private phrase:string = '';
  public get CategoryName(): string {
    return this.Settings.CategoryId == ""
      ? "Kategorie"
      : this.category?.name ?? "";
  }

   public get ReactionName(): string {
     if (Number(this.Settings.ReactionTime) == 0) {
  return "Reaktionszeit";
     }else{
  return '< '+ this.Settings.ReactionTime.toString()+' Stunden';
     }
  }

   public get TypeName(): string {
    return this.Settings.Type == ""
      ? "Typ"
      :  this.Settings.Type == "sell"?'Verkaufen':'Beleihen';
  }
  public SetReactionTime(value: number) {
    this.Settings.ReactionTime = value;
  }

  public SetCategory(value: Components.Schemas.Category) {
    this.category = value;
    Vue.set(this.Settings, 'CategoryId', value.id);

  }
  public SetType(value: string) {
    this.Settings.Type = value;
  }

  public ClearFilters() {
    this.Settings.SearchPhrase = '';
    this.Settings.CategoryId = '';
    this.Settings.ReactionTime = 0;
    this.Settings.Type = '';
    this.phrase = '';

  }
  public DoSearch(){
     this.Settings.SearchPhrase =  this.phrase;
  }

  public async mounted() {
    await TransactionModule.SearchCategories();
    this.categories = TransactionModule.Categories;
  }
}
