































import { Component, Prop, Vue, Mixins, Emit } from "vue-property-decorator";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import HeadlinePager from "@shared/components/General/HeadlinePager.vue";

const moment = require("moment");

@Component({ components: { HeadlinePager } })
export default class Headline extends Vue {
  @Prop() transactionModel!: Components.Schemas.Transaction;
  @Prop() transactions!: Components.Schemas.Transaction[];

  moment() {
    moment.locale("de");
    return moment;
  }

  @Emit("onClose")
  Close() {}

  public mounted() {}
}
