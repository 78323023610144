export class FilterSettings {
  public SearchPhrase!: string;
  public CategoryId!: string;
  public ReactionTime!: number;
  public Type!:string;

  public IsSet():boolean {
    return this.SearchPhrase != ''|| this.ReactionTime != 0 || this.CategoryId != '' || this.Type != '';
  }
   constructor(){
     this.SearchPhrase = '';
     this.ReactionTime = 0;
     this.CategoryId = '';
     this.Type= '';
  }
}
