













































































































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit,
} from "vue-property-decorator";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import Congratulations from "./Congratulations.vue";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");
import { totalSum, totalInterest, totalFees } from "@shared/CalculateTotal";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import Headline from "@shared/components/General/Headline.vue";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
import ViewProfile from "@shared/components/general/AccountParts/ViewProfile.vue";
import { APPMODE } from "../../../../../src/services/Config";
import { Console } from "console";

@Component({
  mixins: [TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    TransactionDisplayHeadline,
    Headline,
    Congratulations,
  },
})
export default class OfferAcceptedView extends Mixins(
  TransactionListMixin,
  TransactionMixin
) {
  offers: Array<any> = [];
  public get profile(): Definitions.PrivacyProfile | undefined {
    if (this.offers.length > 0) {
      return this.offers[0].profile;
    }
    return undefined;
  }
  @Emit("opened")
  public Opened() {}

  public congratulations: Boolean =
    sessionStorage.getItem("accptedTransaction") != undefined;

  @Prop() transactionModelId!: string;

  @Prop() transactionModel!: Components.Schemas.Transaction;

  public async mounted() {
    const id = this.transactionModelId ?? this.transactionModel?.id;
    console.log(id, "id");
    const t = await TransactionNugget.GetTransaction(id);
    this.transactionModel = t;
    if (t == null) {
      console.log("t is null");
      return;
    }
    this.offers = await this.OffersAsyncVM(t);
    TransactionModule.SET_TRANSACTION(t);
    TransactionModule.SET_TRANSACTIONMODE(t.transactionMode);
    TransactionModule.SET_RUNTIME(Number(t.period));
    TransactionModule.SET_SUM(t.summwishedfor);
    if (this.transactionModel.transactionMode.toLowerCase() != "pawn") {
      this.fields = [
        {
          key: "creditamount",
          label: "Gebot",
          thClass: "th-creditamount",
          tdClass: "td-creditamount",
        },
      ];
    }
  }

  public GetCountryName(text: string) {
    return PrivacyNugget.GetCountryName(text);
  }

  public GetCleanEmail(text: string) {
    return text.indexOf(APPMODE + "_") >= 0
      ? text.substring(APPMODE.length + 1)
      : text;
  }

  public async OffersAsyncVM(transaction: Components.Schemas.Transaction) {
    if (transaction == undefined) {
      console.log("transaction is undefined");
      return [];
    }
    const filtered = transaction.responses.filter((x) => {
      return x.offerstate == "selected";
    });
    if (filtered.length == 0) {
      console.log("no selected offfer found");
      console.log(transaction.responses);
    }
    var rownr: number = 0;
    const result = (
      await Promise.all(
        filtered.map(async (v) => {
          rownr++;
          var profile = await PrivacyNugget.GetSafe(v?.privacytoken ?? "");
          return {
            id: v.id,
            profile: profile,
            pawnbrokername_pawnbrokeraddress: {
              pawnbrokername: profile.company,
              pawnbrokeraddress:
                profile.postalcode +
                " " +
                profile.city +
                ", " +
                PrivacyNugget.GetCountryName(profile?.country ?? ""),
            },
            creditamount: v.values["kreditbetrag"],
            creditinterest: {
              creditinterest: totalInterest(
                Number(v.values["kreditbetrag"]),
                Number(v.values["zinsen"]),
                Number(transaction.period)
              ),
              creditinterestpercent: v.values["zinsen"],
            },
            creditfees: totalFees(
              Number(v.values["kreditbetrag"]),
              Number(v.values["gebühren"]),
              Number(transaction.period)
            ),
            creditsum: totalSum(
              Number(v.values["kreditbetrag"]),
              Number(v.values["zinsen"]),
              Number(v.values["gebühren"]),
              Number(transaction.period)
            ),
            selectoffer: "",
          };
        })
      )
    ).sort((a, b) => {
      return a.creditsum - b.creditsum;
    });
    return result;
  }

  public checked_custome_contact: boolean = false;
  public fields: Array<any> = [
    {
      key: "creditamount",
      label: "Kreditbetrag",
      thClass: "th-creditamount",
      tdClass: "td-creditamount",
    },
    {
      key: "creditinterest",
      label: "Zinsen",
      thClass: "th-creditinterest",
      tdClass: "td-creditinterest",
    },
    {
      key: "creditfees",
      label: "Gebühren",
      thClass: "th-creditfees",
      tdClass: "td-creditfees",
    },
    {
      key: "creditsum",
      label: "Gesamtkosten",
      thClass: "th-creditsum",
      tdClass: "td-creditsum",
    },
  ];
  // ---------------------- ITEMS ------------------------
}
