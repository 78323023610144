


















import { Component, Prop, Vue } from "vue-property-decorator";

import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import * as ApiEnvironment from "@/services/Environment";
import { APPMODE } from "@/services/Config";
import { UserModule } from "@shared/store/modules/UserAuthModule";
@Component
export default class Debug extends Vue {
  public mounted() {}

  public get CNAPI() :string{
    return ApiEnvironment.Environment.CNAPI_ENDPOINT;
  }

  public async LoginPF() {
    const user: User = {
      userName: APPMODE + "_Pawn@vertical.codes", //this.username,
      password: "mysecurepassword", //this.password,
      firstName: "",
      lastName: "",
      userPoolId: -1,
      language: "de",
      eMail: "",
      timeZone: "",
      id: "",
      entityId: "",
      userRoles: ""
    };
    await UserModule.Login(user);
    this.$router.push("/pf");
  }
  public async LoginGV() {
    const user: User = {
      userName: APPMODE + "_gv@vertical.codes", //this.username,
      password: "mysecurepassword", //this.password,
      firstName: "",
      lastName: "",
      userPoolId: -1,
      language: "de",
      eMail: "",
      timeZone: "",
      id: "",
      entityId: "",
      userRoles: ""
    };
    await UserModule.Login(user);
    this.$router.push("/GV/GVListCustomerInquiries");
  }

  public async ToRequest() {
    this.$router.push("/Request1");
  }
  public async LoginCustomer() {
    const user: User = {
      userName: APPMODE + "_customer@vertical.codes", //this.username,
      password: "mysecurepassword", //this.password,
      firstName: "",
      lastName: "",
      userPoolId: -1,
      language: "de",
      eMail: "",
      timeZone: "",
      id: "",
      entityId: "",
      userRoles: ""
    };
    await UserModule.Login(user);
    this.$router.push("/Customer/CustomerOverview");
  }
}
