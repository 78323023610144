var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"contentarea"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"h1--isdark mb-4"},[_vm._v("Benutzerverwaltung")]),_c('div',{staticClass:"useradmin-lists"},[_c('b-tabs',{attrs:{"align":"center","nav-class":"flex-column flex-sm-row"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{staticClass:"mt-4",attrs:{"title-link-class":"customernew_yes","active":""},on:{"click":function($event){return _vm.SaveTab(0)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Kunden - Neu "),_c('i',{staticClass:"fa fa-lg fa-exclamation-circle"})]},proxy:true}])},[_c('UserFilter',{attrs:{"Settings":_vm.CurrentFilterSettings}}),_c('div',{staticClass:"listtable"},[_c('b-table',{staticClass:"table-useradmin-customer table-head--noborder-top",attrs:{"hover":"","items":_vm.UsersVM(_vm.Users, 'customernew'),"fields":_vm.fields_customernew},scopedSlots:_vm._u([{key:"cell(customernewnumber)",fn:function(data){return [_vm._v(_vm._s(data.item.customernewnumber))]}},{key:"cell(customernewfname)",fn:function(data){return [_vm._v(_vm._s(data.item.customernewfname))]}},{key:"cell(customernewlname)",fn:function(data){return [_vm._v(_vm._s(data.item.customernewlname))]}},{key:"cell(customernewemail)",fn:function(data){return [_vm._v(_vm._s(data.item.customernewemail))]}},{key:"cell(customernewcompany)",fn:function(data){return [_vm._v(_vm._s(data.item.customernewcompany))]}},{key:"cell(customernewzip_customernewcity)",fn:function(data){return [_vm._v(_vm._s(data.value.customernewzip)+" "+_vm._s(data.value.customernewcity))]}},{key:"cell(customernewcountry)",fn:function(data){return [_vm._v(_vm._s(data.item.customernewcountry))]}},{key:"cell(btnaction)",fn:function(data){return [_c('b-button',{attrs:{"variant":"secondary","to":{
                      name: 'gvconfirmuser',
                      params: {
                        user: data.value.email,
                        userid: data.value.id,
                      },
                    },"href":"","size":"sm"}},[_vm._v("Kontrollieren")]),_c('user-delete-confirmation',{attrs:{"size":"sm","user":data.value.userObj,"buttonname":"löschen und sperren"},on:{"userDeleted":_vm.Reload}})]}}])})],1)],1),_c('b-tab',{staticClass:"mt-4",attrs:{"title":"Kunden","href":"#kunden"},on:{"click":function($event){return _vm.SaveTab(1)}}},[_c('div',{staticClass:"listtable"},[_c('UserFilter',{attrs:{"Settings":_vm.CurrentFilterSettings}}),_c('b-table',{staticClass:"table-useradmin-customer table-head--noborder-top",attrs:{"hover":"","items":_vm.UsersVM(_vm.Users, 'customer'),"fields":_vm.fields_customer},scopedSlots:_vm._u([{key:"cell(customernumber)",fn:function(data){return [_vm._v(_vm._s(data.item.customernumber))]}},{key:"cell(customerfname)",fn:function(data){return [_vm._v(_vm._s(data.item.customerfname))]}},{key:"cell(customerlname)",fn:function(data){return [_vm._v(_vm._s(data.item.customerlname))]}},{key:"cell(customeremail)",fn:function(data){return [_vm._v(_vm._s(data.item.customeremail))]}},{key:"cell(customercompany)",fn:function(data){return [_vm._v(_vm._s(data.item.customercompany))]}},{key:"cell(customerzip_customercity)",fn:function(data){return [_vm._v(_vm._s(data.value.customerzip)+" "+_vm._s(data.value.customercity))]}},{key:"cell(customercountry)",fn:function(data){return [_vm._v(_vm._s(data.item.customercountry))]}},{key:"cell(btnaction)",fn:function(data){return [_c('b-button',{attrs:{"variant":"secondary","to":{
                      name: 'gvshowuser',
                      params: {
                        user: data.value.email,
                        userid: data.value.id,
                      },
                    },"href":"","size":"sm"}},[_vm._v("Ansehen")]),_c('user-delete-confirmation',{attrs:{"size":"sm","user":data.value.userObj,"buttonname":"löschen und sperren"},on:{"userDeleted":_vm.Reload}})]}}])})],1)]),(_vm.PFAppMode())?_c('b-tab',{staticClass:"mt-4",attrs:{"title":"Pfandleiher"},on:{"click":function($event){return _vm.SaveTab(2)}}},[_c('div',{staticClass:"listbuttons mb-4"},[_c('b-button',{attrs:{"to":{
                  name: 'gvnewuser',
                  params: {
                    user: '',
                    userid: -1,
                    userType: 'pawn',
                    newuser: true,
                  },
                }}},[_c('i',{staticClass:"fa fa-lg fa-plus-circle"}),_vm._v("Pfandleiher anlegen ")])],1),_c('UserFilter',{attrs:{"Settings":_vm.CurrentFilterSettings}}),_c('div',{staticClass:"listtable"},[_c('b-table',{staticClass:"table-useradmin-pawnbroker table-head--noborder-top",attrs:{"hover":"","items":_vm.UsersVM(_vm.Users, 'pawnbroker'),"fields":_vm.fields_pawnbroker},scopedSlots:_vm._u([{key:"cell(pawnbrokernumber)",fn:function(data){return [_vm._v(_vm._s(data.item.pawnbrokernumber))]}},{key:"cell(pawnbrokerfname)",fn:function(data){return [_vm._v(_vm._s(data.item.pawnbrokerfname))]}},{key:"cell(pawnbrokerlname)",fn:function(data){return [_vm._v(_vm._s(data.item.pawnbrokerlname))]}},{key:"cell(pawnbrokeremail)",fn:function(data){return [_vm._v(_vm._s(data.item.pawnbrokeremail))]}},{key:"cell(pawnbrokercompany)",fn:function(data){return [_vm._v(_vm._s(data.item.pawnbrokercompany))]}},{key:"cell(pawnbrokerzip_pawnbrokercity)",fn:function(data){return [_vm._v(_vm._s(data.value.pawnbrokerzip)+" "+_vm._s(data.value.pawnbrokercity))]}},{key:"cell(pawnbrokercountry)",fn:function(data){return [_vm._v(_vm._s(data.item.pawnbrokercountry))]}},{key:"cell(btnaction)",fn:function(data){return [_c('b-button',{attrs:{"variant":"secondary","to":{
                      name: 'gvshowuser',
                      params: {
                        user: data.value.email,
                        userid: data.value.id,
                      },
                    },"href":"","size":"sm"}},[_vm._v("Ansehen")]),_c('user-delete-confirmation',{attrs:{"size":"sm","user":data.value.userObj,"buttonname":"löschen und sperren"},on:{"userDeleted":_vm.Reload}})]}}],null,false,2848633087)})],1)],1):_vm._e(),_c('b-tab',{staticClass:"mt-4",attrs:{"title":"Geldverleiher"},on:{"click":function($event){return _vm.SaveTab(3)}}},[_c('div',{staticClass:"listbuttons mb-4"},[_c('b-button',{attrs:{"to":{
                  name: 'gvnewuser',
                  params: {
                    user: '',
                    userid: -1,
                    userType: 'gv',
                    newuser: true,
                  },
                }}},[_c('i',{staticClass:"fa fa-lg fa-plus-circle"}),_vm._v("Geldverleiher anlegen ")])],1),_c('UserFilter',{attrs:{"Settings":_vm.CurrentFilterSettings}}),_c('div',{staticClass:"listtable"},[_c('b-table',{staticClass:"table-useradmin-inhouse table-head--noborder-top",attrs:{"hover":"","items":_vm.UsersVM(_vm.Users, 'inhouse'),"fields":_vm.fields_inhouse},scopedSlots:_vm._u([{key:"cell(inhousefname)",fn:function(data){return [_vm._v(_vm._s(data.item.inhousefname))]}},{key:"cell(inhousezip_inhousecity)",fn:function(data){return [_vm._v(_vm._s(data.value.pawnbrokerzip)+" "+_vm._s(data.value.pawnbrokercity))]}},{key:"cell(inhouseemail)",fn:function(data){return [_vm._v(_vm._s(data.item.inhouseemail))]}},{key:"cell(btnaction)",fn:function(data){return [_c('b-button',{attrs:{"variant":"secondary","to":{
                      name: 'gvshowuser',
                      params: {
                        user: data.value.email,
                        userid: data.value.id,
                      },
                    },"href":"","size":"sm"}},[_vm._v("Bearbeiten")]),_c('user-delete-confirmation',{attrs:{"size":"sm","nolock":true,"user":data.value.userObj,"buttonname":"löschen"},on:{"userDeleted":_vm.Reload}})]}}])})],1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }