




































import { Component, Prop, Vue, Model, Mixins } from "vue-property-decorator";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
@Component({})
export default class TransactionDisplayHeadline extends Vue {
  @Prop() transactionModel!: Components.Schemas.Transaction;


  public mounted() {}
}
