





import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {}
})
export default class TimerDisplay extends Vue {
  @Prop() ToDate!: moment.Moment;
  public remaining: string = "";
  private intervallTimer :any|undefined = undefined;

  public mounted() {
    this.remaining = moment(this.ToDate, "DD.MM.YYYY HH:mm:ss").add(24, "hour")
        .fromNow();
    this.intervallTimer = setInterval(x => {
      this.remaining = moment(this.ToDate, "DD.MM.YYYY HH:mm:ss")
        .add(24, "hour")
        .fromNow();
    },60000);
  }
    public beforeDestroy () {
	clearInterval(this.intervallTimer);
}
}
