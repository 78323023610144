























import { ValidationProvider } from "vee-validate";
import { Prop,Vue,Component,Watch } from 'vue-property-decorator';
import VuePassword from 'vue-password';

@Component({
  components: {
    ValidationProvider,VuePassword
  },
 })
export default class BTextInputWithValidation extends Vue {

    @Prop()
    public vid: String = '';
    @Prop()
    public rules: String = '';

    @Prop()
    public append: String = '';

    @Prop()
    public size: String = '';
    // must be included in props
    @Prop()
    public value: string = '';

    public innerValue:string =  '';

    // Handles internal model changes.
    @Watch('innerValue')
    innerValuechanged (newVal:string) {

      this.$emit('input', newVal);

    }
    // Handles external model changes.
    @Watch('value')
    valuechanged (newVal:string) {


     this.innerValue = newVal;
    }


  public created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
