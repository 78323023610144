













































































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";

import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");

import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import Headline from "@shared/components/General/Headline.vue";

@Component({
  mixins: [ModalMixin, TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    TransactionDisplayHeadline,
    Headline
  }
})
export default class InquiryCheck extends Mixins(
  ModalMixin,
  TransactionListMixin,
  TransactionMixin
) {
  @Prop()
  public noaction: boolean = true;

  public async OnActivated() {
    this.ProblemText = "";
    if (this.deleteButton == null) this.deleteButton = "Ablehnen";
    this.$forceUpdate();
  }
  @Prop()
  public deleteButton!: string;

  @Emit("transactionDeleted")
  public OnDeleted(id: string) {
    this.OnChanged(id);
    this.CloseModal();
  }

  @Emit("transactionChanged")
  public OnChanged(id: string) {}

  @Emit("transactionReleased")
  public Released(id: string) {}

  public PagerLeftClicked() {}

  public PagerRightClicked() {}

  public async Release(id: string) {
    this.isLoading = true;
    try {
      await TransactionNugget.TransitionTransactionStateById(
        this.transaction,
        "approved"
      );
    } catch (e) {
      this.error = "Anfrage konnte nicht freigegeben werden"!;
    }
    this.isLoading = false;
    this.Released(id);
  }

  public async Rueckfragen() {
    try {
      this.isLoading = true;
      await TransactionNugget.SendMessage(
        this.transactionModel.id,
        this.Problems,
        this.ProblemText
      );
      await TransactionNugget.TransitionTransactionStateById(
        this.transaction,
        "feedback"
      );
    } catch (e) {
      this.error = "Rückfrage konnte nicht gesendet werden"!;
    }
    this.isLoading = false;
    this.OnChanged(this.transactionModel.id);
  }
}
