import { Component, Prop, Vue } from "vue-property-decorator";
import OpenAPIClientAxios from "openapi-client-axios";
import { Client, Components } from "./TransactionSvc";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import * as ApiEnvironment from "@/services/Environment";
import { componentsPlugin } from 'bootstrap-vue';
import router from '@/router/index'
import { ENVIRONMENT } from '@/services/Config';
import { User } from './ManagementNugget';


export default class TransactionNugget extends Vue {


  public static Client: Client | null = null;
  public static LastEndpoint: string | null = null;


  public static async GetCustomData(key: string) {
    var email = '';
    if (key == UserModule.UserEntityId) {
      email = UserModule.Email;

      if (UserModule.CustomData?.email != null)
        return UserModule.CustomData;
    }
    const client = await TransactionNugget.PrepareClient();
    try {
      const result = await client.getcustomdata({ key: key });
      if (key == UserModule.UserEntityId) {
        UserModule.UpdateCustomData(result.data);
        if (UserModule.CustomData.email == "") UserModule.CustomData.email = UserModule.Email;
        return UserModule.CustomData;
      }
      return result.data;
    } catch (ex) {
        await client.updatecustomdata({ key: key }, <any>{Email:email}); // Wha if is not my user
    }
    if (key == UserModule.UserEntityId) {
      UserModule.UpdateCustomData(<any>{});
    }

  }


  public static async Register(user:User) {
    const defs = require("./Transactionsservice.json");
    defs["servers"][0]["url"] = ApiEnvironment.Environment.API_ENDPOINT;

    const api = new OpenAPIClientAxios({
      definition: defs,
      axiosConfigDefaults: {
        headers: {
        }
      }
    });
    const client = await api.getClient<Client>();
    const result = await client.Register({language:'de'},user) ;
    return result.data;
  }

  public static async ResetPassword(email: string, language:string = "de") {
    const defs = require("./Transactionsservice.json");
    defs["servers"][0]["url"] = ApiEnvironment.Environment.API_ENDPOINT;

    const api = new OpenAPIClientAxios({
      definition: defs,
      axiosConfigDefaults: {
        headers: {
        }
      }
    });
    //  if (api.definition.servers != undefined) {
    //  api.definition.servers[0].url = ApiEnvironment.Environment.API_ENDPOINT;
    //   }
    const client = await api.getClient<Client>();
    const result = await client.ResetPassword({ email: email, language: language });
    return result.data;
  }
  public static async UpdateCustomData(key: string, object: any) {
    const client = await TransactionNugget.PrepareClient();
    const mydata: string = JSON.stringify(object);
    UserModule.UpdateCustomData(object);
    const result = await client.updatecustomdata({ key: key }, object);
    return result.data;
  }

  public static async DeleteUserData(entityId: string) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.DeleteUserData({ key: entityId });
    return result.data;
  }

  public static async GetSafes(key: Array<string>) {
    //const key = keys.map(x => 'key=' + x).join("&").substr(4);
    const client = await TransactionNugget.PrepareClient();
    // Paging max
    const result = (await client.getsafes({ key: key, customData: true })).data;
    result.forEach(function (value) {
      if (value.customData != null) {
        try {
          value.customData = JSON.parse(value.customData.replace("null", "\\\"\\\"").replace("null", "\\\"\\\""));
        } catch (ex) {

        }
      }
    });
    return result;
  }



  public static async GetFiles(transaction: Components.Schemas.Transaction, keyname: string) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.GetFiles({
      guid: transaction.id
    }); //[{name:'language',value:'de',in: 'query' }]
    return result.data;
  }

  public static async RemoveFile(transaction: Components.Schemas.Transaction, keyname: string) {
    const client = await TransactionNugget.PrepareClient();
    console.log("Removing File" + keyname);

    const result = await client.RemoveFile({
      guid: transaction.id,
      filename: keyname
    }, {
      filename: keyname
    }); //[{name:'language',value:'de',in: 'query' }]
    return result.data;
  }

  public static async GetTransactionsByState(state: string, endpoint: string = ApiEnvironment.Environment.API_ENDPOINT): Promise<Components.Schemas.Transaction[]> {
    const client = await TransactionNugget.PrepareClient(endpoint);
    var stateobj: any = state.toLowerCase();
    /* tslint:disable */

    const result = await client.GetTransactionbyState({
      state: stateobj,
      language: "de"
    }); //[{name:'language',value:'de',in: 'query' }]

    /* tslint:enable */

    return result.data;
  }


  public static async GetTransaction(id: string): Promise<Components.Schemas.Transaction> {
    const client = await TransactionNugget.PrepareClient();
    /* tslint:disable */

    const result = await client.GetTransaction({
      guid: id,
    }); //[{name:'language',value:'de',in: 'query' }]

    /* tslint:enable */

    return result.data;
  }




  public static async GetMyTransactionsByState(state: string): Promise<Components.Schemas.Transaction[]> {
    const client = await TransactionNugget.PrepareClient();
    var stateobj: any = state.toLowerCase();
    /* tslint:disable */

    const result = await client.GetMyTransactions({
      state: stateobj,
      language: "de"
    }); //[{name:'language',value:'de',in: 'query' }]

    /* tslint:enable */

    return result.data;
  }

  private static async PrepareClient(endpoint: string = ApiEnvironment.Environment.API_ENDPOINT) {

    if (TransactionNugget.Client == null || TransactionNugget.LastEndpoint != endpoint) {
      const defs = require("./Transactionsservice.json");
      defs["servers"][0]["url"] = endpoint;
      TransactionNugget.LastEndpoint = endpoint;
      const token = UserModule.Token;
      const api = new OpenAPIClientAxios({
        definition: defs,
        axiosConfigDefaults: {
          headers: {
            CloudNuggetJWTToken: token
          }
        }
      });
      //  if (api.definition.servers != undefined) {
      //  api.definition.servers[0].url = ApiEnvironment.Environment.API_ENDPOINT;
      //   }
      TransactionNugget.Client = await api.getClient<Client>();
      TransactionNugget.Client.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error?.response?.status === 401) {
          UserModule.Logout();
          TransactionNugget.Client = null;
          router.push('/login')
        }
        return Promise.reject(error)
      });
    }
    return TransactionNugget.Client;
  }

  public static async UpdateTransaction(transaction: Components.Schemas.Transaction) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.UpdateTransaction({ guid: transaction.id }, transaction);
    return result.data;
  }

  public static async TransitionTransactionState(transaction: Components.Schemas.Transaction, state: string) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.SetTransactionState({ guid: transaction.id, state: state });
    return result.data;
  }
  public static async UpdateUserStyle(entityId: string) {
    const rnd = Math.floor(Math.random() * 1000);
    var file = ApiEnvironment.Environment.API_ENDPOINT + '/UserSettings/'+entityId+'/Stylesheet?cloudnuggetjwttoken=' + UserModule.token + "&rnd=" + rnd;

    var link = document.createElement("link");
    link.href = file;
    link.id = "customstyles";
    link.type = "text/css";
    link.rel = "stylesheet";
    link.media = "screen,print";
    var found : HTMLLinkElement|null = <HTMLLinkElement>document.getElementById('customstyles');
    if (found == undefined) {
      document.getElementsByTagName("head")[0].appendChild(link);
    } else {
      found.href = file;
      found.id = "customstyles";
      found.type = "text/css";
      found.rel = "stylesheet";
      found.media = "screen,print";
    }
  }


  public static async SetMyCategory(id: string, UserEntityId:string = "") {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.SetMyCategory({ id: id, userEntityId: UserEntityId });
    return result.data;
  }
  public static async RemoveMyCategory(id: string,UserEntityId:string = "") {
    console.log('removecat')
    const client = await TransactionNugget.PrepareClient();
    const result = await client.RemoveMyCategory({ id: id, userEntityId: UserEntityId });
    return result.data;
  }

  public static async TransitionTransactionStateById(transactionid: string, state: string) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.SetTransactionState({ guid: transactionid, state: state });
    return result.data;
  }
  public static async SendMessage(transactionid: string, problems: Array<string>, problemText: string) {
    const client = await TransactionNugget.PrepareClient();
    const newmsg: Components.Schemas.Lifecycle = { type: "Message", message: JSON.stringify({ "problems": problems, "problemText": problemText }), timestamp: "", senderUserID: "0", newState: "" };

    const result = await client.PostMessage({ guid: transactionid }, newmsg);
    return result.data;
  }

  public static async LoadCategories() {
    const client = await TransactionNugget.PrepareClient();
    const result = (await client.GetCategories('de')) //[{name:'language',value:'de',in: 'query' }]
    return result.data;
  }

  public static async CreateTransaction(payload: any) {
    const client = await TransactionNugget.PrepareClient();
    var result = (await client.CreateTransaction(payload));//[{name:'language',value:'de',in: 'query' }]
    return result.data;
  }

  public static async LoadMyCategories(UserEntityId:string = "") {
    const client = await TransactionNugget.PrepareClient();

    const result = (await client.GetMyCategories({ userEntityId: UserEntityId })); //[{name:'language',value:'de',in: 'query' }]
    return result.data;
  }

  public static async CreateBid(transactionid: string, sum: number, interest: number, cost: number) {
    const client = await TransactionNugget.PrepareClient();
    const myModel = { "Kreditbetrag": sum.toString(), "zinsen": interest.toString(), "Gebühren": cost.toString() };

    const newmsg: Components.Schemas.Offer = { values: myModel };

    const result = await client.CreateOffer({ guid: transactionid }, newmsg);
    return result.data;
  }

  public static async DeleteBid(transactionid: string, id: string) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.DeleteOffer({ guid: transactionid, offerguid: id });
    return result.data;
  }


  public static async SelectBid(transactionid: string, id: string) {
    const client = await TransactionNugget.PrepareClient();
    const result = await client.GetContactDetails({ guid: transactionid, offerguid: id });
    return result.data;
  }
}
