































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { isFunction, forEach } from "lodash";
import { Environment } from "@/services/Environment";
import PrivacyNugget from "@shared/services/nuggets/PrivacyNugget";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import VuePictureSwipe from "vue-picture-swipe";
import { IMG_PREVIEWFILETYPE, IMG_LISTPREVIEWHEIGHT, IMG_LISTPREVIEWWIDTH, IMG_BACKGROUND, IMG_LISTORIGINALSIZE } from '@/services/Config';

@Component({
  components: {
    vue2Dropzone,
    VuePictureSwipe
  }
})
export default class DocumentDropzone extends Vue {
  @Prop()
  public profileModel!: Definitions.PrivacyProfile;
  @Prop()
  public id!: string;

  @Prop()
  public ReadOnly!: boolean;
  public files: Array<any> = [];
  public swipefiles: Array<any> = [];
  @Prop()
  public keyval!: string;

  active: boolean = true;
  public get dropzoneOptions(): any {
    return {
      url:
        Environment.CNPRIVACYAPI_ENDPOINT +
        "/1/safes/" +
        this.profileModel?.entityId +
        "/" +
        this.keyval,
      thumbnailWidth: 120,
      thumbnailHeight: 120,
      dictDuplicateFile: "Du kannst diese Datei nicht doppelt hochladen",
      dictDefaultMessage: "Drop files here to upload",
      dictFallbackMessage:
        "Dein Browser unterstützt keine drag'n'drop Datei uploads.",
      dictFallbackText:
        "Please use the fallback form below to upload your files like in the olden days.",
      dictFileTooBig:
        "Datei ist zu groß ({{filesize}}MiB). Maximal: {{maxFilesize}}MiB.",
      dictInvalidFileType: "Dieser Dateityp ist nicht erlaubt",
      dictResponseError: "Server responded with {{statusCode}} code.",
      dictCancelUpload: "Abbrechen",
      dictCancelUploadConfirmation:
        "Are you sure you want to cancel this upload?",
      dictRemoveFile: "Entfernen",
      dictMaxFilesExceeded: "Du kannst keine weiteren Dateien hochladen.",
      acceptedFiles: "image/jpeg,image/png,image/gif, application/pdf",
      addRemoveLinks: true,
      timeout: 1800000,
      previewTemplate: this.template(),
      headers: { CloudnuggetJWTToken: UserModule.Token }
      /*  previewTemplate: this.template(),*/
    };
  }

  async getFiles() {
    return await PrivacyNugget.GetSafeFiles(
      this.profileModel!.entityId??"",
      this.keyval
    );
  }
  async RemoveFile(file) {
    return await PrivacyNugget.DeleteSafeFile(
      this.profileModel!.entityId ?? "",
      this.keyval,
      file
    );
  }
  get ImageBaseUrl(): string {
    return (
      Environment.CNPRIVACYAPI_ENDPOINT +
      "/1/safes/" +
      this.profileModel.entityId +
      "/" +
      this.keyval +
      "/$filename/preview?width="+IMG_LISTPREVIEWWIDTH+"&height="+IMG_LISTPREVIEWHEIGHT+"&format="+IMG_PREVIEWFILETYPE+"&originalsize="+IMG_LISTORIGINALSIZE.toString()+"&background="+IMG_BACKGROUND+"&CloudnuggetJWTToken=" +
      UserModule.Token
    );
  }
  beforeDestroy() {
    //const mydropzone =  this.$refs.myVueDropzone;
    // mydropzone.off(); //removes all listeners attached with Emitter.on()
    this.active = false;
  }

  async mounted() {
    this.files = await this.getFiles();
    this.swipefiles = this.files;
    const url: string = this.ImageBaseUrl;
    if (!this.ReadOnly) {
      this.$nextTick(async function() {
        var mappedfiles = this.files.map(function(v) {
          return { name: v.filename, size: v.length, type: "image" };
        });
        var mydropzone = this.$refs[this.keyval + "myVueDropzone"];

        forEach(mappedfiles, function(file) {
          if (<any>mydropzone != undefined)
            (<any>mydropzone).manuallyAddFile(
              file,
              url.replace("$filename", file.name)
            );
        });
        this.files = mappedfiles.map(function(v) {
          return v.name;
        });
      });
    }
  }

  template() {
    return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                      <div data-dz-thumbnail-bg></div>
                  </div>
                  <div class="dz-details">
                      <div class="dz-size"><span data-dz-size></span></div>
                      <div class="dz-filename"><span data-dz-name></span></div>
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  <div class="dz-success-mark"><i class="fad fa-2x fa-check-circle fa-theme-dgv"></i></div>
                  <div class="dz-error-mark"><i class="fad fa-2x fa-close-circle fa-theme-dgv"></i></div>
               </div>
        `;
  }
  thumbnail(file, dataUrl) {
    var j, len, ref, thumbnailElement;
    if (file.previewElement) {
      file.previewElement.classList.remove("dz-file-preview");
      ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
      for (j = 0, len = ref.length; j < len; j++) {
        thumbnailElement = ref[j];
        thumbnailElement.alt = file.name;
        thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
      }
      return setTimeout(
        (function(_this) {
          return function() {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this),
        1
      );
    }
  }

  uploadedThisFile(file, response) {
    this.files = (<any>(
      this.$refs[this.keyval + "myVueDropzone"]
    )).dropzone.files.map(function(v) {
      return v.name;
    });
    this.onChange();
  }

  removeThisFile(file, error, xhr) {
    if (this.active) {
      this.RemoveFile(file.name);

      this.files = (<any>(
        this.$refs[this.keyval + "myVueDropzone"]
      )).dropzone.files.map(function(v) {
        return v.name;
      });
    }
    this.onChange();
  }

  sendingEvent(file, xhr, formData) {
    // formData.append('paramName', 'some value or other');
  }

  @Emit()
  public onChange() {}

  public PhotoSwipeVM(pictures): Array<any> {
    const me = this;
    var result = this.swipefiles.map(m => {
      return {
        src: me.ImageBaseUrl.replace("$filename", m.filename),
        thumbnail: me.ImageBaseUrl.replace("$filename", m.filename),
        w: 600,
        h: 400,
        alt: m // optional alt attribute for thumbnail image
      };
    });

    return result;
  }
}
