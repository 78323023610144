









































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import Headline from "@shared/components/General/Headline.vue";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import CreateTransaction from "@shared/components/Customer/CreateTransaction.vue";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
@Component({
  mixins: [ModalMixin, TransactionMixin],
  components: { Headline, TransactionDisplayHeadline, CreateTransaction }
})
export default class TransactionEdit extends Mixins(
  TransactionMixin
) {
    @Prop() transactionModel!: Components.Schemas.Transaction;
    @Prop() transactionid!: string;
  //#endregion


  public async Initialize(){
      this.EnableLabels = true;
      this.Pagenr = -1;
      this.transactionModel = await TransactionNugget.GetTransaction(this.transactionid);
      TransactionModule.SelectCategory(this.transactionModel.category);
      TransactionModule.SelectTransactionMode(
        this.transactionModel.transactionMode
      );
      TransactionModule.SET_TRANSACTION(this.transactionModel);
      TransactionModule.SET_RUNTIME(Number(this.transactionModel.period));
      TransactionModule.SET_SUM(this.transactionModel.summwishedfor);
  }

  @Emit("transactionChanged")
  public FireChanged(id: string) {}


  public async SaveMe(){
    this.SaveModel(false);
    this.Changed(this.transactionModel.id);
  }

  public async Changed(id: string) {
    await this.Save(false, "new");
    // await TransactionNugget.TransitionTransactionState(this.transactionModel,'new')
    this.FireChanged(this.transactionModel.id);
  }

  public async mounted() {
        await this.Initialize();
  }

  public;
}
