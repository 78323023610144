<template >
<div class="btn-group-toggle-explanation-wrapper-formgenerator">
   <div class="btn-group-toggle-explanation-wrapper">
          <div class="btn-group-toggle-explanation" v-for="item in items" >
            <p>{{ getItemDescription(item) }}</p>
          </div>

        </div>
              <div class="btn-group-fullwidth">
          <b-form-group label="">
            <b-form-radio-group
              id="storetake"
              v-model="value"
              buttons
              button-variant="dgv-radio-toggle"
              size="sm"
              name="storetake"
            >
              <b-form-radio v-for="item in items" :value="item.id" v-bind:key="item.id" :id="getFieldID(schema, true)+item.id" :disabled="isItemDisabled(item)" :class="schema.fieldClasses">{{getItemName(item) }}</b-form-radio>
           <!--   <b-form-radio value="1">Weiternutzen</b-form-radio>-->
            </b-form-radio-group>
          </b-form-group>

        </div>
</div>
</template>

<script>

/*
	.radio-list(:disabled="disabled", v-attributes="'wrapper'")
		label(v-for="item in items", :class="getItemCssClasses(item)", v-attributes="'label'")
			input
			| {{ getItemName(item) }}
*/
import { isObject, isFunction, get as objGet } from "lodash";
import abstractField from "../abstractField";

export default {
	mixins: [abstractField],

	computed: {
		items() {
			let values = this.schema.values;
			if (typeof values == "function") {
				return values.apply(this, [this.model, this.schema]);
			} else {
				return values;
			}
		},
		id() {
			return this.schema.model;
    }
	},

	methods: {
       mounted()  {
         value= 'einlagern';
       },
		getItemValue(item) {
			if (isObject(item)) {
				if (typeof this.schema["values"] !== "undefined" && typeof this.schema["values"]["id"] !== "undefined") {
					return item[this.schema.values.value];
				} else {
					if (typeof item["id"] !== "undefined") {
						return item.id;
					} else {
						throw "`id` is not defined. If you want to use another key name, add a `value` property under `values` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
					}
				}
			} else {
				return item;
			}
		},
		getItemName(item) {
			if (isObject(item)) {
				if (typeof this.schema["values"] !== "undefined" && typeof this.schema["values"]["caption"] !== "undefined") {
					return item[this.schema.values.name];
				} else {
					if (typeof item["caption"] !== "undefined") {
						return item.caption;
					} else {
						throw "`caption` is not defined. If you want to use another key name, add a `name` property under `values` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
					}
				}
			} else {
				return item;
			}
    },
    	getItemDescription(item) {
			if (isObject(item)) {
				if (typeof this.schema["values"] !== "undefined" && typeof this.schema["values"]["description"] !== "undefined") {
					return item[this.schema.values.name];
				} else {
					if (typeof item["description"] !== "undefined") {
						return item.description;
					} else {
						throw "`description` is not defined. If you want to use another key name, add a `description` property under `values` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values";
					}
				}
			} else {
				return item;
			}
		},
		getItemCssClasses(item) {
			return {
				"is-checked": this.isItemChecked(item),
				"is-disabled": this.isItemDisabled(item)
			};
		},
		onSelection(item) {
      console.log('item'+item.id);
			this.value = this.getItemValue(item);
		},
		isItemChecked(item) {

      let currentValue = this.getItemValue(item);
      console.log(currentValue === this.value);
 			return currentValue === this.value;
		},
		isItemDisabled(item) {
			if (this.disabled) {
				return true;
			}
			let disabled = objGet(item, "disabled", false);
			if (isFunction(disabled)) {
				return disabled(this.model);
			}
			return disabled;
		}
	}
};
</script>

<style lang="scss">
.vue-form-generator .field-radios {
	.radio-list {
		label {
			display: block;
			input[type="radio"] {
				margin-right: 5px;
			}
		}
	}
}
</style>
