var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{attrs:{"variant":"secondary","size":_vm.size},on:{"click":function($event){return _vm.ShowModal()}}},[_c('i',{staticClass:"fa fa-lg fa-trash"}),_vm._v(_vm._s(_vm.ButtonName)+" ")]),(_vm.activated)?_c('b-modal',{ref:"mymodal",attrs:{"id":"modalInquiryDeleteConfirmation","size":"","content-class":"shadow","modal-class":"modal-message modal-message__alarm"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"modal__title-col",attrs:{"sm":"11"}},[_vm._v("Achtung!")]),_c('b-col',{staticClass:"modal__close-col",attrs:{"sm":"1"}},[_c('div',{staticClass:"modal__close"},[_c('b-link',{staticClass:"modal__close-icon",on:{"click":function($event){return close()}}},[_c('i',{staticClass:"fa fa-2x fa-times-circle"})])],1)])],1)],1)]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('p',[_vm._v(_vm._s(_vm.text))]),_c('p',{staticClass:"modal-body__text-info"},[_vm._v(_vm._s(_vm.subtext))])])],1)],1)]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"modal-footer__buttonbar"},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return cancel()}}},[_c('i',{staticClass:"fa fa-lg fa-times"}),_vm._v("Abbrechen ")]),_c('b-button',{attrs:{"variant":"danger","disabled":_vm.isLoading},on:{"click":function($event){return _vm.DeleteTransaction();}}},[(_vm.isLoading)?_c('i',{staticClass:"fa fa-lg fa-spinner fa-spin"}):_vm._e(),_c('i',{staticClass:"fa fa-lg fa-trash"}),_vm._v(_vm._s(_vm.deleteButton)+" ")])],1)])],1)],1)]}}],null,false,808954064)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }