<template>
  <ValidationProvider :vid="vid" :name="$attrs.name" :rules="rules">
    <b-form-group slot-scope="{ valid, errors, dirty, pending, pristine }"
      v-bind="$attrs"
    >
      <b-form-select id="exampleInput3"
        v-bind="$attrs"
        v-model="innerValue"
        :state="(dirty || pending || pristine) ? errors[0] ? false : (valid ? true : null):null"
      >
        <slot />
      </b-form-select>
        <b-form-invalid-feedback id="inputLiveFeedback">
          {{ errors[0] }}
        </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>


<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: null
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      console.log(newVal);
      console.log(this.$refs.validator);
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
