<template >
  <div class="dropzone-wrapper">
    <vue-dropzone
      ref="myVueDropzone"
      :include-styling="false"
      v-on:vdropzone-thumbnail="thumbnail"
      :useCustomSlot="true"
      :id="'dropzone-'+getFieldID(schema)"
      :options="dropzoneOptions"
      v-on:vdropzone-removed-file="removeThisFile"
      v-on:vdropzone-success="uploadedThisFile"
      v-on:vdropzone-sending="sendingEvent"
      v-on:vdropzoone-added-file="addedfile"
    >
      <div class="dropzone-custom-content">
        <div class="dropzone-custom-content__icon">
          <svg
            width="77px"
            height="52px"
            viewBox="0 0 77 52"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="icon-dropzone" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path
                d="M33.8000019,0 C43.234789,0 51.4340664,5.28984587 55.5937448,13.0654484 C56.1233629,13.0220229 56.6591107,13.0000007 57.2000021,13.0000007 C67.9695564,13.0000007 76.7000021,21.7304486 76.7000021,32.5000007 C76.7000021,43.1549853 68.1543225,51.8140158 57.5430109,51.9970446 L57.2000021,52.0000007 L41.504,52 L41.5046656,36.1627842 L44.9340227,39.5927703 L45.094877,39.7448937 C46.6262418,41.1140042 48.9789298,41.0632964 50.4494559,39.5927703 C51.9725007,38.0697255 51.9725007,35.600382 50.4494559,34.0773371 L50.4494559,34.0773371 L40.3378283,23.9657096 L40.1643342,23.8023345 C39.4554736,23.1740379 38.5726535,22.8481878 37.6833534,22.8247842 L37.6833534,22.8247842 L37.4730516,22.8247842 C36.5096433,22.8501381 35.5538398,23.2304466 34.8185768,23.9657096 L34.8185768,23.9657096 L24.7069492,34.0773371 C23.1839044,35.600382 23.1839044,38.0697255 24.7069492,39.5927703 C26.2299941,41.1158152 28.6993376,41.1158152 30.2223824,39.5927703 L30.2223824,39.5927703 L33.7046656,36.1087842 L33.704,52 L14.3000008,52.0000029 L14.3000259,51.9861275 C6.34491315,51.6458286 0,45.0888822 0,37.050002 C0,30.8534672 3.76992194,25.5374448 9.14129429,23.2704064 C9.88057585,10.2948448 20.6380439,0 33.8000019,0 Z"
                id="icon-dropzone-cloud"
                fill="#31AABD"
              />
            </g>
          </svg>
        </div>

        <div class="dropzone-custom-content__text">
          <p>Klicken und Datei auswählen</p>
          <p>oder ziehen und hier ablegen.</p>
        </div>
      </div>
    </vue-dropzone>
<div class="hidden" id="Parkplatz">
    <div :id="'addbutton_'+getFieldID(schema)" class="dz-preview dz-button-upload">
        <div class="dz-image">
          <b-link href="" @click="upload()">
            <img src="@assets/img/dropbox-btn-upload-icononly.svg" alt="Upload">
          </b-link>
        </div>
    </div>
</div>
  </div>
</template>
<script>
/*
.wrapper(v-attributes="'wrapper'")
	input.form-control(
,
		type="file",
		:name="schema.inputName",
		@change="onChange",
		:accept="schema.accept",
		:multiple="schema.multiple",
		:placeholder="schema.placeholder",
		:readonly="schema.readonly",
		:required="schema.required",
		:disabled="disabled",
    v-attributes="'input'")
    */

import vue2Dropzone from "vue2-dropzone";
// import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import abstractField from "../abstractField";
import { isFunction, forEach } from "lodash";
export default {
  mixins: [abstractField],
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function() {
    return {
      active: true,
      dropzoneOptions: {
        url: this.schema.postUrl,
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/jpeg,image/png,image/gif",
        addRemoveLinks: true,
        dictDuplicateFile: "Du kannst die Datei nicht doppelt hochladen",
        dictDefaultMessage: "Drop files here to upload",
        dictFallbackMessage:
          "Dein Browser unterstützt keine drag'n'drop Datei uploads.",
        dictFallbackText:
          "Please use the fallback form below to upload your files like in the olden days.",
        dictFileTooBig:
          "Datei ist zu groß ({{filesize}}MiB). Maximal: {{maxFilesize}}MiB.",
        dictInvalidFileType: "Dieser Dateityp ist nicht erlaubt",
        dictResponseError: "Server responded with {{statusCode}} code.",
        dictCancelUpload: "Abbrechen",
        dictCancelUploadConfirmation:
          "Are you sure you want to cancel this upload?",
        dictRemoveFile: "Entfernen",
        dictMaxFilesExceeded: "Du kannst keine weiteren Dateien hochladen.",
        preventDuplicates: true,
        timeout: 1800000,
        headers: this.schema.headers,
        maxFilesize: this.schema.filesize,
        previewTemplate: this.template()
      }
    };
  },
  beforeDestroy() {
    //const mydropzone =  this.$refs.myVueDropzone;
    // mydropzone.off(); //removes all listeners attached with Emitter.on()
    this.active = false;
  },
  async mounted() {
    var files = await this.schema.getFiles();
    this.$nextTick(async function() {
      var mappedfiles = files.map(function(v) {
        return { name: v.filename, size: v.length, type: "image" };
      });
      var mydropzone = this.$refs.myVueDropzone;
      var schema = this.schema;

      forEach(mappedfiles, function(file) {
        mydropzone.manuallyAddFile(
          file,
          schema.getFileUrl.replace("$filename", file.name)
        );
      });
      this.model[this.schema.model] = mappedfiles.map(function(v) {
        return v.name;
      });
      if (mappedfiles.length >0){
          this.$nextTick(async function() {
            //@ts-ignore
         document.getElementById('dropzone-'+this.getFieldID(this.schema)).append(document.getElementById('addbutton_'+this.getFieldID(this.schema)));
        });
      }
    });
    if (this.model[this.schema.model] != undefined)
      this.value = this.model[this.schema.model];

  },
  methods: {
    upload: function(){
         var mydropzone = this.$refs.myVueDropzone;
         mydropzone.$el.click();
    },
    addedfile: function() {
      if (this.files.length) {

        var _i, _len;
        for (
          _i = 0, _len = this.files.length;
          _i < _len - 1;
          _i++ // -1 to exclude current file
        ) {
          if (
            this.files[_i].name === file.name &&
            this.files[_i].size === file.size
          ) {
            this.removeFile(file);
          }
        }
            this.$nextTick(async function() {
            //@ts-ignore
        document.getElementById('dropzone-'+this.getFieldID(this.schema)).append(document.getElementById('addbutton_'+this.getFieldID(this.schema)));
        });
      }
    },
    template: function() {
      return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                      <div data-dz-thumbnail-bg></div>
                  </div>
                  <div class="dz-details">
                      <div class="dz-size"><span data-dz-size></span></div>
                      <div class="dz-filename"><span data-dz-name></span></div>
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  <div class="dz-success-mark"><i class="fad fa-2x fa-check-circle fa-theme-dgv"></i></div>
                  <div class="dz-error-mark"><i class="fad fa-2x fa-close-circle fa-theme-dgv"></i></div>
               </div>`;
    },
    thumbnail: function(file, dataUrl) {
      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        return setTimeout(
          (function(_this) {
            return function() {
              return file.previewElement.classList.add("dz-image-preview");
            };
          })(this),
          1
        );
      }
    },
    uploadedThisFile(file, response) {
      this.model[
        this.schema.model
      ] = this.$refs.myVueDropzone.dropzone.files.map(function(v) {
        return v.name;
      });
      this.value = this.model[this.schema.model];
            this.$nextTick(async function() {
           //@ts-ignore
         document.getElementById('dropzone-'+this.getFieldID(this.schema)).append(document.getElementById('addbutton_'+this.getFieldID(this.schema)));
        });
    },
    removeThisFile(file, error, xhr) {
      if (this.active) {
        this.schema.RemoveFile(file.name);

        this.model[
          this.schema.model
        ] = this.$refs.myVueDropzone.dropzone.files.map(function(v) {
          return v.name;
        });

        this.value = this.model[this.schema.model];
        if (this.value.length == 0){

            this.$nextTick(async function() {
           //@ts-ignore
         document.getElementById('Parkplatz').append(document.getElementById('addbutton_'+this.getFieldID(this.schema)));
        });
        }

      }
    },
    sendingEvent(file, xhr, formData) {
      // formData.append('paramName', 'some value or other');
    },
    onChange($event) {
      if (isFunction(this.schema.onChanged)) {
        // Schema has defined onChange method.
        this.schema.onChanged.call(this, this.model, this.schema, $event, this);
      }

      this.value = this.model[this.schema.model];
    }
  }
};
</script>

<style lang="scss">
.vue-form-generator .field-input {
  .wrapper {
    width: 100%;
  }
  .helper {
    margin: auto 0.5em;
  }
}
.hidden
{
  display:none;
}

</style>
