








































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { APPMODE } from "@/services/Config";
import { Environment } from "@/services/Environment";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";
import VuePassword from "vue-password";

@Component({
  components: { MarketingMenuHeader, VuePassword },
})
export default class Login extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  // menueopen:boolean = true;
  username: string = "";
  password: string = "";
  /*
  get username(){
    return this.credentials.username;
  }

  get username(){
    return this.credentials.password;
  }*/

  public async login() {
    this.isLoading = true;
    this.error = null;

    try {
      const user: User = {
        userName: APPMODE + "_" + this.username,
        password: this.password,
        firstName: "",
        lastName: "",
        userPoolId: -1,
        language: "de",
        eMail: APPMODE + "_" + this.username,
        timeZone: "",
        id: "",
        entityId: "",
        userRoles: "",
      };
      await UserModule.Login(user);
    } catch (ex) {
      this.error = "invalid username or password";
      this.isLoading = false;
      return;
    }

    await TransactionModule.TakeOverCurrentTransaction();

    switch (UserModule.claims[0]) {
      case "CUST": {
        this.$router.push("/Customer");
        break;
      }
      case "PB": {
        this.$router.push("/pf");
        break;
      }
      case "GV": {
        this.$router.push("/GV");
        break;
      }
    }

    // this.$store.dispatch('loadCategories')
  }

  isLoginButtonDisabled() {
    return !this.username || !this.password || this.password.length < 4;
  }
}

/*
export default {
  name: 'Login',
  data () {
    return {
      input: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {
    this.$store.dispatch('loadCategories')
  },
  computed: mapState([
    'categories'
  ]),
  methods: {
    login () {
      if (this.input.username !== '' && this.input.password !== '') {
        this.$emit('authenticated', true)
        this.$router.replace({ name: 'secure' })
      } else {
        console.log('A username and password must be present')
      }
    }
  }
}
*/
