































































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";
import headlineAccount from "@shared/components/General/HeadlineAccount.vue";
import ViewProfile from "@shared/components/General/AccountParts/ViewProfile.vue";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import UserDialogMixin from "@shared/components/GV/UserDialogMixin";
import EditProfile from "@shared/components/General/AccountParts/EditProfile.vue";
import GVCategories from "@shared/components/GV/GVCategories.vue";
import { APPMODE, UserPoolMap, RoleMap, AppModes } from "@/services/Config";
@Component({
  mixins: [UserDialogMixin],
  components: {
    headlineAccount,
    ViewProfile,
    EditProfile,
    GVCategories
  }
})
export default class GVShowUser extends Mixins(UserDialogMixin) {
  public isLoading: boolean = false;
  public error: string | null = null;
  public EditMode = false;
  public get IsAuthenticated(): boolean {
    return UserModule.Authenticated;
  }

  public get Username(): string {
    return UserModule.Email;
  }

  public get IsGvApp(): boolean {
    return APPMODE == AppModes.DieGeldverleiher;
  }
  public get IsPFUser(): Boolean {
    var poolid = UserPoolMap.Admin;
    var rolefilter = RoleMap.Admin;

    poolid = UserPoolMap.Pawn;
    rolefilter = RoleMap.Pawn;

    return (
      this.userModel!.userPoolId == poolid &&
      (this.userModel!.userRoles ?? "")
        .split(",")
        .indexOf(rolefilter.toString()) >= 0
    );
  }
  public async DeleteUser() {
    // Delete user
  }

  public OpenEditor() {
    // Delete user
    this.EditMode = true;
  }

  public OpenViewer() {
    // Delete user
    this.EditMode = false;
  }
}
