




































































































import { Component, Prop, Vue, Mixins, Emit } from "vue-property-decorator";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import PrivacyNugget from "@shared/services/nuggets/PrivacyNugget";
import DocumentEdit from "@shared/components/General/AccountParts/DocumentEdit.vue";
import { Environment } from "@/services/Environment";
import { APPMODE } from "@/services/Config";
const moment = require("moment");

@Component({ components: { DocumentEdit } })
export default class ViewProfile extends Vue {
  @Prop() userModel!: User;
  @Prop() headline!: string;
  @Prop() profileModel!: Definitions.PrivacyProfile;

  moment() {
    moment.locale("de");
    return moment;
  }

  public GetCountryName(key: string) {
    return PrivacyNugget.GetCountryName(key);
  }

  public OrDefault(value: string) {
    if (value == null || value == undefined || value == "") {
      return "-";
    }
    return value;
  }

  public CleanEmail(value: string) {
    if (value.startsWith(APPMODE + "_"))
      return value.substr(APPMODE.length + 1);
    return value;
  }

  public mounted() {}
}
