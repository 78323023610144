


















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import * as ApiEnvironment from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";

import OpenAPIClientAxios from "openapi-client-axios";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";

import { ENVIRONMENT } from "@/services/Config";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";

const moment = require("moment");
import TransactionMixin from "@shared/components/TransactionEditor/TransactionMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import InquiryCheck from "./Parts/InquiryCheck.vue";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import InquiryCheckSplitView from "@shared/components/GV/Parts/InquiryCheckSplitView.vue";
import TransactionFilter from "@shared/components/General/TransactionFilter.vue";
import DateDisplay from "@shared/components/General/DateDisplay.vue";
// InquiryCheckSplitView has • inquiry data and • customer data in one single modal window
// import  InquiryCheck from './Parts/InquiryCheckSplitView.vue';

@Component({
  mixins: [TransactionMixin, TransactionListMixin],
  components: {
    InquiryCheck,
    InquiryDeleteConfirmation,
    InquiryCheckSplitView,
    TransactionFilter,
    DateDisplay,
  },
})
export default class GVListCustomerInquiries extends TransactionListMixin {
  public isLoading: boolean = false;
  public error: string | null = null;
  public transactions: Components.Schemas.Transaction[] = [];

  public get Transactions() {
    return this.transactions;
  }

  public set Transactions(value: Components.Schemas.Transaction[]) {
    this.transactions = value;
  }

  public async created() {
    if (!UserModule.Authenticated) {
      await UserModule.AnonymousLogin();
    }
    await this.Reload();
    this.timer = setInterval(this.Reload, Environment.REFRESHTIME);
  }
  public beforeDestroy() {
    clearInterval(this.timer);
  }

  public async OnDelete(id: string) {
    await this.Reload();
  }
  public async OnReleased(id: string) {
    await this.Reload();
  }
  public async OnChanged(id: string) {
    await this.Reload();
  }

  public async Reload() {
    this.Transactions = [
      ...(await TransactionNugget.GetTransactionsByState("closednok")),
      ...(await TransactionNugget.GetTransactionsByState("closedok")),
    ];
  }

  public get TransactionFields(): Array<any> {
    return [
      {
        key: "id",
        label: "Nummer",
        thClass: "th-number",
        tdClass: "td-number",
      },
      {
        key: "createDate",
        label: "Datum",
        thClass: "th-datetime",
        tdClass: "td-datetime",
        sortable: true,
      },
      {
        key: "state",
        label: "Status",
        thClass: "th-datetime",
        tdClass: "td-datetime",
        sortable: true,
      },
      {
        key: "country",
        label: "Land",
        thClass: "th-country",
        tdClass: "td-country",
        sortable: true,
      },
      {
        key: "inquirytype",
        label: "Art",
        thClass: "th-typeinquirynr",
        tdClass: "td-typeinquirynr",
        sortable: true,
      },
      {
        key: "category",
        label: "Kategorie",
        thClass: "th-category",
        tdClass: "td-category",
        sortable: true,
      },
      {
        key: "media",
        label: "Foto",
        thClass: "th-image",
        tdClass: "td-image",
      },
      {
        key: "title",
        label: "Titel",
        thClass: "th-title",
        tdClass: "td-title",
      },
      {
        key: "runtime",
        label: "Laufzeit",
        thClass: "th-term",
        tdClass: "td-term",
        sortable: true,
      },
      {
        key: "sum",
        label: "Betrag",
        thClass: "th-amount",
        tdClass: "td-amount",
        sortable: true,
      },
      {
        key: "btn-check",
        label: "",
        thClass: "th-btn-check",
        tdClass: "td-btn-check",
      },
    ];
  }

  // ---------------------- ITEMS ------------------------
}
