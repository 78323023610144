



















































import { Component, Prop, Vue,Mixins } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Environment } from '@/services/Environment'
import { TransactionModule } from '@shared/store/modules/TransactionModule'

import TransactionMixin from '@shared/components/TransactionEditor/TransactionMixin';
import { mixins } from 'vue-class-component';
import TransactionNugget from '@shared/services/nuggets/TransactionNugget';
@Component({ mixins: [TransactionMixin] })
export default class Request3 extends Mixins(TransactionMixin) {
  public isLoading: boolean = false
  public error: string | null = null;


public created (){
      this.EnableLabels = false;
}
  public mounted () {
    this.Pagenr = 2;
    this.EnableLabels = false;
  }


}
