


















































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import CompanySwitcher from "@shared/components/General/CompanySwitcher.vue";
import { UserModule } from "@shared/store/modules/UserAuthModule";


@Component({
  components: {
    CompanySwitcher
  }
})
export default class GVMaster extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  public get CustomerNew():boolean{
    return UserModule.UnconfirmedUsers;
  }
  public get IsAuthenticated(): boolean {
    return UserModule.Authenticated;
  }

  public get Username(): string {
    return UserModule.Email;
  }
  public get Company(): string {
    return UserModule.Profile.company??"";
  }
  public isMobile: boolean = false;
  public get GetStyle(): boolean {
    return this.isMobile ? true : false;
  }
  public async created() {
     
  }

  data() {
    return {
      fields: [
        {
          key: "pawnbrokername_pawnbrokeraddress",
          label: "",
          thClass: "th-pawnbroker",
          tdClass: "td-pawnbroker"
        },
        {
          key: "creditamount",
          label: "Kreditbetrag",
          thClass: "th-creditamount",
          tdClass: "td-creditamount"
        },
        {
          key: "creditinterest",
          label: "Zinsen",
          thClass: "th-creditinterest",
          tdClass: "td-creditinterest"
        },
        {
          key: "creditfees",
          label: "Gebühren",
          thClass: "th-creditfees",
          tdClass: "td-creditfees"
        },
        {
          key: "creditsum",
          label: "Gesamtkosten",
          thClass: "th-creditsum",
          tdClass: "td-creditsum"
        },
        {
          key: "btn-action",
          label: "",
          thClass: "th-btn-action",
          tdClass: "td-btn-action"
        }
      ]
       };
  }
}
