

















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";

import { UserModule } from "@shared/store/modules/UserAuthModule";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";


@Component({
  components: {
    InquiryDeleteConfirmation
  }
})
export default class PFMaster extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  public get IsAuthenticated(): boolean {
    return UserModule.Authenticated;
  }

  public get Username(): string {
    return UserModule.Email;
  }

    public get Claims(): string[] {
    return UserModule.Claims;
  }
 public get Company(): string {
    return UserModule.Profile.company??"";
  }
  public isMobile: boolean = false;
  public get GetStyle(): boolean {
    return this.isMobile ? true : false;
  }
  public mounted() {}



  data() {
    return {
      fields: [
        {
          key: "pawnbrokername_pawnbrokeraddress",
          label: "",
          thClass: "th-pawnbroker",
          tdClass: "td-pawnbroker"
        },
        {
          key: "creditamount",
          label: "Kreditbetrag",
          thClass: "th-creditamount",
          tdClass: "td-creditamount"
        },
        {
          key: "creditinterest",
          label: "Zinsen",
          thClass: "th-creditinterest",
          tdClass: "td-creditinterest"
        },
        {
          key: "creditfees",
          label: "Gebühren",
          thClass: "th-creditfees",
          tdClass: "td-creditfees"
        },
        {
          key: "creditsum",
          label: "Gesamtkosten",
          thClass: "th-creditsum",
          tdClass: "td-creditsum"
        },
        {
          key: "btn-action",
          label: "",
          thClass: "th-btn-action",
          tdClass: "td-btn-action"
        }
      ],
      // ---------------------- ITEMS ------------------------

    };
  }
}
