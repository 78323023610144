










































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";


import PFRequestsList from "./PFRequestsList.vue";

@Component({
  components: {
    PFRequestsList,
  }
})
export default class PFDashboard extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  public mounted() {}
}
