


































































































































































































































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit,
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";
import OfferAcceptedView from "@shared/components/Customer/Parts/OfferAcceptedView.vue";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { totalSum, totalInterest, totalFees } from "@shared/CalculateTotal";
const moment = require("moment");
import { APPMODE, AppModes } from "@/services/Config";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import Headline from "@shared/components/General/Headline.vue";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
import { UserModule } from "@shared/store/modules/UserAuthModule";
@Component({
  mixins: [ModalMixin, TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    TransactionDisplayHeadline,
    Headline,
  },
})
export default class CustomerTop5Offers extends Mixins(
  ModalMixin,
  TransactionListMixin,
  TransactionMixin,
  OfferAcceptedView
) {
  showdetails = false;
  BackupTransactionModel: any = null;
  public HasSelectedOffer(value: Components.Schemas.Transaction) {
    return value.responses.filter((x) => x.offerstate == "selected").length;
  }

  public IsUserConfirmed(customData: any) {
    return PrivacyNugget.IsUserConfirmed(customData);
  }

  public congratclosed() {
    this.showdetails = true;
    this.$router.push({
      name: "acceptedtransaction",
      params: { id: this.transactionModel.id },
    });
  }
  public IsUserComplete() {
    return PrivacyNugget.IsProfileCompleted(UserModule.Profile);
  }
  public async OnActivated() {
    this.offers.forEach((x) => {
      x.selectoffer = "";
    });
    this.offers = await this.OffersAsyncVM(this.transactionModel);
    this.profileReleased = this.IsUserConfirmed(
      await TransactionNugget.GetCustomData(UserModule.UserEntityId)
    );
    TransactionModule.SET_TRANSACTION(this.transactionModel);
    TransactionModule.SET_TRANSACTIONMODE(
      this.transactionModel.transactionMode
    );
    TransactionModule.SET_RUNTIME(Number(this.transactionModel.period));
    TransactionModule.SET_SUM(this.transactionModel.summwishedfor);
    if (this.transactionModel.transactionMode.toLowerCase() != "pawn") {
      this.fields = [
        {
          key: "offernr_pawnbrokerlocation",
          label: "",
          thClass: "th-offernr_pawnbrokerlocation",
          tdClass: "td-offernr_pawnbrokerlocation",
        },
        {
          key: "creditsum",
          label: "Gebot",
          thClass: "th-creditsum",
          tdClass: "td-creditsum",
        },
        {
          key: "selectoffer",
          label: "",
          thClass: "th-selectoffer",
          tdClass: "td-selectoffer",
        },
      ];
    }
  }

  public profileReleased: boolean = true;
  @Emit("transactionbidselected")
  public Done(id: string) {
    this.accepted = false;
  }

  public offers: Array<any> = [];
  public validationError: string = "";
  public accepted: boolean = false;

  public selectedTransactionModel: Components.Schemas.Transaction | null = null;

  public UpdateSelected(offer) {
    var unselectList = this.offers.filter(
      (x) => x.selectoffer == "accepted" && x.id != offer.id
    );
    unselectList.forEach((x) => {
      x.selectoffer = "";
    });
  }

  public async SelectOffer() {
    this.isLoading = true;

    const selectedOffers = this.offers.filter(
      (x) => x.selectoffer == "accepted"
    );
    if (selectedOffers.length == 1) {
      try {
        const selectedOffer = selectedOffers[0];
        await TransactionNugget.SelectBid(
          this.transactionModel.id,
          selectedOffer.id
        );

        //   selectedOffer.selectoffer = "selected";
        this.selectedTransactionModel = await TransactionNugget.GetTransaction(
          this.transactionModel.id
        );
        this.accepted = true;
        sessionStorage.setItem("accptedTransaction", this.transactionModel.id);
      } catch (e) {
        this.error =
          "Das Angebot konnte leider nicht angenommen werden, Bitte probiere es etwas später erneut."!;
        console.log(e);
      }
      this.isLoading = false;

      //  this.CloseModal();
    }
  }

  public IsValid(): boolean {
    const selectedOffers = this.offers.filter(
      (x) => x.selectoffer == "accepted"
    );

    if (selectedOffers.length != 1) {
      const MSG = "Bitte triff eine Auswahl";
      if (this.validationError != MSG) this.validationError = MSG;
      return false;
    }
    if (this.validationError != "") this.validationError = "";
    return true;
  }

  public async OffersAsyncVM(transaction: Components.Schemas.Transaction) {
    if (transaction == undefined) return [];
    if (this.offers.length > 0) return this.offers;

    var filtered = transaction.responses.filter((x) => {
      return x.offerstate != "deleted";
    });
    var rownr: number = 0;
    if (transaction.transactionMode.toLowerCase() == "pawn") {
      filtered = filtered.sort((a, b) => {
        return (
          (Number(a.values["kreditbetrag"]) / 100) *
            Number(a.values["zinsen"]) +
          (Number(a.values["kreditbetrag"]) / 100) *
            Number(a.values["gebühren"]) +
          Number(a.values["kreditbetrag"]) -
          ((Number(b.values["kreditbetrag"]) / 100) *
            Number(b.values["zinsen"]) +
            (Number(b.values["kreditbetrag"]) / 100) *
              Number(b.values["gebühren"]) +
            Number(b.values["kreditbetrag"]))
        );
      });
    } else {
      filtered = filtered.sort((a, b) => {
        return (
          Number(b.values["kreditbetrag"]) - Number(a.values["kreditbetrag"])
        );
      });
    }
    for (var i = 1; i < filtered.length + 1; i++) {
      (<any>filtered[i - 1]).sortOrder = i;
    }

    var result = await Promise.all(
      filtered.map(async (v) => {
        rownr++;
        return {
          id: v.id,
          offernr_pawnbrokerlocation: {
            offernr: (<any>v).sortOrder.toString(),
          },
          creditamount: v.values["kreditbetrag"],
          creditinterest: {
            creditinterest: totalInterest(
              Number(v.values["kreditbetrag"]),
              Number(v.values["zinsen"]),
              Number(transaction.period)
            ),
            creditinterestpercent: v.values["zinsen"],
          },
          creditfees: totalFees(
            Number(v.values["kreditbetrag"]),
            Number(v.values["gebühren"]),
            Number(transaction.period)
          ),
          creditsum: totalSum(
            Number(v.values["kreditbetrag"]),
            Number(v.values["zinsen"]),
            Number(v.values["gebühren"]),
            Number(transaction.period)
          ),
          selectoffer: "",
        };
      })
    );

    result = result.splice(0, 5);

    this.offers = result;

    return result;
  }

  public checked_custome_contact: boolean = false;

  public fields: Array<any> = [
    {
      key: "offernr_pawnbrokerlocation",
      label: "",
      thClass: "th-offernr_pawnbrokerlocation",
      tdClass: "td-offernr_pawnbrokerlocation",
    },
    {
      key: "creditamount",
      label: "Kreditbetrag",
      thClass: "th-creditamount",
      tdClass: "td-creditamount",
    },
    {
      key: "creditinterest",
      label: "Zinsen",
      thClass: "th-creditinterest",
      tdClass: "td-creditinterest",
    },
    {
      key: "creditfees",
      label: "Gebühren",
      thClass: "th-creditfees",
      tdClass: "td-creditfees",
    },
    {
      key: "creditsum",
      label: "Gesamtkosten",
      thClass: "th-creditsum",
      tdClass: "td-creditsum",
    },
    {
      key: "selectoffer",
      label: "",
      thClass: "th-selectoffer",
      tdClass: "td-selectoffer",
    },
  ];
  // ---------------------- ITEMS ------------------------
}
