


















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import * as ApiEnvironment from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";

import OpenAPIClientAxios from "openapi-client-axios";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { ENVIRONMENT, APPMODE, AppModes } from "@/services/Config";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Environment } from "@/services/Environment";
import { filter, sumBy } from "lodash";
import CountryPostalCodes from "@shared/components/Pfandleiher/CountryPostalCodes.vue";
import moment from "moment";
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";

@Component({ components: { CountryPostalCodes } })
export default class PFCategories extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  moment() {
    moment.locale("de");
    return moment;
  }

  protected ModeSelf: Boolean = true;
  public categories: Components.Schemas.Category[] | null = null;

  public myCategories: Components.Schemas.Category[] | null = null;
  public otherCategories: Components.Schemas.Category[] | null = null;
  @Prop({ default: <any>{ entityId: UserModule.UserEntityId } })
  public userModel!: User;
  public Countries: Array<string> = [];
  public PLZ: Array<string> = [];
  public Costs: number = 0;

  public get IsPawnBroker(): boolean {
    const claims = UserModule.Claims[0];
    return claims == "PB";
  }

  public get IsPawnApp(): boolean {
    return APPMODE == AppModes.Pfandlädchen;
  }

  public CalcCostTotal(): number {
    var sum = 0;
    if (this.myCategories == null) return 0;
    sum += this.myCategories.filter(m => m["checked"] == true).length * 40;
    if (this.otherCategories == null) return sum;
    sum += this.otherCategories!.filter(m => m["checked"] == true).length * 40;
    return sum;
  }

  public get MyCategories(): Components.Schemas.Category[] | null {
    const result = this.AddChecked(this.myCategories ?? [], null);
    this.$nextTick(() => {
      this.Costs = this.CalcCostTotal();
    });
    return result;
  }
  public get OtherCategories(): Components.Schemas.Category[] | null {
    if (this.otherCategories == null && this.myCategories != null) {
      if (this.categories == null || this.myCategories == null) return [];
      var results = this.categories.filter(
        m => this.myCategories!.filter(i => i.id == m.id).length == 0
      );
      this.otherCategories = this.AddChecked(results, false);
    }
    this.$nextTick(() => {
      this.Costs = this.CalcCostTotal();
    });
    if (this.otherCategories == null && this.myCategories == null) {
      return this.categories != null ? this.categories : [];
    }

    return this.otherCategories;
  }

  public AddChecked(
    list: Array<any>,
    defaultval: boolean | null = null
  ): Array<any> {
    return list.map(m => {
      const subscription = m["categorySubscription"];
      if (subscription?.canceledAt == null) {
        m["checked"] = defaultval ?? true;
      } else {
        m["checked"] = defaultval ?? false;
      }
      return m;
    });
  }

  public async mounted() {
    if (this.ModeSelf) {
      TransactionModule.SearchCategories().then(M => {
        this.categories = TransactionModule.Categories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
      TransactionModule.SearchMyCategories().then(m => {
        this.myCategories = TransactionModule.MyCategories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
    } else {
      TransactionModule.SearchCategories().then(M => {
        this.categories = TransactionModule.Categories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
      TransactionModule.SearchMyCategories(this.userModel.entityId).then(m => {
        this.myCategories = TransactionModule.MyCategories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      });
      this.PLZ = UserModule.CustomData.postalcodes ?? [];
    }
    this.Countries = UserModule.CustomData.countries ?? [];
  }

  public async CheckedChange(item) {
    item.checked = !item.checked
    if (item.checked == false) {
      await TransactionNugget.RemoveMyCategory(
        item.id,
        this.userModel.entityId
      );
    } else {
      await TransactionNugget.SetMyCategory(item.id, this.userModel.entityId);
    }
    await TransactionModule.SearchMyCategories(this.userModel.entityId);
    this.myCategories = TransactionModule.MyCategories;
    this.Costs = this.CalcCostTotal();
    this.$forceUpdate();
  }

  public OrderCheckedChange(item) {
    this.$nextTick(() => {
      this.Costs = this.CalcCostTotal();
    });
  }

  public async Order() {
    this.isLoading = true;
    var newcats = this.OtherCategories!.filter(m => m["checked"] == true);
    for (var i = 0; i < newcats.length; i++) {
      await TransactionNugget.SetMyCategory(
        newcats[i].id,
        this.userModel.entityId
      );
    }

    await TransactionModule.SearchMyCategories(this.userModel.entityId);
    this.myCategories = TransactionModule.MyCategories;
    this.otherCategories = null;
    this.isLoading = false;
  }
}
