



































































































































































































































import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit,
} from "vue-property-decorator";
import ModalMixin from "@shared/components/General/ModalMixin";
import TransactionListMixin from "@shared/components/TransactionEditor/TransactionListMixin";

import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
const moment = require("moment");
import { totalSum, totalInterest, totalFees } from "@shared/CalculateTotal";
import InquiryDeleteConfirmation from "@shared/components/General/InquiryDeleteConfirmation.vue";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import TransactionMixin from "../../TransactionEditor/TransactionMixin";
import TransactionDisplayHeadline from "@shared/components/General/TransactionDisplayHeadline.vue";
import TransactionDisplay from "@shared/components/General/TransactionDisplay.vue";
import Headline from "@shared/components/General/Headline.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BTextInputWithValidation from "@shared/components/Inputs/BTextInputWithValidation.vue";

@Component({
  mixins: [ModalMixin, TransactionListMixin, TransactionMixin],
  components: {
    InquiryDeleteConfirmation,
    TransactionDisplay,
    TransactionDisplayHeadline,
    BTextInputWithValidation,
    ValidationProvider,
    ValidationObserver,
    Headline,
  },
})
export default class PFOfferBid extends Mixins(
  ModalMixin,
  TransactionListMixin,
  TransactionMixin
) {
  private sumWishedFor: number = this.transactionModel.summwishedfor;
  private interest: number = 0;
  private cost: number = 0;
  public get Cost() {
    return this.cost;
  }

  public set Cost(value: number) {
    this.cost = value;
  }

  public get Interest() {
    return this.interest;
  }

  public set Interest(value: number) {
    this.interest = value;
  }

  public get SumWishedFor() {
    return this.sumWishedFor;
  }

  public set SumWishedFor(value: number) {
    this.sumWishedFor = value;
  }

  public get AbsInterest(): number {
    // Zinsen auf Monatsbasis * Monate
    return totalInterest(
      Number(this.sumWishedFor),
      Number(this.Interest),
      Number(this.transactionModel.period)
    );
  }

  public get AbsFee(): number {
    // Zinsen auf Monatsbasis * Monate
    return totalFees(
      Number(this.sumWishedFor),
      Number(this.Cost),
      Number(this.transactionModel.period)
    );
  }

  public get TotalSum(): number {
    //Kreditbetrag + Gebühren * Monate + Zinsen auf Monatsbasis * Monate
    return (
      //Number(this.SumWishedFor)
      totalSum(
        this.SumWishedFor,
        this.interest,
        this.Cost,
        Number(this.transactionModel.period)
      )
    );
  }

  public async OnActivated() {
    console.log(this.transactionModel);
    TransactionModule.SET_TRANSACTION(this.transactionModel);
    TransactionModule.SET_TRANSACTIONMODE(
      this.transactionModel.transactionMode
    );
    TransactionModule.SET_RUNTIME(Number(this.transactionModel.period));
    TransactionModule.SET_SUM(this.transactionModel.summwishedfor);
  }

  public created() {}

  @Emit("transactionChanged")
  public FireChanged(id: string) {}

  public async Save() {
    const isvalid = await (<any>this.$refs.observer).validate();
    if (isvalid == true) {
      this.isLoading = true;
      try {
        //await TransactionNugget.TransitionTransactionState(this.transactionModel,'lenderstimeout');
        await TransactionNugget.CreateBid(
          this.transactionModel.id,
          this.SumWishedFor,
          this.Interest,
          this.Cost
        );

        this.FireChanged(this.transactionModel.id);
        this.isLoading = false;
        this.CloseModal();
      } catch (e) {
        this.error =
          "Es ist ein Fehler aufgetreten. Bitte probiere es später erneut.";
      } finally {
        this.isLoading = false;
      }
    }
  }
}
