































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";

import CustomerInquiryCorrection from "./Parts/CustomerInquiryCorrection.vue";
import RequestList from "./RequestList.vue";
import FeedbackList from "./FeedbackList.vue";
import ArchivList from "./ArchivList.vue";
import OfferList from "./OfferList.vue";

@Component({
  components: {
    OfferList,
    RequestList,
    ArchivList,
    FeedbackList,
    CustomerInquiryCorrection
  }
})
export default class CustomerOverview extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  public componentKey : number = 0;

  public mounted() {}

  public OnReloadArchivList(id:string){
    (<any>this.$refs.Archiv).Reload();

  }

}
