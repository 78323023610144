








































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { APPMODE,AppModes } from '@/services/Config';
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget
} from "@shared/services/nuggets/ManagementNugget";
import headlineAccount from "@shared/components/General/HeadlineAccount.vue";
import ViewProfile from "@shared/components/General/AccountParts/ViewProfile.vue";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import UserDialogMixin from "@shared/components/GV/UserDialogMixin";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";

import PrivacyNugget, {
  CustomData
} from "@shared/services/nuggets/PrivacyNugget";
const moment = require("moment");

@Component({
  mixins:[
UserDialogMixin
  ],
  components: {
    headlineAccount,
    ViewProfile
  }
})
export default class GVEditPfandleiher extends Mixins(UserDialogMixin) {

  public async SendFeedback() {
    console.log(this.userModel);
    await TransactionNugget.UpdateCustomData(this.userModel!.entityId, this.customData);
    this.$router.push("/GV/gvlistusers");
  }

  public async Release() {

    if (this.customData != null){
    this.customData.lastVerifyDate = moment.utc().toISOString();
    }
    await TransactionNugget.UpdateCustomData(this.userModel!.entityId, this.customData);
    this.$router.push("/GV/gvlistusers");
  }





}
