





































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import UserFilter from "@shared/components/General/UserFilter.vue";
import { UserFilterSettings } from "@shared/components/General/UserFilterSettings";
import UserDeleteConfirmation from "@shared/components/General/AccountParts/UserDeleteConfirmation.vue";
import {
  User,
  UserAndProfile,
  ProfileDto,
  ManagementNugget,
} from "@shared/services/nuggets/ManagementNugget";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { APPMODE, UserPoolMap, RoleMap, AppModes } from "@/services/Config";
const moment = require("moment");

@Component({
  components: {
    UserFilter,
    UserDeleteConfirmation,
  },
})
export default class GVListUsers extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;
  private users: any = [];

  public CurrentFilterSettings: UserFilterSettings = new UserFilterSettings();

  private tabIndex = 0;
  private tabs: Array<string> = ["#neu", "#kunden", "pfandleiher", "#user"];
  public get Users(): Array<any> {
    return this.users;
  }
  public set Users(value: Array<any>) {
    this.users = value;
  }

  private profiles: any = [];

  public get Profiles(): Array<any> {
    return this.profiles;
  }
  public set Profiles(value: Array<any>) {
    this.profiles = value;
  }

  public get IsAuthenticated(): boolean {
    return UserModule.Authenticated;
  }

  public get Username(): string {
    return UserModule.Email;
  }
  public get Company(): string {
    return "Golending";
  }
  moment() {
    moment.locale("de");
    return moment;
  }

  public isMobile: boolean = false;
  public get GetStyle(): boolean {
    return this.isMobile ? true : false;
  }

  public IsUserConfirmed(customData: any) {
    return PrivacyNugget.IsUserConfirmed(customData);
  }

  public PFAppMode() {
    return APPMODE != AppModes.Golending;
  }

  public SaveTab(i: number) {
    sessionStorage.setItem("listusertabindex", i.toString());
  }
  public async mounted() {
    this.tabIndex = Number(sessionStorage.getItem("listusertabindex") ?? 0);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (!UserModule.Authenticated) {
      await UserModule.AnonymousLogin();
    }
    await this.Reload();
  }

  public async Reload() {
    var userslist = (await ManagementNugget.GetUsers()).filter(
      (x) => x.lockedDate == null
    );
    var profiles = await TransactionNugget.GetSafes(
      userslist.map((x) => x.entityId)
    );
    /* const emptycustomData = {
  Categories: [],
  lastVerifyDate: (new Date().toISOString()),
  Feedback: ""
};*/
    /*profiles.forEach(element => {
        TransactionNugget.UpdateCustomData(element.email,emptycustomData)
    });*/

    this.Profiles = profiles;
    this.Users = userslist;
    //   this.Transactions = await TransactionNugget.GetTransactionsByState("new");
  }

  public GetUserVM(v: User, filter: string): any {
    var obj: any = {};
    var profile = this.Profiles.filter((x) => x.entityId == v.entityId)[0];

    obj["btnaction"] = { email: v.userName, id: v.id, userObj: v };
    obj[filter + "number"] = v.id;
    obj[filter + "fname"] = profile?.firstname ?? v.firstName;
    obj[filter + "lname"] = profile?.lastname ?? v.lastName;
    var customdata = this.Profiles.filter((p) => p.entityId == v.entityId)[0]
      ?.customdata;
    obj[filter + "email"] =
      customdata?.email ??
      (v.userName.indexOf(APPMODE + "_") >= 0
        ? v.userName.substr(APPMODE.length + 1)
        : v.userName);
    obj[filter + "company"] = profile?.company ?? "-";
    var addr = {};
    addr[filter + "zip"] = profile?.postalcode ?? "";
    addr[filter + "city"] = profile?.city ?? "";
    obj[filter + "zip_" + filter + "city"] = addr;

    obj[filter + "country"] =
      profile?.country == null
        ? ""
        : Environment.CountryOptions.filter(
            (m) => m.value == profile?.country
          )[0]?.text ?? "-";

    return obj;
  }

  public UsersVM(users: User[], filter: string): any {
    if (users == undefined) return [];
    var poolid = UserPoolMap.Admin;
    var rolefilter = RoleMap.Admin;

    if (filter == "pawnbroker") {
      poolid = UserPoolMap.Pawn;
      rolefilter = RoleMap.Pawn;
    }
    if (filter == "customer") {
      poolid = UserPoolMap.User;
      rolefilter = RoleMap.User;
    }
    if (filter == "customernew") {
      poolid = UserPoolMap.User;
      rolefilter = RoleMap.User;
    }

    if (this.CurrentFilterSettings.SearchPhrase != "") {
      users = users.filter(
        (x) =>
          (x.eMail?.toLowerCase() ?? "").indexOf(
            this.CurrentFilterSettings.SearchPhrase.toLowerCase()
          ) >= 0
      );
    }

    if (filter == "customernew") {
      return users
        .filter((x) => {
          var profile = this.Profiles.filter(
            (p) => p.entityId == x.entityId
          )[0];
          return !this.IsUserConfirmed(profile?.customData);
        })
        .filter(
          (x) =>
            x.userPoolId == poolid &&
            (x.userRoles ?? "").split(",").indexOf(rolefilter.toString()) >= 0
        )
        .map((x) => this.GetUserVM(x, filter));
    } else {
      return users
        .filter((x) => {
          if (
            (x.userRoles ?? "").split(",").indexOf(RoleMap.User.toString()) >= 0
          ) {
            var profile = this.Profiles.filter(
              (p) => p.entityId == x.entityId
            )[0];
            return this.IsUserConfirmed(profile?.customData);
          }
          return true;
        })
        .filter((x) => {
          return (
            x.userPoolId == poolid &&
            (x.userRoles ?? "").split(",").indexOf(rolefilter.toString()) >= 0
          );
        })
        .map((x) => this.GetUserVM(x, filter));
    }
  }

  public destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  public handleResize(): void {
    this.isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
  }

  // ---------------------- ITEMS ------------------------

  private fields_customernew: Array<any> = [
    {
      key: "customernewnumber",
      label: "#",
      thClass: "th-customernewnumber",
      tdClass: "td-customernewnumber",
      sortable: true,
    },
    {
      key: "customernewfname",
      label: "Vorname",
      thClass: "th-customernewfname",
      tdClass: "td-customernewfname",
      sortable: true,
    },
    {
      key: "customernewlname",
      label: "Nachname",
      thClass: "th-customernewlname",
      tdClass: "td-customernewlname",
      sortable: true,
    },
    {
      key: "customernewemail",
      label: "E-Mail",
      thClass: "th-customernewemail",
      tdClass: "td-customernewemail",
      sortable: true,
    },
    {
      key: "customernewcompany",
      label: "Firma",
      thClass: "th-customernewcompany",
      tdClass: "td-customernewcompany",
      sortable: true,
    },
    {
      key: "customernewzip_customernewcity",
      label: "Stadt",
      thClass: "th-customernewzip-customernewcity",
      tdClass: "td-customernewzip-customernewcity",
      sortable: true,
    },
    {
      key: "customernewcountry",
      label: "Land",
      thClass: "th-customernewcountry",
      tdClass: "td-customernewcountry",
      sortable: true,
    },
    {
      key: "btnaction",
      label: "",
      thClass: "th-btn-action",
      tdClass: "td-btn-action",
    },
  ];

  private fields_customer: Array<any> = [
    {
      key: "customernumber",
      label: "#",
      thClass: "th-customernumber",
      tdClass: "td-customernumber",
    },
    {
      key: "customerfname",
      label: "Vorname",
      thClass: "th-customerfname",
      tdClass: "td-customerfname",
    },
    {
      key: "customerlname",
      label: "Nachname",
      thClass: "th-customerlname",
      tdClass: "td-customerlname",
    },
    {
      key: "customeremail",
      label: "E-Mail",
      thClass: "th-customeremail",
      tdClass: "td-customeremail",
    },
    {
      key: "customercompany",
      label: "Firma",
      thClass: "th-customercompany",
      tdClass: "td-customercompany",
    },
    {
      key: "customerzip_customercity",
      label: "Stadt",
      thClass: "th-customerzip-customercity",
      tdClass: "td-customerzip-customercity",
    },
    {
      key: "customercountry",
      label: "Land",
      thClass: "th-customercountry",
      tdClass: "td-customercountry",
    },
    {
      key: "btnaction",
      label: "",
      thClass: "th-btn-action",
      tdClass: "td-btn-action",
    },
  ];

  private fields_pawnbroker: Array<any> = [
    {
      key: "pawnbrokernumber",
      label: "#",
      thClass: "th-pawnbrokernumber",
      tdClass: "td-pawnbrokernumber",
    },
    {
      key: "pawnbrokerfname",
      label: "Vorname",
      thClass: "th-pawnbrokerfname",
      tdClass: "td-pawnbrokerfname",
    },
    {
      key: "pawnbrokerlname",
      label: "Nachname",
      thClass: "th-pawnbrokerlname",
      tdClass: "td-pawnbrokerlname",
    },
    {
      key: "pawnbrokeremail",
      label: "E-Mail",
      thClass: "th-pawnbrokeremail",
      tdClass: "td-pawnbrokeremail",
    },
    {
      key: "pawnbrokercompany",
      label: "Firma",
      thClass: "th-pawnbrokercompany",
      tdClass: "td-pawnbrokercompany",
    },
    {
      key: "pawnbrokerzip_pawnbrokercity",
      label: "Stadt",
      thClass: "th-pawnbrokerzip-pawnbrokercity",
      tdClass: "td-pawnbrokerzip-pawnbrokercity",
    },
    {
      key: "pawnbrokercountry",
      label: "Land",
      thClass: "th-pawnbrokercountry",
      tdClass: "td-pawnbrokercountry",
    },
    {
      key: "btnaction",
      label: "",
      thClass: "th-btn-action",
      tdClass: "td-btn-action",
    },
  ];

  private fields_inhouse: Array<any> = [
    {
      key: "fields_inhousenumber",
      label: "#",
      thClass: "th-inhousenumber",
      tdClass: "td-inhousenumber",
    },
    {
      key: "inhousefname",
      label: "Vorname",
      thClass: "th-inhousefname",
      tdClass: "td-inhousefname",
    },
    {
      key: "inhouselname",
      label: "Nachname",
      thClass: "th-inhouselname",
      tdClass: "td-inhouselname",
    },
    {
      key: "inhouseemail",
      label: "E-Mail",
      thClass: "th-inhouseemail",
      tdClass: "td-inhouseemail",
    },
    {
      key: "inhousecompany",
      label: "Firma",
      thClass: "th-inhousecompany",
      tdClass: "td-inhousecompany",
    },
    {
      key: "inhousezip_inhousecity",
      label: "Stadt",
      thClass: "th-inhousezip-inhousecity",
      tdClass: "td-inhousezip-inhousecity",
    },
    {
      key: "inhousecountry",
      label: "Land",
      thClass: "th-inhousecountry",
      tdClass: "td-inhousecountry",
    },
    {
      key: "btnaction",
      label: "",
      thClass: "th-btn-action",
      tdClass: "td-btn-action",
    },
  ];
}
