import Vue from 'vue'
import Vuex from 'vuex'
import { IUserState,UserModule } from './modules/UserAuthModule'

import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { ITransactionState, TransactionModule } from './modules/TransactionModule'
import { ILanguageState,LanguageModule } from './modules/LanguageModule'

import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)
export interface IRootState {
  user: IUserState,
  transaction: ITransactionState,
  language:ILanguageState
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({ }) //plugins: [createPersistedState()]
