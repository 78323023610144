var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"mymodal",attrs:{"id":"modalCongratulations","size":"","centered":"","modal-class":"modal-message"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"modal__title-col",attrs:{"sm":"11"}},[_vm._v("Nachricht")]),_c('b-col',{staticClass:"modal__close-col",attrs:{"sm":"1"}},[_c('div',{staticClass:"modal__close"})])],1)],1)]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{attrs:{"src":"/animation-envelope.gif","width":"200px"}})]),_c('div',[_c('p',[_vm._v("Herzlichen Glückwunsch zum ausgewählten Angebot, der Pfandleiher wird sich in kürze mit Ihnen in Verbindung setzen.")])])])],1)],1)]}},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"modal-footer__buttonbar"},[_c('b-button',{attrs:{"variant":"congratulations"},on:{"click":function($event){return _vm.closeme()}}},[_c('i',{staticClass:"fa fa-lg fa-times"}),_vm._v("Schließen ")])],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }