






























import { Component, Prop, Vue, Model, Mixins } from "vue-property-decorator";
import { Client, Components } from "@shared/services/nuggets/TransactionSvc";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { UserFilterSettings } from '@shared/components/General/UserFilterSettings'
import { User } from '@shared/services/nuggets/ManagementNugget';


@Component({})
export default class UserFilter extends Vue {
  @Prop() allusers!: Array<User>;

  @Prop() Settings!: UserFilterSettings;


  private phrase:string = '';




  public ClearFilters() {
    this.Settings.SearchPhrase = '';
    this.phrase = '';

  }
  public DoSearch(){
     this.Settings.SearchPhrase =  this.phrase;
  }

  public async mounted() {
  }
}
