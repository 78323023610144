import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
//import {CloudNuggetAuthenticationService,TokenData,SubClaim,Claim  } from '@verticals/cloudnugget.frontend.js' //'../../../../../../cloudnuggets/Frontend/Client Libraries/CloudNugget.JS/lib/bin/lib/cloud-nugget-authentication-service'
import store from "@shared/store";
import { AxiosResponse } from "axios";
import axios from "axios";
// import router from '@app/router'

import { CloudNuggetService } from "@verticals/cloudnugget.frontend.js/bin/lib/cloud-nugget-service";
import { CloudNuggetAuthenticationService } from "@verticals/cloudnugget.frontend.js/bin/lib/cloud-nugget-authentication-service";
import {
  TokenData,
  Claim,
} from "@verticals/cloudnugget.frontend.js/bin/lib/data/token-data";
import {
  ENVIRONMENT,
  APPMODE,
  RoleMap,
  UserPoolMap,
  APPENTITYID,
  CN_TENANT,
  CN_ANONYMOUSUSERNAME,
  CN_ANONYMOUSPASSWORD,
} from "@/services/Config";
import * as ApiEnvironment from "@/services/Environment";
import {
  User,
  ProfileDto,
  ManagementNugget,
  UserChangePassword,
} from "@shared/services/nuggets/ManagementNugget";
import PrivacyNugget, {
  CustomData,
} from "@shared/services/nuggets/PrivacyNugget";
import { Definitions } from "@shared/services/nuggets/PrivacySvc";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import router from "@/router";
import { config } from "process";

/*
import { CloudNuggetService } from 'C:/Projekte/Vertical-Codes/cloudnuggets/Frontend/Client Libraries/CloudNugget.JS/lib/src/lib/cloud-nugget-service'
import { CloudNuggetAuthenticationService } from 'C:/Projekte/Vertical-Codes/cloudnuggets/Frontend/Client Libraries/CloudNugget.JS/lib/src/lib/cloud-nugget-authentication-service'
import { TokenData,Claim } from 'C:/Projekte/Vertical-Codes/cloudnuggets/Frontend/Client Libraries/CloudNugget.JS/lib/src/lib/data/token-data'
*/
export interface IUserState {
  token: string;
  avatar: string;
  claims: string[];
  email: string;
}

@Module({ dynamic: true, store, name: "user" })
class UserAuthModule extends VuexModule implements IUserState {
  token: string =
    ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNToken") ?? "";
  private name: string =
    ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNUsername") ?? "";
  public avatar: string = "";
  public claims: string[] = <string[]>(
    JSON.parse(
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNClaims") ?? "[]"
    )
  );
  public email: string =
    ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNEmail") ?? "";
  public isAnonymous: boolean = <boolean>(
    JSON.parse(
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNIsAnonymous") ??
        "false"
    )
  );
  public profile: Definitions.PrivacyProfile = <Definitions.PrivacyProfile>(
    JSON.parse(
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNProfile") ?? "{}"
    )
  );
  public userEntityId: string =
    ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNUserEntityId") ?? "";
  private unconfirmedUsers: boolean = false;
  public customData: CustomData = <CustomData>(
    JSON.parse(
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNCustomData") ?? "{}"
    )
  );
  public user: User = <User>(
    JSON.parse(
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("CNUser") ?? "{}"
    )
  );
  public users: User[] = [];
  public get UnconfirmedUsers(): boolean {
    return this.unconfirmedUsers;
  }

  public get Token(): string {
    return this.token;
  }
  public get UserEntityId(): string {
    return this.userEntityId;
  }

  public get ProvisionUserID(): string | undefined {
    return (<any>window).provisionUserID;
  }

  public get Email(): string {
    const mode = APPMODE;
    return this.email.indexOf(mode + "_") >= 0
      ? this.email.substr((mode + "_").length)
      : this.email;
  }

  public get CNUser(): User {
    return this.user;
  }
  public get Profile(): Definitions.PrivacyProfile {
    return this.profile;
  }

  public get CustomData(): CustomData {
    return this.customData;
  }
  public get Authenticated(): boolean {
    return this.Token != "" && this.Email.indexOf("anonymous") < 0;
  }

  public get Claims(): string[] {
    return this.claims;
  }

  public get IsAnonymous(): boolean {
    return this.isAnonymous;
  }

  @Mutation
  public LOAD_RESULTS({ data }: AxiosResponse<string>) {
    if (data && data.length) {
    }
  }

  @Mutation
  public SET_CNPROFILE(user: User) {
    this.user = user;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem(
      "CNUser",
      JSON.stringify(user)
    );
  }

  @Mutation
  public SET_UNCONFIRMEDUSERS(value: boolean) {
    this.unconfirmedUsers = value;
  }

  @Mutation
  public AUTH_REQUEST({ data }: AxiosResponse<string[]>) {
    if (data && data.length) {
    }
  }

  @Mutation
  public AUTH_SUCCESS(data: string) {
    if (data && data.length) {
      this.token = data;
      ApiEnvironment.Environment.TOKENSTORAGE.setItem("CNToken", data);

      // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    }
  }
  @Mutation
  public AUTH_CLEAR() {
    this.token = "";
    ApiEnvironment.Environment.TOKENSTORAGE.removeItem("CNToken");
    this.profile = {};
    this.name = "";
    this.avatar = "";
    this.claims = [];
    this.email = "";
  }
  @Mutation
  public SET_USERNAME(name: string) {
    this.name = name;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem("CNUsername", name);
  }
  @Mutation
  public SET_USERID(entityId: string) {
    const id: string = entityId.substring(6);
    this.userEntityId = id;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem("CNUserEntityId", id);
  }

  @Mutation
  public SET_EMPLOYEES(users: User[]) {
    this.users = users;
  }

  @Mutation
  public SET_PROFILE(profile: Definitions.PrivacyProfile) {
    this.profile = profile;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem(
      "CNProfile",
      JSON.stringify(profile)
    );
  }

  @Mutation
  public SET_CUSTOMDATA(data: CustomData) {
    this.customData = data;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem(
      "CNCustomData",
      JSON.stringify(data)
    );
  }

  @Mutation
  public SET_EMAIL(email: string) {
    this.email = email;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem("CNEmail", email);
  }

  @Mutation
  public SET_CLAIMS(claims: string) {
    this.claims = claims.split(",");
    ApiEnvironment.Environment.TOKENSTORAGE.setItem(
      "CNClaims",
      JSON.stringify(this.claims)
    );
  }

  @Mutation
  public SET_ANONYMOUS(isAnonymous: boolean) {
    this.isAnonymous = isAnonymous;
    ApiEnvironment.Environment.TOKENSTORAGE.setItem(
      "CNIsAnonymous",
      JSON.stringify(isAnonymous)
    );
  }

  @Mutation
  public AUTH_ERROR(data: string) {}

  @Action({ commit: "loadResults" })
  public async SearchUsers(organization: string) {
    //  return await ApiClient.getUsers({ organization: organization || '1', sort: 'name' })
  }

  @Action({ rawError: true })
  public async AnonymousLogin() {
    if (!this.Authenticated) {
      const user: User = {
        userName: APPMODE + "_" + CN_ANONYMOUSUSERNAME,
        password: CN_ANONYMOUSPASSWORD,
        firstName: "",
        lastName: "",
        userPoolId: -1,
        language: "de",
        eMail: "",
        timeZone: "",
        id: "",
        entityId: "",
        userRoles: "",
      };
      return await this.Login(user);
    }
  }

  @Action({ rawError: true })
  public async Login(user: User) {
    const username = user.userName;
    const password = user.password;
    this.AUTH_CLEAR();
    let cnservice: CloudNuggetAuthenticationService =
      new CloudNuggetAuthenticationService();
    CloudNuggetService.apiEndpoint = ApiEnvironment.Environment.CNAPI_ENDPOINT; //SetEnvironment();
    var mytoken: any | null = null;
    const cleanusername = username.substr(APPMODE.length + 1);
    try {
      mytoken = await cnservice.loginUser(
        CN_TENANT,
        APPENTITYID,
        username,
        password
      );
    } catch (ex) {
      mytoken = await cnservice.loginUser(
        CN_TENANT,
        APPENTITYID,
        cleanusername,
        password
      );
    }
    this.AUTH_SUCCESS(mytoken);
    const jwttoken = mytoken;
    const tokendata = cnservice.decodeToken(jwttoken);
    const userstring: string = tokendata.sub.substr(
      tokendata.sub.lastIndexOf("\\") + 1
    );
    PrivacyNugget.Client = null;
    TransactionNugget.Client = null;

    if (username != APPMODE + "_" + CN_ANONYMOUSUSERNAME) {
      this.SET_CNPROFILE(await ManagementNugget.GetProfile());
      this.SET_USERID(userstring);
      this.SET_PROFILE(await PrivacyNugget.GetSafe(this.UserEntityId)); //.then(m => {
      if (
        this.Profile.email == "" ||
        this.Profile.email == null ||
        this.Profile.email == undefined
      ) {
        this.Profile.email = cleanusername;
        await PrivacyNugget.UpdateSafe(this.UserEntityId, this.Profile);
      }
    }
    this.SET_USERNAME(username);
    this.SET_EMAIL(username);
    this.SET_CLAIMS(tokendata["cn-claims"]);
    this.SET_ANONYMOUS(username == CN_ANONYMOUSUSERNAME);
    if (this.claims.includes("GV")) {
      await this.InitGVWatchers();
    }

    if (
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("deeplink") !=
        undefined &&
      ApiEnvironment.Environment.TOKENSTORAGE.getItem("deeplink") != null
    ) {
      const to = JSON.parse(
        ApiEnvironment.Environment.TOKENSTORAGE.getItem("deeplink") ?? ""
      );
      ApiEnvironment.Environment.TOKENSTORAGE.removeItem("deeplink");
      router.push({ name: to!.name, params: to!.params, query: to!.query });
    }
  }

  @Action({ rawError: true })
  public UpdateProfile(user: Definitions.PrivacyProfile) {
    this.SET_PROFILE(user);
  }

  @Action({ rawError: true })
  public UpdateCustomData(user: CustomData) {
    this.SET_CUSTOMDATA(user);
  }

  @Action({ rawError: true })
  public async ForgotPassword(user: User) {
    const username = user.userName;
    const password = user.password;
    let cnservice: CloudNuggetAuthenticationService =
      new CloudNuggetAuthenticationService();
    CloudNuggetService.apiEndpoint = ApiEnvironment.Environment.CNAPI_ENDPOINT; //SetEnvironment();
    await TransactionNugget.ResetPassword(user.eMail);
  }

  @Action({ rawError: true })
  public async Register(user: User) {
    const username = user.userName;
    const password = user.password;
    const apiClient = axios.create({
      baseURL: ApiEnvironment.Environment.CNMGMNTAPI_ENDPOINT,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const newUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      userName: username,
      userPoolId:
        user.userPoolId == -1 || user.userPoolId == null
          ? UserPoolMap.User
          : user.userPoolId,
      email: username,
      language: "German",
      timeZone: "GMT +1",
      password: password,
    };
    try {
      const response = await TransactionNugget.Register(<any>newUser); // await apiClient.post<User>('/users', newUser);
      const user = response;
      return user;
    } catch (err) {
      console.log(err.response.data);
      throw err.response.data;
    }
  }

  @Action({ rawError: true })
  public async InitializeProfile() {
    if (this.Profile?.firstname == null) {
      await PrivacyNugget.CreateSafe(UserModule.UserEntityId);
    }
  }

  @Action
  public async InitGVWatchers() {
    const poolid = UserPoolMap.Admin;
    const rolefilter = RoleMap.Admin;

    var userslist = await ManagementNugget.GetUsers();
    var profiles = await TransactionNugget.GetSafes(
      userslist.map((x) => x.entityId)
    );
    var result =
      userslist.filter((x) => {
        var profile = profiles.filter((p) => p.entityId == x.entityId)[0];
        return !PrivacyNugget.IsUserConfirmed(profile?.customData);
      }).length > 0;

    const employees = userslist
      .filter((x) => {
        if (
          (x.userRoles ?? "").split(",").indexOf(RoleMap.Admin.toString()) >= 0
        ) {
          return true;
        }
      })
      .filter((x) => {
        return (
          x.userPoolId == poolid &&
          (x.userRoles ?? "").split(",").indexOf(rolefilter.toString()) >= 0
        );
      });

   // this.SET_UNCONFIRMEDUSERS(result);
    this.SET_EMPLOYEES(employees);
  }

  @Action({ rawError: true })
  public LoadTokenFromRoute(nav: any) {
    const to = nav.to;
    const next = nav.next;
    let cnservice: CloudNuggetAuthenticationService =
      new CloudNuggetAuthenticationService();
    CloudNuggetService.apiEndpoint = ApiEnvironment.Environment.CNAPI_ENDPOINT; //SetEnvironment();
    var mytoken = to?.params?.token;
    if (mytoken != undefined && mytoken != null && mytoken != this.token) {
      this.AUTH_SUCCESS(mytoken);

      const jwttoken = mytoken;
      ManagementNugget.GetProfile().then((user) => {
        this.SET_EMAIL(user.userName);
        this.SET_CNPROFILE(user);
        this.SET_USERNAME(user.userName);
        this.SET_ANONYMOUS(user.userName == CN_ANONYMOUSUSERNAME);
        PrivacyNugget.GetSafe(user.entityId).then((m) => this.SET_PROFILE(m));
      });
      const tokendata = cnservice.decodeToken(jwttoken);
      const userstring: string = tokendata.sub.substr(
        tokendata.sub.lastIndexOf("\\") + 1
      );
      PrivacyNugget.Client = null;
      TransactionNugget.Client = null;

      this.SET_USERID(userstring);
      this.SET_CLAIMS(tokendata["cn-claims"]);

      next({ path: to.path.replace("/Enter/Token/" + this.token, "") });
    } else {
      if (UserModule.Token != null && UserModule.Claims[0] == "GV") {
        next();
      } else {
        next("/login");
      }
    }
  }

  @Action
  public async ChangePassword(myuser: User) {
    const apiClient = axios.create({
      baseURL: ApiEnvironment.Environment.CNMGMNTAPI_ENDPOINT,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    try {
      const response = await apiClient.patch<User>(
        "/users/" + encodeURIComponent(myuser.eMail) + "/changepassword",
        myuser
      );
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }
  }

  @Action
  public async ResetPasswordFromSecret(myuser: UserChangePassword) {
    const apiClient = axios.create({
      baseURL: ApiEnvironment.Environment.CNMGMNTAPI_ENDPOINT,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    try {
      const response = await apiClient.patch<User>(
        "/users/" +
          encodeURIComponent(myuser.appEntityId) +
          "/" +
          myuser.eMail +
          "/changepassword/" +
          myuser.secret,
        myuser
      );
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }
  }

  @Action
  public async EditProfile(newvalues: ProfileDto) {}

  @Action
  public Logout() {
    this.AUTH_CLEAR();
    ApiEnvironment.Environment.TOKENSTORAGE.clear();
    /*this.customData = <any>{};
    this.profile = <any>{};
    this.user = <any>{};
*/
  }
}

export const UserModule = getModule(UserAuthModule);
