















import {
  Component,
  Prop,
  Vue,
  Model,
  Mixins,
  Emit,
} from "vue-property-decorator";
import moment from "moment";
import TimerDisplay from "./TimerDisplay.vue";
import { UserModule } from "@shared/store/modules/UserAuthModule";

@Component({
  components: { TimerDisplay },
})
export default class DateDisplay extends Vue {
  @Prop() data!: any;

  isCustomer(): Boolean {
    return UserModule.claims[0] == "CUST";
  }

  moment() {
    moment.locale("de");
    return moment;
  }
}
