import { render, staticRenderFns } from "./SelfDeleteConfirmation.vue?vue&type=template&id=52c09817&scoped=true&"
import script from "./SelfDeleteConfirmation.vue?vue&type=script&lang=ts&"
export * from "./SelfDeleteConfirmation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c09817",
  null
  
)

export default component.exports