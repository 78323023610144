import { Component, Prop, Vue } from "vue-property-decorator";
import OpenAPIClientAxios from "openapi-client-axios";
import { Client,Definitions } from "./PrivacySvc";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import * as ApiEnvironment from "@/services/Environment";
import { componentsPlugin } from 'bootstrap-vue';
import { Environment } from "@/services/Environment";
import router from '@/router/index'
import { ProfileDto } from './ManagementNugget';
const moment = require("moment");

export interface CustomData{
  categories: Array<string>,
  lastVerifyDate: string,
  feedback: string,
  email: string,
  countries: Array<string>,
  postalcodes:Array<string>
}

export default class PrivacyNugget extends Vue {

  public static Client: Client | null = null;
  public static LastEndpoint: string | null = null;

    private static async PrepareClient(endpoint: string = ApiEnvironment.Environment.CNPRIVACYAPI_ENDPOINT) {
      if (PrivacyNugget.Client == null){
        const defs = require("./Privacyservice.json");
        defs["servers"][0]["url"] = endpoint;
        PrivacyNugget.LastEndpoint = endpoint;
      const token = UserModule.Token;
      const api = new OpenAPIClientAxios({
        definition: defs,
        axiosConfigDefaults: {
          headers: {
            CloudNuggetJWTToken: token
          }
        }
      });
      //  if (api.definition.servers != undefined) {
      //  api.definition.servers[0].url = ApiEnvironment.Environment.API_ENDPOINT;
      //   }
      PrivacyNugget.Client = await api.getClient<Client>();
      PrivacyNugget.Client.interceptors.response.use(function (response) {
          return response
        }, function (error) {
          if (error?.response?.status === 401) {
            UserModule.Logout();
            PrivacyNugget.Client = null;
            router.push('/login')
          }
          return Promise.reject(error)
        });
      }
      return PrivacyNugget.Client;
    }

    public static IsUserConfirmed(customData: any | null) {
      var result: CustomData | null = null;
      if ((customData as CustomData) != null) {
        result = customData;
      } else {
        try {
          result = JSON.parse(customData ?? "{}");
        } catch (ex) {
          console.log(ex);
        }
      }
      if (customData == null) return false;

      const timestamp = moment(customData.lastVerifyDate == undefined? customData.lastVerifyDate: customData.lastVerifyDate).local();

      return timestamp >= moment().add(-1, 'years');
    }

  public static GetCountryName(key:string){
    var found = Environment.CountryOptions.filter(m=>m.value == key);
    if (found.length > 0){
      return found[0].text;
    }
    return "";
  }

  public static async GetSafeFiles(key:string,filetype:string) {
    const client = await PrivacyNugget.PrepareClient();
    const result = await client.getSafeFiles({ key,filetype });
    return result.data;
  }

  private static IsNullOrWhitespace( input:string|undefined|null ):Boolean {

    if (typeof input === 'undefined' || input == null) return true;

    return input.replace(/\s/g, '').length < 1;
}

  public static IsProfileCompleted(profile: Definitions.PrivacyProfile): Boolean{
    return !(PrivacyNugget.IsNullOrWhitespace(profile.firstname) || PrivacyNugget.IsNullOrWhitespace(profile.lastname)   || PrivacyNugget.IsNullOrWhitespace(profile.phone)   || PrivacyNugget.IsNullOrWhitespace(profile.email)   || PrivacyNugget.IsNullOrWhitespace(profile.city)   || PrivacyNugget.IsNullOrWhitespace(profile.postalcode)   || PrivacyNugget.IsNullOrWhitespace(profile.country) || PrivacyNugget.IsNullOrWhitespace(profile.address))
  }
  public static async DeleteSafeFile(key:string,filetype:string,filename:string) {
    const client = await PrivacyNugget.PrepareClient();
    const result = await client.deleteSafeFile({ key,filetype,filename });
    return result.data;
  }

  public static async GetSafe(key: string): Promise<Definitions.PrivacyProfile> {
    if (key == UserModule.UserEntityId) {
      if (UserModule.Profile?.firstname != null)
        return UserModule.Profile;
    }
    const client = await PrivacyNugget.PrepareClient();
    try {
      const result = await client.getsafe({ key: key });
      if (key == UserModule.UserEntityId) {
        UserModule.UpdateProfile(result.data);
      }
      return result.data;
    } catch (ex) {
      console.log(ex);
      await UserModule.InitializeProfile();
      UserModule.UpdateProfile({});
    }

    return {};
  }

  public static async UpdateSafe(key:string,profile :Definitions.PrivacyProfile) {
    const client = await PrivacyNugget.PrepareClient();
    profile.entityId = key;
    const result = await client.updatesafe({ key: key }, <any>profile);
    if (key == UserModule.UserEntityId) {
      UserModule.UpdateProfile(profile);
    }
    return result.data;
  }


  public static async CreateSafe(key: string) {
    const profile: Definitions.PrivacyProfile = {
      email: "",
      phone: "",
      firstname: "",
      lastname: "",
      address: "",
      postalcode: "",
      city: "",
      country: "",
      company: "",
      taxnumber: "",
      webpage: "",
      entityId: key
    };
    const client = await PrivacyNugget.PrepareClient();
    const result = await client.createsafe(undefined, <any>profile);
    if (key == UserModule.UserEntityId) {
      UserModule.UpdateProfile({ entityId: key});
    }
  }

/*
  public static async GetCustomData(key:string) {
    const client = await PrivacyNugget.PrepareClient();
    const result = await client.getcustomdata({ key: key });
    return result.data;
  }

  public static async UpdateCustomData(key: string, object: any) {
    const client = await PrivacyNugget.PrepareClient();
    const mydata: string = JSON.stringify(object);
    const result = await client.updatecustomdata({ key: key }, <any>mydata );
    return result.data;
  }



  public static async GetSafes(key: Array<string>) {
    //const key = keys.map(x => 'key=' + x).join("&").substr(4);
     const client = await PrivacyNugget.PrepareClient();
     const result = (await client.getsafes({ key: key, customData: true })).data;
     result.forEach(function (value) {
       if (value.customData != null) {
         try{
           value.customData = JSON.parse(value.customData.replace("null","\\\"\\\"").replace("null","\\\"\\\""));
         } catch (ex) {
           console.log(value.customData.replace("null","\\\"\\\"").replace("null","\\\"\\\""));
           console.log(ex);
         }
       }
     });
     return result;
   }
*/

  public static async DeleteSafe(key:string, state:string) {
    const client = await PrivacyNugget.PrepareClient();
    const result = await client.deletesafe({ key: key });
    return result.data;
  }
}
