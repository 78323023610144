

















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { User, ProfileDto } from "@shared/services/nuggets/ManagementNugget";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { APPMODE } from "@/services/Config";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";

@Component({
  components: { MarketingMenuHeader },
})
export default class Logout extends Vue {
  public async mounted() {
    await UserModule.Logout();
  }
  public isLoading: boolean = false;
  public error: string | null = null;

  username: string = "";
  password: string = "";
  /*
  get username(){
    return this.credentials.username;
  }

  get username(){
    return this.credentials.password;
  }*/

  public async Login() {
    this.isLoading = true;
    this.error = null;

    try {
      const user: User = {
        userName: APPMODE + "_" + this.username,
        password: this.password,
        firstName: "",
        lastName: "",
        userPoolId: -1,
        language: "de",
        eMail: APPMODE + "_" + this.username,
        timeZone: "",
        id: "",
        entityId: "",
        userRoles: "",
      };
      await UserModule.Login(user);
    } catch (ex) {
      this.error = "ungültiger Benutzername oder Passwort";
      this.isLoading = false;
      return;
    }
    await TransactionModule.TakeOverCurrentTransaction();
    switch (UserModule.Claims[0]) {
      case "CUST": {
        this.$router.push("/Customer");
        break;
      }
      case "PB": {
        this.$router.push("/pf");
        break;
      }
      case "GV": {
        this.$router.push("/GV");
        break;
      }
    }
    this.isLoading = false;
    // this.$store.dispatch('loadCategories')
  }

  isLoginButtonDisabled() {
    return !this.username || !this.password || this.password.length < 4;
  }
}
