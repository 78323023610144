


































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { APPMODE } from "@/services/Config";
import { Environment } from "@/services/Environment";
import { UserModule } from "@shared/store/modules/UserAuthModule";
import {
  User,
  ProfileDto,
  ManagementNugget,
} from "@shared/services/nuggets/ManagementNugget";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";

@Component({
  components: { MarketingMenuHeader },
})
export default class ResetPassword extends Vue {
  isLoading: boolean = false;
  isDone: Boolean = false;
  email: string = "";
  menueopen: boolean = true;
  public mounted() {}

  async resetPassword() {
    (<any>this.$refs.observerbase).validate();
    const isvalid = await (<any>this.$refs.observerbase).validate();
    if (isvalid == false) {
      return;
    }

    this.isLoading = true;
    const newUser: User = {
      id: "",
      entityId: "",
      firstName: "",
      lastName: "",
      userName: APPMODE + "_" + this.email,
      userPoolId: -1,
      eMail: APPMODE + "_" + this.email,
      language: "German",
      timeZone: "GMT +1",
      password: "",
      userRoles: "",
    };
    await UserModule.ForgotPassword(newUser);
    this.$router.push({
      name: "resetPasswordSuccess",
      params: { email: APPMODE + "_" + this.email },
    });
    this.isDone = true;
  }
}
