<template>
  <div class="page2">


  </div>
</template>

<script>
// @ is an alias to /src
import Backend from '@shared/components/Backend'
export default {
  name: 'backend',
  components: {
    Backend
  }
}
</script>
