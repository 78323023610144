















































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { Environment } from "@/services/Environment";
import { TransactionModule } from "@shared/store/modules/TransactionModule";
import { APPMODE } from "@/services/Config";
import { APPENTITYID } from "../../../../src/services/Config";
import {
  User,
  ProfileDto,
  ManagementNugget,
  UserChangePassword,
} from "@shared/services/nuggets/ManagementNugget";
import MarketingMenuHeader from "./MarketingMenuHeader.vue";

@Component({
  components: { MarketingMenuHeader },
})
export default class ConfirmEmail extends Vue {
  public isLoading: boolean = false;
  public error: string | null = null;

  public GetCleanEmail(text: string) {
    return text.indexOf(APPMODE + "_") >= 0
      ? text.substring(APPMODE.length + 1)
      : text;
  }

  @Prop()
  public email!: string;
  public async mounted() {
    await this.confirmUser();
  }

  async confirmUser() {
    //@ts-ignore
    const user: UserChangePassword = {
      userName: APPMODE + "_" + this.email,
      password: "", //this.password,
      firstName: "",
      lastName: "",
      userPoolId: -1,
      language: "de",
      eMail: APPMODE + "_" + this.email,
      timeZone: "",
      id: "",
      entityId: "",
      secret: "",
      appEntityId: APPENTITYID,
      userRoles: "",
    };
    this.isLoading = true;
    try {
      await ManagementNugget.ConfirmUser(user);

      this.isLoading = false;
    } catch (ex) {
      this.error = "Deine Emailadresse konnte nicht bestätigt werden!";
      this.isLoading = false;
    }
  }
}
