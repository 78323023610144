import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@shared/components/Account/Login.vue";
import Logout from "@shared/components/Account/Logout.vue";
import Register from "@shared/components/Account/Register.vue";
import RegisterPf from "@shared/components/Account/RegisterPF.vue";
import ResetPassword from "@shared/components/Account/ResetPassword.vue";
import ResetPasswordSuccess from "@shared/components/Account/ResetPasswordSuccess.vue";
import ConfirmPasswordSuccess from "@shared/components/Account/ConfirmPasswordSuccess.vue";
import ConfirmEmail from "@shared/components/Account/ConfirmEmail.vue";

import ChangePassword from "@shared/components/Account/ChangePassword.vue";

import About from "@shared/views/About.vue";
import Backend from "@shared/views/Backend.vue";
import { UserModule } from "@shared/store/modules/UserAuthModule";

//General
import Impressum from "@shared/components/Impressum.vue";
import AGB from "@shared/components/AGB.vue";
import Datenschutz from "@shared/components/Datenschutz.vue";

//Customer
import CustomerMaster from "@shared/components/Customer/CustomerMaster.vue";
import NewRequest2 from "@shared/components/Customer/Request2.vue";
import NewRequest3 from "@shared/components/Customer/Request3.vue";
import NewRequest4 from "@shared/components/Customer/Request4.vue";
import NewRequest5 from "@shared/components/Customer/Request5.vue";
import GVPawnBrokerOfferPhase from "@shared/components/GV/GVPawnBrokerOfferPhase.vue";
import NewInquiry from "@shared/components/Customer/Parts/NewInquiry.vue";
import CustomerOverview from "@shared/components/Customer/CustomerOverview.vue";
import FeedbackList from "@shared/components/Customer/FeedbackList.vue";
import RequestList from "@shared/components/Customer/RequestList.vue";
import OfferList from "@shared/components/Customer/OfferList.vue";
import PFListHistory from "@shared/components/Pfandleiher/PFHistory.vue";
import ArchivList from "@shared/components/Customer/ArchivList.vue";
import GVMaster from "@shared/components/GV/GVMaster.vue";
import GVListUsers from "@shared/components/GV/GVListUsers.vue";
import GVEditUser from "@shared/components/General/EditUser.vue";
import GVNewUser from "@shared/components/GV/GVNewUser.vue";
import GVPawnOffers from "@shared/components/GV/GVPawnOffers.vue";
import GVListCustomerInquiries from "@shared/components/GV/GVListCustomerInquiries.vue";
import GVListFeedback from "@shared/components/GV/GVListFeedback.vue";
import GVCustomerDecisionPhase from "@shared/components/GV/GVCustomerDecisionPhase.vue";
import GVListHistory from "@shared/components/GV/GVListHistory.vue";
import GVShowUser from "@shared/components/GV/GVShowUser.vue";
import GVConfirmUser from "@shared/components/GV/GVConfirmUser.vue";
import Debug from "@shared/components/Debug.vue";
import TransactionEdit from "@shared/components/General/TransactionEdit.vue";
import PFMaster from "@shared/components/Pfandleiher/PFMaster.vue";
import PFDashboard from "@shared/components/Pfandleiher/PFDashboard.vue";
import PFRequestsList from "@shared/components/Pfandleiher/PFRequestsList.vue";
import PFQuoteAccepted from "@shared/components/Pfandleiher/PFQuoteAccepted.vue";
import PFQuoteDenied from "@shared/components/Pfandleiher/PFQuoteDenied.vue";
import PFCategories from "@shared/components/Pfandleiher/PFCategories.vue";
import PFOfferList from "@shared/components/Pfandleiher/PFOfferList.vue";
import TransactionNugget from "@shared/services/nuggets/TransactionNugget";
import { Components } from "@shared/services/nuggets/TransactionSvc";
import { EventBus } from "@/eventbus";
import CustomerOfferAccepted from "@shared/components/Customer/Parts/CustomerOfferAccepted.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/debug",
    name: "debug",
    component: Debug,
    beforeEnter: SetLoaded,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
    beforeEnter: SetLoaded,
    // component: () => import(/* webpackChunkName: "about" */ '@shared/views/About.vue')
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: SetLoaded,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    beforeEnter: SetLoaded,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    beforeEnter: SetLoaded,
  },
  {
    path: "/registerpf",
    name: "registerpf",
    component: RegisterPf,
    beforeEnter: SetLoaded,
  },
  {
    path: "/resetpassword",
    name: "resetPassword",
    component: ResetPassword,
    beforeEnter: SetLoaded,
  },
  {
    path: "/resetpasswordsuccess/:email",
    props: true,
    name: "resetPasswordSuccess",
    component: ResetPasswordSuccess,
    beforeEnter: SetLoaded,
  },
  {
    path: "/confirmpasswordsuccess/:email",
    props: true,
    name: "confirmPasswordSuccess",
    component: ConfirmPasswordSuccess,
    beforeEnter: SetLoaded,
  },
  {
    path: "/confirm/:email",
    props: true,
    beforeEnter: requireAnyAuth,
    name: "confirmemail",
    component: ConfirmEmail,
  },
  {
    path: "/changepassword/:email/:userid/:secret",
    props: true,
    name: "changePassword",
    component: ChangePassword,
    beforeEnter: SetLoaded,
  },
  {
    path: "/backend",
    name: "backend",
    component: Backend,
    beforeEnter: SetLoaded,
  },
  {
    path: "/Impressum",
    name: "impressum",
    component: Impressum,
    beforeEnter: SetLoaded,
  },
  {
    path: "/agb",
    name: "agb",
    component: AGB,
    beforeEnter: SetLoaded,
  },
  {
    path: "/Datenschutz",
    name: "datenschutz",
    component: Datenschutz,
    beforeEnter: SetLoaded,
  },
  {
    path: "/Request1/:mode",
    beforeEnter: requireAnonymousAuth,
    props: true,
    name: "newrequest1",
    component: () =>
      import(
        /* webpackChunkName: "RequestWidget" */ "@shared/components/Customer/Request1.vue"
      ),
  },
  {
    path: "/Request2",
    name: "newrequest2",
    component: NewRequest2,
    beforeEnter: SetLoaded,
  },
  {
    path: "/Request3",
    name: "newrequest3",
    component: NewRequest3,
    beforeEnter: SetLoaded,
  },
  {
    path: "/Request4",
    name: "newrequest4",
    component: NewRequest4,
    beforeEnter: SetLoaded,
  },
  {
    path: "/Request5/:user/:userid",
    props: true,
    name: "newrequest5",
    component: NewRequest5,
    beforeEnter: SetLoaded,
  },

  /* Customer */
  {
    path: "/Customer",
    component: CustomerMaster,
    beforeEnter: requireCustAuth,
    children: [
      {
        path: "Enter/Token/:token",
        name: "entertoken",
        component: CustomerOverview,
      },
      {
        path: "ReviewRequest/:transactionid",
        props: true,
        name: "reviewrequest",
        beforeEnter: routeReview,
        component: TransactionEdit,
      },
      {
        path: "CustomerOverview",
        name: "customeroverview",
        component: CustomerOverview,
      },
      {
        path: "EditProfile",
        name: "CustomerEditProfile",
        component: GVEditUser,
      },
      {
        path: "newinquiry",
        name: "CustomerInquiryCorrection",
        component: NewInquiry,
      },
      {
        path: "offerlist",
        name: "offerlist",
        component: OfferList,
      },
      {
        path: "feedbacklist",
        name: "feedbacklist",
        component: FeedbackList,
      },
      {
        path: "requestlist",
        name: "requestlist",
        component: RequestList,
      },
      {
        path: "archivlist",
        name: "archivlist",
        component: ArchivList,
        children: [
          {
            path: "accepted/:id",
            props: true,
            name: "acceptedtransaction",
            component: CustomerOfferAccepted,
          },
        ],
      },

      {
        path: "",
        name: "default",
        component: CustomerOverview,
      },
    ],
  },
  {
    path: "/GV/",
    component: GVMaster,
    beforeEnter: requireGVAuth,
    children: [
      {
        path: "ReviewRequest/:transactionid",
        props: true,
        name: "reviewrequest",
        beforeEnter: routeReview,
        component: TransactionEdit,
      },
      {
        path: "Enter/Token/:token/*",
        name: "entertoken",
        props: true,
        component: GVListUsers,
      },
      {
        path: "gvlistusers",
        name: "gvlistusers",
        component: GVListUsers,
      },
      {
        path: "gvshowuser/:user/:userid",
        props: true,
        name: "gvshowuser",
        component: GVShowUser,
      },
      {
        path: "gvedituser",
        name: "gvedituser",
        component: GVEditUser,
      },
      {
        path: "gvnewuser/:user/:userid/:userType/:newsuser",
        props: true,
        name: "gvnewuser",
        component: GVNewUser,
      },
      {
        path: "gvconfirmuser/:user/:userid",
        props: true,
        name: "gvconfirmuser",
        component: GVConfirmUser,
      },
      {
        path: "GVListCustomerInquiries/:transactionid?",
        name: "gvlistcustomerinquiries",
        component: GVListCustomerInquiries,
        props: true,
      },
      {
        path: "GVHistory",
        name: "gvlistHistory",
        component: GVListHistory,
      },
      {
        path: "GVListFeedback",
        name: "gvlistfeedback",
        component: GVListFeedback,
      },
      {
        path: "PawnOfferPhase",
        name: "PawnOfferPhase",
        component: GVPawnBrokerOfferPhase,
      },
      {
        path: "PawnOffers",
        name: "PawnOffers",
        component: GVPawnOffers,
      },
      {
        path: "CustomerDecisionPhase",
        name: "CustomerDecisionPhase",
        component: GVCustomerDecisionPhase,
      },

      {
        path: "gveditProfile",
        name: "gveditprofile",
        component: GVEditUser,
      },

      {
        path: "",
        name: "default",
        component: GVListCustomerInquiries,
      },
    ],
  },
  {
    path: "/pf",
    component: PFMaster,
    beforeEnter: requirePBAuth,
    children: [
      {
        path: "Enter/Token/:token",
        name: "entertoken",
        component: PFDashboard,
      },
      {
        path: "ReviewRequest/:transactionid",
        props: true,
        name: "reviewrequest",
        beforeEnter: routeReview,
        component: TransactionEdit,
      },
      {
        path: "pfdashboard",
        name: "pfdashboard",
        component: PFDashboard,
      },
      {
        path: "pfofferlist",
        name: "PFOfferList",
        component: PFOfferList,
      },
      {
        path: "pfhistory",
        name: "PFHistory",
        component: PFListHistory,
      },
      {
        path: "pfrequestslist",
        name: "pfrequestslist",
        component: PFRequestsList,
      },
      {
        path: "pfquoteaccepted",
        name: "pfquoteaccepted",
        component: PFQuoteAccepted,
      },
      {
        path: "pfquotedenied",
        name: "pfquotedenied",
        component: PFQuoteDenied,
      },
      {
        path: "pfCategories",
        name: "pfCategories",
        component: PFCategories,
      },
      {
        path: "pfeditprofile",
        name: "pfeditprofile",
        component: GVEditUser,
      },
      {
        path: "",
        name: "default",
        component: PFRequestsList,
      },
    ],
  },
  {
    path: "*",
    name: "defaultroute",
    beforeEnter: requireAnonymousAuth,
    component: () =>
      import(
        /* webpackChunkName: "RequestWidget" */ "@shared/components/Customer/Request1.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

function SetLoaded(to: any, from: any, next: any) {
  Vue.nextTick(function () {
    // do something cool
    EventBus.$emit("AppLoaded");
    console.log("emitcalled");
  });
  next();
}

function requireAnyAuth(to: any, from: any, next: any) {
  SetLoaded(to, from, next);
  if (to.name == "reviewrequest") next();
  if (UserModule.Token != null && !UserModule.IsAnonymous) {
    next();
  } else {
    next("/login");
  }
}

function requireAnonymousAuth(to: any, from: any, next: any) {
  //ExtractPassedToken(to, from, next);
  if (to.name == "reviewrequest") next();
  if (UserModule.Token != null && UserModule.Claims[0] == "") {
    next();
  } else {
    UserModule.Logout();

    UserModule.AnonymousLogin().then((x) => next());
  }
}
function requirePBAuth(to: any, from: any, next: any) {
  SetLoaded(to, from, next);
  console.log(UserModule.Claims);
  if (to.name == "reviewrequest") next();
  if (UserModule.Token != null && UserModule.Claims[0] == "PB") {
    next();
  } else {
    UserModule.LoadTokenFromRoute({ to: to, from: from, next: next });
  }
}
function requireCustAuth(to: any, from: any, next: any) {
  SetLoaded(to, from, next);
  if (to.name == "reviewrequest") next();
  if (
    UserModule.Token != null &&
    UserModule.Claims[0] == "CUST" &&
    !UserModule.IsAnonymous
  ) {
    next();
  } else {
    UserModule.LoadTokenFromRoute({ to: to, from: from, next: next });
  }
}

function routeReview(to: any, from: any, next: any) {
  const transactionid = to?.params?.transactionid;
  if (UserModule.Authenticated) {
    TransactionNugget.GetTransaction(transactionid).then((transaction) => {
      if (
        UserModule.Token != null &&
        UserModule.Claims[0] == "CUST" &&
        !UserModule.IsAnonymous
      ) {
        if (transaction.state == "offersapproved") {
          next({
            name: "customeroverview",
            query: { transactionid: transactionid, operation: "top5" },
          });
        } else if (transaction.state == "feedback") {
          next({
            name: "customeroverview",
            query: { transactionid: transactionid, operation: "verify" },
          });
        } else if (transaction.state == "closedok") {
          next({
            name: "archivlist",
            query: { transactionid: transactionid, operation: "open" },
          });
        } else {
          next({
            name: "customeroverview",
            query: { transactionid: transactionid, operation: "open" },
          });
        }
      }
      if (
        UserModule.Token != null &&
        UserModule.Claims[0] == "PB" &&
        !UserModule.IsAnonymous
      ) {
        if (transaction.state == "atlenders") {
          next({
            name: "PFRequestsList",
            query: { transactionid: transactionid, operation: "bid" },
          });
        } else if (transaction.state == "closednok") {
          next({ name: "PFQuoteDenied" });
        } else if (transaction.state == "feedback") {
          next({
            name: "gvlistcustomerinquiries",
            query: { transactionid: transactionid, operation: "verify" },
          });
        } else if (transaction.state == "closedok") {
          next({
            name: "PFQuoteAccepted",
            query: { transactionid: transactionid, operation: "open" },
          });
        } else {
          next({ name: "PFOfferList" });
        }
      }
      if (
        UserModule.Token != null &&
        UserModule.Claims[0] == "GV" &&
        !UserModule.IsAnonymous
      ) {
        if (transaction.state == "new") {
          next({
            name: "gvlistcustomerinquiries",
            query: { transactionid: transactionid, operation: "verify" },
          });
        } else if (transaction.state == "feedback") {
          next({
            name: "gvlistfeedback",
            query: { transactionid: transactionid, operation: "verify" },
          });
        } else if (transaction.state == "lenderstimeout") {
          next({
            name: "PawnOffers",
            query: { transactionid: transactionid, operation: "verify" },
          });
        } else if (transaction.state == "closednok") {
          next({
            name: "gvlistHistory",
            query: { transactionid: transactionid, operation: "verify" },
          });
        } else if (transaction.state == "closedok") {
          next({
            name: "gvlistHistory",
            query: { transactionid: transactionid, operation: "verify" },
          });
        }
      }
    });
  } else {
    sessionStorage.setItem("deeplink", JSON.stringify(to));
    next("login");
  }
}

function requireGVAuth(to: any, from: any, next: any) {
  if (to.name == "reviewrequest") next();
  UserModule.LoadTokenFromRoute({ to: to, from: from, next: next });
}

export default router;
